import React from "react";

const Tooltip = ({ setShowTooltip }) => {
  return (
    <div
      id="tooltip-container"
      className="cursor-pointer tooltip-animation"
      onClick={() => setShowTooltip(false)}
    >
      Indiquez ici vos disponibilités si vous souhaitez obtenir,{" "}
      <u>à titre grâcieux</u>, une analyse de vos résultats par un expert en
      cybersécurité.
      <br />
      <br />
      Nous vous rappelerons à la date de votre choix, sous 48h ouvrées minimum
      entre 9h et 17h59.
      <br />
      <br />
      Vous connaîtrez ainsi les premières mesures de cybersécurité à adopter
      pour votre société.
    </div>
  );
};

export default Tooltip;
