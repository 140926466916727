import React, { useState } from "react";
// import { BsFillPatchQuestionFill } from "react-icons/bs";
import { supplierAuditThemes } from "../../../../../utils/JSON/supplierAssesmentQuestions";
import { AiOutlineAudit } from "react-icons/ai";
import { BiSolidDownArrow, BiSolidRightArrow } from "react-icons/bi";
import QuestionThemeButtonTemplate from "./QuestionThemeButtonTemplate";
// import { useAtomValue } from "jotai";
// import { isSupplierAssesmentQuestionsSelectedAtom } from "../../../../../atoms/MenusSelectionAtom";

const QuestionsButtonSupplierAssesmentMenu = () => {
  const [
    displaySupplierAssesmentQuestionsThemes,
    setDisplaySupplierAssesmentQuestionsThemes,
  ] = useState(false);

  // const isSupplierAssesmentQuestionsSelected = useAtomValue(
  //   isSupplierAssesmentQuestionsSelectedAtom
  // );

  const handleClick = () => {
    setDisplaySupplierAssesmentQuestionsThemes(
      !displaySupplierAssesmentQuestionsThemes
    );
  };

  return (
    <>
      <div
        className="supplier-assesment-themes-button-container"
        onClick={() => handleClick()}
      >
        <div className="supplier-assesment-themes-button">
          {displaySupplierAssesmentQuestionsThemes ? (
            <>
              <button>
                <BiSolidDownArrow color="white" />
              </button>
            </>
          ) : (
            <>
              <button>
                <BiSolidRightArrow color="white" />
              </button>
            </>
          )}
        </div>
      </div>

      {displaySupplierAssesmentQuestionsThemes && (
        <div
          id="supplier-assesment-themes-container"
          // className="supplier-assesment-questions-animation"
        >
          {supplierAuditThemes.map((theme) => (
            <QuestionThemeButtonTemplate
              theme={theme}
              icon={AiOutlineAudit}
              url={`/supplier-assesment-questions/${
                theme.id + ". " + theme.name
              }`}
              displaySupplierAssesmentQuestionsThemes={
                displaySupplierAssesmentQuestionsThemes
              }
              setDisplaySupplierAssesmentQuestionsThemes={
                setDisplaySupplierAssesmentQuestionsThemes
              }
            />
          ))}
        </div>
      )}
    </>
  );
};

export default QuestionsButtonSupplierAssesmentMenu;
