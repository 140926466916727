import React, { useState } from "react";
import { FiFacebook, FiPhone } from "react-icons/fi";
import { FaRegEnvelope } from "react-icons/fa6";
import { BsTwitterX } from "react-icons/bs";
import { RiYoutubeLine } from "react-icons/ri";
import { CiLinkedin } from "react-icons/ci";
import { Link } from "react-router-dom";
import logoACT from "../../../images/logo2023Dark.png";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import LegalMentions from "../LegalMentions/LegalMentions";

const Footer = () => {
  const [displayPrivacyPolicy, setDisplayPrivacyPolicy] = useState(false);
  const [displayLegalMenions, setDisplayLegalMentions] = useState(false);

  return (
    <>
      <div id="footer-container">
        <div id="footer-act-informations-container">
          <div id="footer-act-description-container">
            <div id="footer-logo-and-name-container">
              <Link
                to="https://www.alliancecybertech.com/tools"
                target="_blank"
                className="text-decoration-none"
              >
                <div id="header-logo-container">
                  <div id="act-logo">
                    <img
                      src={logoACT}
                      alt="logo de la société Alliance Cyber Technologies"
                    />
                  </div>
                  <div id="header-act-name">
                    <span className="font-family-designosaur ">
                      Alliance Cyber
                      <br />
                      Technologies
                    </span>
                  </div>
                </div>
              </Link>
            </div>
            Alliance Cyber Technologies est un cabinet d'excellence en cyber
            sécurité spécialisé dans la protection des données, la gestion des
            risques, les normes ISO 27001, 27002, 27005, les référentiels ITIL, COBIT et NIS2.
          </div>

          <div id="footer-act-links-container">
            <div className="footer-single-link-container">
              <div className="footer-icon-container">
                <FiPhone color="#539bff" size="1.15rem" />
              </div>

              <div className="footer-information-container">
                +33&nbsp;1&nbsp;34&nbsp;90&nbsp;86&nbsp;77
              </div>
            </div>
            <div className="footer-single-link-container">
              <div className="footer-icon-container">
                <FaRegEnvelope color="#539bff" size="1.15rem" />
              </div>

              <div className="footer-information-container">
                contact@alliancecybertech.com
              </div>
            </div>
            <div className="footer-single-link-container">
              <div className="footer-social-link-container">
                <Link
                  to="https://facebook.com/Alliance-Cyber-Technologies-560385091081859"
                  target="_bank"
                >
                  <FiFacebook color="#539bff" size="1.15rem" />
                </Link>
              </div>
              <div className="footer-social-link-container">
                <Link to="https://twitter.com/alliance_cyber" target="_bank">
                  <BsTwitterX color="#539bff" size="1.15rem" />
                </Link>
              </div>
              <div className="footer-social-link-container">
                <Link
                  to="https://www.youtube.com/watch?v=rmrhlpSkY-M"
                  target="_bank"
                >
                  <RiYoutubeLine color="#539bff" size="1.15rem" />
                </Link>
              </div>
              <div className="footer-social-link-container">
                <Link
                  to="https://www.linkedin.com/company/18929139"
                  target="_bank"
                >
                  <CiLinkedin color="#539bff" size="1.15rem" />
                </Link>
              </div>
              <div className="footer-social-link-container">
                <Link to="mailto:alliancecybertech.com" target="_bank">
                  <FaRegEnvelope color="#539bff" size="1.15rem" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="footer-legal-container">
          <div id="footer-copyright-container">
            Copyrights ©2018 - {new Date().getFullYear()} Alliance Cyber
            Technologies
          </div>
          <div id="footer-privacy-and-legal-container">
            <div
              id="footer-privacy-policy"
              onClick={() => setDisplayPrivacyPolicy(true)}
            >
              Politique de confidentialité
            </div>
            <div
              id="footer-legal-mentions"
              onClick={() => setDisplayLegalMentions(true)}
            >
              Mentions légales
            </div>
          </div>
        </div>
      </div>

      {displayPrivacyPolicy && (
        <PrivacyPolicy setDisplayPrivacyPolicy={setDisplayPrivacyPolicy} />
      )}

      {displayLegalMenions && (
        <LegalMentions setDisplayLegalMentions={setDisplayLegalMentions} />
      )}
    </>
  );
};

export default Footer;
