import React, { useEffect, useState } from "react";
// import MainViewTitle from "../../../../components/Version2/MainViewTitle/MainViewTitle";
import { getAuthUserSuppliersList } from "../../../../../utils/functions/Version3/functionsForSuppliersManagement";
import SupplierSummaryTemplate from "./SupplierSummaryTemplate";
import { nanoid } from "nanoid";
import { IoAddCircleSharp } from "react-icons/io5";
import AddSupplierForm from "./AddSupplierForm";

const SuppliersListing = () => {
  const [suppliersListing, setSuppliersListing] = useState();
  const [displayAddSupplierForm, setDisplayAddSupplierForm] = useState(false);

  useEffect(() => {
    getAuthUserSuppliersList(setSuppliersListing);
  }, []);

  return (
    <>
      <div id="suppliers-listing-title">
        <h1>
          Vos fournisseurs{" "}
          <IoAddCircleSharp
            onClick={() => setDisplayAddSupplierForm(true)}
            className="margin-left-10px cursor-pointer"
            color="#13deb9"
            title="Ajouter un fournisseur"
          />
        </h1>
      </div>

      <div id="suppliers-listing-container">
        {suppliersListing &&
          suppliersListing.map((supplier, index) => (
            <>
              <SupplierSummaryTemplate
                key={nanoid()}
                supplier={supplier}
                index={index + 1}
              />
            </>
          ))}
      </div>

      {displayAddSupplierForm && (
        <>
          <AddSupplierForm
            setDisplayAddSupplierForm={setDisplayAddSupplierForm}
            getAuthUserSuppliersList={getAuthUserSuppliersList}
            setSuppliersListing={setSuppliersListing}
          />
        </>
      )}
    </>
  );
};

export default SuppliersListing;
