import React from "react";
// import { getCurrentUserPreaudit } from "../utils/functions/Version2/functionsForPreauditManagement";

const Tests = () => {
  return (
    <>
      <div>
        <button
          // onClick={() => getCurrentUserPreaudit(1)}
          className="light-blue1-button"
        >
          TEST
        </button>
      </div>
    </>
  );
};

export default Tests;
