import React, { useState } from "react";
import SupplierAssesmentCharts from "./SupplierAssesmentCharts";
import {
  supplierAssesmentAnswersAtom,
  supplierAssesmentCommentsAtom,
} from "../../../../../atoms/SupplierAssesmentAtom";
import { useSetAtom } from "jotai";
import { parseDate } from "../../../../../utils/functions/Version3/functionsForFormsInputsManagement";

const SupplierAssesmentSummaryTemplate = ({ supplierAssesment }) => {
  const [displaySupplierAssesmentCharts, setDisplaySupplierAssesmentCharts] =
    useState(false);

  const setSupplierAssesmentAnswers = useSetAtom(supplierAssesmentAnswersAtom);
  const setSupplierAssesmentComments = useSetAtom(
    supplierAssesmentCommentsAtom
  );

  const showSupplierAssesmentDetails = () => {
    setSupplierAssesmentAnswers(supplierAssesment.answers);
    setSupplierAssesmentComments(supplierAssesment.comments);
    setDisplaySupplierAssesmentCharts(true);
  };

  return (
    <>
      <li onClick={() => showSupplierAssesmentDetails()}>
        Évaluation du {supplierAssesment && parseDate(supplierAssesment.created_at)}
      </li>

      {displaySupplierAssesmentCharts && (
        <>
          <SupplierAssesmentCharts
            supplierAssesment={supplierAssesment}
            setDisplaySupplierAssesmentCharts={
              setDisplaySupplierAssesmentCharts
            }
          />
        </>
      )}
    </>
  );
};

export default SupplierAssesmentSummaryTemplate;
