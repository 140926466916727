import React, { useState } from "react";
import { useAtom } from "jotai";
import {
  preauditAnswersAtom,
  preauditProgressAtom,
} from "../../../../../atoms/PreauditAtom";

const PreauditQuestionTemplate = ({ question, setIsPreauditmodified }) => {
  const [preauditAnswers, setPreauditAnswers] = useAtom(preauditAnswersAtom);
  const [rating, setRating] = useState(
    preauditAnswers && preauditAnswers[question.id + ". " + question.theme]
  );
  const [progress, setProgress] = useAtom(preauditProgressAtom);

  const handleChange = (value) => {
    setPreauditAnswers({
      ...preauditAnswers,
      [`${question.id}. ${question.theme}`]: parseInt(value),
    });

    setProgress({ ...progress, [question.id]: true });

    setRating(value);

    setIsPreauditmodified(true);
  };

  return (
    <>
      <div className="preaudit-single-question-container">
        <div className="preaudit-single-question-header">
          <div className="preaudit-question-id-container">
            Question {question.id}
          </div>
          <div className="rating-container">{rating ? rating : 0}</div>
        </div>
        <div className="preaudit-question-content-container">
          {question.content}
        </div>

        <div className="preaudit-answer-container">
          <div className="range-limit-container">
            <span className="range-limit">0</span>
          </div>

          <div className="range-input-container">
            <input
              type="range"
              name={`${question.id}. ${question.theme}`}
              min={0}
              max={10}
              defaultValue={
                preauditAnswers
                  ? preauditAnswers[`${question.id}. ${question.theme}`]
                  : 0
              }
              onWheel={(e) => e.target.blur()}
              onChange={(e) => handleChange(e.target.value)}
              className="range-input"
            />
          </div>

          <div className="range-limit-container">
            <span className="range-limit">10</span>
          </div>
        </div>

        {/* <div className="rating-container">{rating ? rating : 0}</div> */}
      </div>
    </>
  );
};

export default PreauditQuestionTemplate;
