import { useAtom, useSetAtom } from "jotai";
import React from "react";
import {
  Q4WhatManufacturingSubSectorsAtom,
  Q4WhatSizeAndRevenueAtom,
  Q5WhichOnesAreCorrectAtom,
  Q6WhatActivitiesAtom,
  displayQ5WhichOnesAreCorrectAtom,
  displayQ6WhatActivitiesAtom,
} from "../../../../../atoms/NIS2Atom";
import {
  answersManagementForQ3OtherSectorsOfActivity,
  resetQ5WhichOnesAreCorrect,
  resetQ6WhatActivties,
  resetSizeAndRevenue,
} from "../../../../../utils/functions/Version3/functionsForNIS2Management";

const ManufacturingSubSectors = ({ setDisplayQ4WhatSizeAndRevenue }) => {
  const [Q4WhatManufacturingSubSectors, setQ4WhatManufacturingSubSectors] =
    useAtom(Q4WhatManufacturingSubSectorsAtom);

  const setQ4WhatSizeAndRevenue = useSetAtom(Q4WhatSizeAndRevenueAtom);

  const setQ5WhichOnesAreCorrect = useSetAtom(Q5WhichOnesAreCorrectAtom);
  const setDisplayQ5WhichOnesAreCorrect = useSetAtom(
    displayQ5WhichOnesAreCorrectAtom
  );

  const setQ6WhatActivities = useSetAtom(Q6WhatActivitiesAtom);

  const setDisplayQ6WhatActivities = useSetAtom(displayQ6WhatActivitiesAtom);

  return (
    <>
      <div className="nis2-sub-sectors-container">
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="fabrication de dispositifs médicaux et de dispositifs médicaux de diagnostic in vitro"
              checked={Q4WhatManufacturingSubSectors.includes(
                "fabrication de dispositifs médicaux et de dispositifs médicaux de diagnostic in vitro"
              )}
              onChange={(e) =>
                answersManagementForQ3OtherSectorsOfActivity(
                  e,
                  Q4WhatManufacturingSubSectors,
                  setQ4WhatManufacturingSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Fabrication de dispositifs médicaux et de dispositifs médicaux de
            diagnostic in vitro
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="fabrication de produits informatiques, électroniques et optiques"
              checked={Q4WhatManufacturingSubSectors.includes(
                "fabrication de produits informatiques, électroniques et optiques"
              )}
              onChange={(e) =>
                answersManagementForQ3OtherSectorsOfActivity(
                  e,
                  Q4WhatManufacturingSubSectors,
                  setQ4WhatManufacturingSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Fabrication de produits informatiques, électroniques et optiques{" "}
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="fabrication d’équipements électriques"
              checked={Q4WhatManufacturingSubSectors.includes(
                "fabrication d’équipements électriques"
              )}
              onChange={(e) =>
                answersManagementForQ3OtherSectorsOfActivity(
                  e,
                  Q4WhatManufacturingSubSectors,
                  setQ4WhatManufacturingSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Fabrication d’équipements électriques
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="fabrication de machines et équipements n.c.a. (non classés ailleurs par la nomenclature Européenne NACE rév.2)"
              checked={Q4WhatManufacturingSubSectors.includes(
                "fabrication de machines et équipements n.c.a. (non classés ailleurs par la nomenclature Européenne NACE rév.2)"
              )}
              onChange={(e) =>
                answersManagementForQ3OtherSectorsOfActivity(
                  e,
                  Q4WhatManufacturingSubSectors,
                  setQ4WhatManufacturingSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Fabrication de machines et équipements n.c.a. (non classés ailleurs
            par la nomenclature Européenne NACE rév.2)
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="construction de véhicules automobiles, remorques et semi-remorques"
              checked={Q4WhatManufacturingSubSectors.includes(
                "construction de véhicules automobiles, remorques et semi-remorques"
              )}
              onChange={(e) =>
                answersManagementForQ3OtherSectorsOfActivity(
                  e,
                  Q4WhatManufacturingSubSectors,
                  setQ4WhatManufacturingSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Construction de véhicules automobiles, remorques et semi-remorques
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="fabrication d’autres matériels de transport"
              checked={Q4WhatManufacturingSubSectors.includes(
                "fabrication d’autres matériels de transport"
              )}
              onChange={(e) =>
                answersManagementForQ3OtherSectorsOfActivity(
                  e,
                  Q4WhatManufacturingSubSectors,
                  setQ4WhatManufacturingSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Fabrication d’autres matériels de transport
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="aucun de la liste"
              checked={Q4WhatManufacturingSubSectors.includes(
                "aucun de la liste"
              )}
              onChange={(e) =>
                answersManagementForQ3OtherSectorsOfActivity(
                  e,
                  Q4WhatManufacturingSubSectors,
                  setQ4WhatManufacturingSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Aucun de la liste
          </label>
        </div>
      </div>
    </>
  );
};

export default ManufacturingSubSectors;
