import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import SupplierAssesmentListing from "./components/SupplierAssesmentListing";
import SuppliersListing from "./components/SuppliersListing";
// import AddSupplierForm from "./components/AddSupplierForm";
import { getAuthUserSuppliersList } from "../../../../utils/functions/Version3/functionsForSuppliersManagement";
import SupplierAssesmentQuestionsList from "./components/SupplierAssesmentQuestionsList";
import { getAuthUserDetails } from "../../../../utils/functions/Version3/functionsForUserInformationsManagement";
import { useSetAtom } from "jotai";
import { authUserDetailsAtom } from "../../../../atoms/AuthenticationAtom";

const SupplierAssesmentDashboardV3 = () => {
  const navigate = useNavigate();
  // const [displayAddSupplierForm, setDisplayAddSupplierForm] = useState(false);
  const [suppliersListing, setSuppliersListing] = useState();
  const [displayQuestionsList, setDisplayQuestionsList] = useState(false);
  const setUserDetails = useSetAtom(authUserDetailsAtom);

  useEffect(() => {
    setTimeout(() => {
      getAuthUserDetails(setUserDetails);
    }, 500);
    getAuthUserSuppliersList(setSuppliersListing);
  }, [setUserDetails]);

  return (
    <>
      <div id="supplier-assesment-dashboard-container">
        <div id="supplier-assesment-description-container">
          La cybersécurité de vos fournisseurs est une composante essentielle de
          la protection globale de votre entreprise.{" "}
          <Link to="https://www.alliancecybertech.com" target="_blank">
            <span className="font-family-designosaur">
              Alliance Cyber Technologies
            </span>
          </Link>{" "}
          a créé un questionnaire gratuit d'évaluation de la cybersécurité des
          fournisseurs qui vous offre une approche proactive pour identifier et
          atténuer les risques liés à votre chaîne d'approvisionnement.
          <br />
          <br /> Pour obtenir une analyse par l'un de nos experts en
          cybersécurité, <u>à titre grâcieux</u>, demandez à être contacté
          depuis l'onglet{" "}
          <span
            className="cursor-pointer font-orange font-family-designosaur"
            onClick={() => navigate("/user-informations-edition")}
          >
            vos informations
          </span>
          .
        </div>

        <div id="suppliers-list-and-dashboard-buttons-container">
          <div id="supplier-assesment-dashboard-buttons-container">
            <div
              className="supplier-assesment-dashboard-button-container"
              onClick={() => setDisplayQuestionsList(true)}
            >
              <button
                id="supplier-assesment-questions-list-button"
                className="supplier-assesment-dashboard-button"
              >
                VOIR LE
                <br />
                QUESTIONNAIRE
              </button>
            </div>
          </div>

          <div id="suppliers-list-container">
            <SuppliersListing />
          </div>
        </div>
      </div>

      {displayQuestionsList && (
        <SupplierAssesmentQuestionsList
          setDisplayQuestionsList={setDisplayQuestionsList}
        />
      )}
    </>
  );
};

export default SupplierAssesmentDashboardV3;
