import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isSupplierAssesmentModifiedAtom } from "../../../../../atoms/SupplierAssesmentAtom";

const QuestionThemeButtonTemplate = ({
  theme,
  url,
  displaySupplierAssesmentQuestionsThemes,
  setDisplaySupplierAssesmentQuestionsThemes,
}) => {
  const navigate = useNavigate();
  const [isSupplierAssesmentModified, setIsSupplierAssesmentModified] = useAtom(
    isSupplierAssesmentModifiedAtom
  );

  useEffect(() => {
    isSupplierAssesmentModified && setIsSupplierAssesmentModified(false);
  }, [isSupplierAssesmentModified, setIsSupplierAssesmentModified]);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    navigate(url);
    // setDisplaySupplierAssesmentQuestionsThemes(
    //   !displaySupplierAssesmentQuestionsThemes
    // );
  };

  return (
    <div
      className="supplier-assesment-single-theme-container"
      onClick={() => handleClick()}
    >
      {theme.id}. {theme.name}
    </div>
  );
};

export default QuestionThemeButtonTemplate;
