import React from "react";
import logoACT from "../../../images/logo2023Dark.png";
import manWithShield from "../../../images/character-shield.png";
import manAtDesktop from "../../../images/character-desktop.png";
import { Link, useNavigate } from "react-router-dom";

const HomepageV3 = () => {

  const navigate = useNavigate();

  return (
    <>
      <div id="homepage-container">
        <div id="logo-and-name-container">
          <Link
            to="https://www.alliancecybertech.com/tools"
            target="_blank"
            className="text-decoration-none"
          >
            <div id="logo-container">
              <div id="act-logo">
                <img
                  src={logoACT}
                  alt="logo de la société Alliance Cyber Technologies"
                />
              </div>
              <div id="act-name">
                <span className="font-family-designosaur font-size-2rem ">
                  Alliance Cyber
                  <br />
                  Technologies
                </span>
              </div>
            </div>
          </Link>

          <div id="image1-container">
            <img
              src={manAtDesktop}
              alt="outils de cybersécurité"
              width="100%"
            />
          </div>

          <div id="description-container">OUTILS GRATUITS DE CYBERSÉCURITÉ</div>
        </div>

        <div id="cta-container">
          <Link
            to="https://www.alliancecybertech.com/tools"
            target="_blank"
            className="text-decoration-none"
          >
            <div id="cta-button-container">ACCÉDER AUX OUTILS</div>
          </Link>

          <div id="benefits-container">
            <span className="font-family-designosaur font-dark-blue1">
              Alliance Cyber Technologies
            </span>{" "}
            met à la disposition de votre entreprise{" "}
            <span className="font-family-designosaur font-dark-blue1">
              3 outils gratuits
            </span>{" "}
            :
            <ul>
              <li className="font-dark-blue1">
                un questionnaire d'audit préliminaire{" "}
                <span className="background-color-green padding-1px border-radius-5px font-weight-bold">
                  ISO 27001
                </span>{" "}
                comportant 20 questions avec un graphique des indicateurs ;
              </li>
              <li className="font-dark-blue1">
                un questionnaire de 66 questions pour évaluer la{" "}
                <span className="background-color-orange padding-1px border-radius-5px font-weight-bold">
                  cybersécurité de vos fournisseurs
                </span>
                , avec un graphique des résultats ;
              </li>
              <li className="font-dark-blue1">
                un audit{" "}
                <span className="background-color-red padding-1px border-radius-5px font-weight-bold">
                  RGPD
                </span>{" "}
                pour être en conformité légale et protéger vos données à
                caractère personnel.
              </li>
            </ul>
          </div>

          <div id="image2-container">
            {" "}
            <img
              src={manWithShield}
              alt="outils de cybersécurité"
              width="50%"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomepageV3;
