import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { selectedToolAtom } from "../../../../atoms/MenusSelectionAtom";
import { useSetAtom } from "jotai";
// import manWithShield from "../../../../images/character-shield.png";
// import manAtDesktop from "../../../../images/character-desktop.png";
import logoACT from "../../../../images/logo2023Dark.png";

const PleaseConnect = () => {
  const navigate = useNavigate();
  const setSelectedTool = useSetAtom(selectedToolAtom);

  useEffect(() => {
    setSelectedTool("no-selection");
  }, [setSelectedTool]);

  return (
    <>
      <div id="please-connect-container">

        <div id="please-connect-text">MERCI DE VOUS RECONNECTER.</div>

        <div id="tool-selection-container">
          <div
            className="tool-name-container"
            onClick={() => navigate("/preaudit-welcome")}
          >
            <button className="tool-button">
              Audit préliminaire ISO 27001
            </button>
          </div>
          <div
            className="tool-name-container"
            onClick={() => navigate("/supplier-assesment-welcome")}
          >
            <button className="tool-button ">
              Évaluation fournisseur
            </button>
          </div>
          <div
            className="tool-name-container"
            onClick={() => navigate("/audit-rgpd-welcome")}
          >
            <button className="tool-button ">Audit RGPD</button>
          </div>
        </div>

        <div id="logo-and-name-container-in-please-connect">
          <Link
            to="https://www.alliancecybertech.com/tools"
            target="_blank"
            className="text-decoration-none"
          >
            <div id="logo-container-in-please-connect">
              <div id="act-logo-in-please-connect">
                <img
                  src={logoACT}
                  alt="logo de la société Alliance Cyber Technologies"
                />
              </div>
              <div id="act-name-in-please-connect">
                <span className="font-family-designosaur font-size-1rem ">
                  Alliance Cyber
                  <br />
                  Technologies
                </span>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default PleaseConnect;
