import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import logoACT from "../../../images/logo2023Dark.png";
// import NoToolSelected from "./components/Menus/NoToolSelected/NoToolSelected";
// import PreauditMenu from "./components/Menus/PreauditMenu/PreauditMenu";
// import AuditRGPDMenu from "./components/Menus/AuditRGPDMenu/AuditRGPDMenu";
// import BackstageMenu from "./components/Menus/BackstageMenu/BackstageMenu";
// import SupplierAssesmentMenuForSupplier from "./components/Menus/SupplierAssesmentMenuForSupplier/SupplierAssesmentMenuForSupplier";
// import SupplierAssesmentMenuForRegisteredUser from "./components/Menus/SupplierAssesmentMenuForRegisteredUser/SupplierAssesmentMenuForRegisteredUser";
import { useSetAtom } from "jotai";
import {
  selectedToolAtom,
  isAuditRGPDSelectedAtom,
  isPreauditSelectedAtom,
  isSupplierAssesmentSelectedAtom,
  // isPreauditDashboardSelectedAtom,
  isPreauditQuestionsSelectedAtom,
  // isSupplierAssesmentDashboardSelectedAtom,
  isSupplierAssesmentQuestionsSelectedAtom,
  // isAuditRGPDDashboardSelectedAtom,
  displaySupplierAssesmentQuestionsThemesAtom,
  isUserInformationSelectedAtom,
  isAuditRGPDQuestionsSelectedAtom,
  isDashboardSelectedAtom,
} from "../../../atoms/MenusSelectionAtom";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
// import { isPreauditModifiedAtom } from "../../../atoms/PreauditAtom";
// import { isSupplierAssesmentModifiedAtom } from "../../../atoms/SupplierAssesmentAtom";
// import { isAuditRGPDModifiedAtom } from "../../../atoms/AuditRGPDAtom ";
import ToolName from "./components/ToolName";
// import { signOut } from "../../../utils/functions/Version3/functionsForUserSessionManagement";
// import { FaPowerOff, FaRegUserCircle, FaUserCircle } from "react-icons/fa";
// import { BsGraphUp } from "react-icons/bs";
// import { ImStatsBars2 } from "react-icons/im";
import MenuClassic from "./components/MenuClassic";
import MenuSupplierAssesmentAnswering from "./components/MenuSupplierAssesmentAnswering";
// import MenuNoToolSelected from "./components/MenuNoToolSelected";
// import SupplierAssesmentMenuForSupplier from "./components/MenuSupplierAssesmentAnswering";
import womandSittingWithComputer from "../../../images/woman-sitting-with-computer.png";

const NavbarV3 = () => {
  const navigate = useNavigate();
  const [selectedTool, setSelectedTool] = useAtom(selectedToolAtom);
  const [menuToDisplay, setMenuToDisplay] = useState();

  const [isUserInformationsSelected, setIsUserInformationsSelected] = useAtom(
    isUserInformationSelectedAtom
  );

  const [isDashboardSelected, setIsDashboardSelected] = useAtom(
    isDashboardSelectedAtom
  );

  const setIsPreauditSelected = useSetAtom(isPreauditSelectedAtom);
  // const [isPreauditDashboardSelected, setIsPreauditDashboardSelected] = useAtom(
  //   isPreauditDashboardSelectedAtom
  // );
  const setIsPreauditQuestionsSelected = useSetAtom(
    isPreauditQuestionsSelectedAtom
  );

  const setIsSupplierAssesmentSelected = useSetAtom(
    isSupplierAssesmentSelectedAtom
  );
  // const [
  //   isSupplierAssesmentDashboardSelected,
  //   setIsSupplierAssesmentDashboardSelected,
  // ] = useAtom(isSupplierAssesmentDashboardSelectedAtom);
  const setIsSupplierAssesmentQuestionsSelected = useSetAtom(
    isSupplierAssesmentQuestionsSelectedAtom
  );

  const setIsAuditRGPDSelected = useSetAtom(isAuditRGPDSelectedAtom);
  // const [isAuditRGPDDashboardSelected, setIsAuditRGPDDashboardSelected] =
  //   useAtom(isAuditRGPDDashboardSelectedAtom);
  const setIsAuditRGPDQuestionsSelected = useSetAtom(
    isAuditRGPDQuestionsSelectedAtom
  );

  const setDisplayQuestionsThemes = useSetAtom(
    displaySupplierAssesmentQuestionsThemesAtom
  );
  const currentLocation = useLocation();

  useEffect(() => {
    const getCurrentURL = () => {
      return currentLocation.pathname;
    };

    // Kept here for a possible later use
    // const resetToolsSelection = () => {
    //   setSelectedTool("no-selection");
    //   setIsUserInformationsSelected(false);
    //   setIsPreauditSelected(false);
    //   setIsSupplierAssesmentSelected(false);
    //   setIsAuditRGPDSelected(false);
    //   setIsPreauditDashboardSelected(false);
    //   setIsPreauditQuestionsSelected(false);
    //   setIsSupplierAssesmentDashboardSelected(false);
    //   setIsSupplierAssesmentQuestionsSelected(false);
    //   setIsAuditRGPDDashboardSelected(false);
    //   setIsAuditRGPDQuestionsSelected(false);
    // };

    const setNavbarSelections = (currentURL) => {
      currentURL === "/user-informations" ||
      currentURL === "/user-informations-edition"
        ? setIsUserInformationsSelected(true)
        : setIsUserInformationsSelected(false);

      currentURL === "/dashboard"
        ? setIsDashboardSelected(true)
        : setIsDashboardSelected(false);

      // currentURL === "/preaudit-dashboard"
      //   ? setIsPreauditDashboardSelected(true)
      //   : setIsPreauditDashboardSelected(false);

      currentURL === "/preaudit-questions"
        ? setIsPreauditQuestionsSelected(true)
        : setIsPreauditQuestionsSelected(false);

      // currentURL === "/supplier-assesment-dashboard"
      //   ? setIsSupplierAssesmentDashboardSelected(true)
      //   : setIsSupplierAssesmentDashboardSelected(false);

      if (/^\/supplier-assesment-questions\//.test(currentURL)) {
        setIsSupplierAssesmentQuestionsSelected(true);
        setDisplayQuestionsThemes(true);
      } else {
        setIsSupplierAssesmentQuestionsSelected(false);
        setDisplayQuestionsThemes(false);
      }

      // currentURL === "/audit-rgpd-dashboard"
      //   ? setIsAuditRGPDDashboardSelected(true)
      //   : setIsAuditRGPDDashboardSelected(false);

      currentURL === "/audit-rgpd-questions"
        ? setIsAuditRGPDQuestionsSelected(true)
        : setIsAuditRGPDQuestionsSelected(false);
    };

    const defineMenuToDisplay = () => {
      selectedTool === "supplier-assesment-for-suppliers"
        ? setMenuToDisplay("supplier-assesment-questions-menu-container")
        : setMenuToDisplay("classic-menu-container");
    };

    const currentURL = getCurrentURL();
    setNavbarSelections(currentURL);
    defineMenuToDisplay();
  }, [
    currentLocation.pathname,
    setSelectedTool,
    setIsPreauditSelected,
    setIsSupplierAssesmentSelected,
    setIsAuditRGPDSelected,
    // setIsPreauditDashboardSelected,
    setIsPreauditQuestionsSelected,
    setIsUserInformationsSelected,
    setIsDashboardSelected,
    // setIsSupplierAssesmentDashboardSelected,
    setIsSupplierAssesmentQuestionsSelected,
    // setIsAuditRGPDDashboardSelected,
    setIsAuditRGPDQuestionsSelected,
    setDisplayQuestionsThemes,
    selectedTool,
    setMenuToDisplay,
  ]);

  return (
    <>
      <div id="navbar-container">
        <header>
          <div id="header-logo-and-name-container">
            <Link
              to="https://www.alliancecybertech.com/tools"
              target="_blank"
              className="text-decoration-none"
            >
              <div id="header-logo-container">
                <div id="act-logo">
                  <img
                    src={logoACT}
                    alt="logo de la société Alliance Cyber Technologies"
                  />
                </div>
                <div id="header-act-name">
                  <span className="font-family-designosaur ">
                    Alliance Cyber
                    <br />
                    Technologies
                  </span>
                </div>
              </div>
            </Link>
          </div>
        </header>

        <ToolName selectedTool={selectedTool} />

        {/* {selectedTool === "no-selection" && <MenuNoToolSelected />} */}

        {(selectedTool === "preaudit" ||
          selectedTool === "supplier-assesment" ||
          selectedTool === "audit-rgpd") && (
          <MenuClassic
            isUserInformationsSelected={isUserInformationsSelected}
            isDashboardSelected={isDashboardSelected}
            navigate={navigate}
            selectedTool={selectedTool}
            setSelectedTool={setSelectedTool}
          />
        )}

        {selectedTool === "supplier-assesment-for-suppliers" && (
          <MenuSupplierAssesmentAnswering />
        )}

        <div id="menu-illustration-container">
          <img
            src={womandSittingWithComputer}
            alt="illustration de cybersécurité"
            id="menu-illustration"
          />
        </div>
      </div>
    </>
  );
};

export default NavbarV3;
