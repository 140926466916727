import React, { useEffect, useState } from "react";
import {
  cleanInput,
  generateCaptcha,
  parseDate,
  parsePhoneNumber,
  validateCaptcha,
  validatePhoneFormat,
  validateSIRETFormat,
} from "../../../../utils/functions/Version3/functionsForFormsInputsManagement";
import { useAtomValue, useSetAtom } from "jotai";
import {
  supplierAssesmentToUpdateIdAtom,
  supplierAssesmentToUpdateSupplierAtom,
  supplierAssesmentToUpdateTokenAtom,
} from "../../../../atoms/SupplierAssesmentAtom";
import { IoMdInformationCircle } from "react-icons/io";
import { checkInformationsCompletion } from "../../../../utils/functions/Version3/functionsForUserInformationsManagement";
import { saveSupplierInformationsInBackend } from "../../../../utils/functions/Version3/functionsForSuppliersManagement";
import { selectedToolAtom } from "../../../../atoms/MenusSelectionAtom";

import Tooltip from "../../UserInformationsV3/components/Tooltip";
import CGUV3 from "../../../../components/Version3/CGUV3/CGUV3";
import SupplierInformationsEditionFormErrorMessages from "./SupplierInformationsEditionFormErrorMessages";
import { areUserInformationsCompletedAtom } from "../../../../atoms/AuthenticationAtom";

const SupplierInformationsEditionForm = ({ setDisplayAssesmentQuestions }) => {
  const [captcha, setCaptcha] = useState("");
  const [nextBusinessDay, setNextBusinessDay] = useState();
  const supplierInformations = useAtomValue(
    supplierAssesmentToUpdateSupplierAtom
  );

  var editedOrganization;
  var editedPhone;
  var editedContact;
  var editedAddress;
  var editedSiret;
  var editedAvailability;

  const setSelectedTool = useSetAtom(selectedToolAtom);

  const supplierAssesmentToUpdateId = useAtomValue(
    supplierAssesmentToUpdateIdAtom
  );
  const supplierAssesmentToUpdateToken = useAtomValue(
    supplierAssesmentToUpdateTokenAtom
  );

  const [displayErrorMessages, setDisplayErrorMessages] = useState(false);
  const [phoneInputValidity, setPhoneInputValidity] = useState(true);
  const [siretInputValidity, setSiretInputValidity] = useState(true);
  const [dateInputValidity, setDateInputValidity] = useState(true);
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);
  const setAreUserInformationsCompleted = useSetAtom(
    areUserInformationsCompletedAtom
  );

  const [showTooltip, setShowTooltip] = useState(false);
  const [displayCGU, setDisplayCGU] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    setCaptcha(generateCaptcha());
    getNextBusinessDay();
    // console.log("supplierInformations : \n", supplierInformations);
  }, []);

  const getNextBusinessDay = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();

    // Définir le jour actuel comme le jour de départ
    let nextWorkingDay = new Date(today);

    // Si c'est vendredi (jour 5), ajoutez 3 jours pour passer à lundi
    if (dayOfWeek === 5) {
      nextWorkingDay.setDate(today.getDate() + 3);
    } else {
      // Sinon, ajoutez 1 jour pour passer au jour ouvré suivant
      nextWorkingDay.setDate(today.getDate() + 2);
    }
    // Définir l'heure sur 9h
    nextWorkingDay.setHours(9, 0, 0, 0);

    setNextBusinessDay(nextWorkingDay);
  };

  //   const handleChange = (e) => {
  // setAuthUserData({ ...authUserData, [e.target.name]: e.target.value });
  //   };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (checkIfEmptyValues(e)) {
      alert(
        "Certaines informations sont manquantes.\nMerci de bien vouloir les compléter afin de poursuivre."
      );
    }

    if (!checkIfEmptyValues(e)) {
      // if (
      //   window.confirm(
      //     "En validant cette boîte de dialogue, vous acceptez les conditions d'utilisation. Poursuivre ?"
      //   )
      // ) {
      // Captcha checking
      if (validateCaptcha(e.target.captcha.value, captcha)) {
        setIsCaptchaValid(true);
      } else {
        setIsCaptchaValid(false);
        setDisplayErrorMessages(true);
      }

      // Check inputs content + check script injections
      inputsChecking(e);

      // Check phone format
      if (isPhoneFormatValid(editedPhone)) {
        setPhoneInputValidity(true);
      } else {
        setPhoneInputValidity(false);
        setDisplayErrorMessages(true);
      }

      // Check siret format
      if (isSiretFormatValid(editedSiret)) {
        setSiretInputValidity(true);
      } else {
        setSiretInputValidity(false);
        setDisplayErrorMessages(true);
      }

      // Check date validity
      if (isDateValid(e.target.availability.value)) {
        setDateInputValidity(true);
      } else {
        setDateInputValidity(false);
        setDisplayErrorMessages(true);
      }

      if (
        validateCaptcha(e.target.captcha.value, captcha) &&
        isPhoneFormatValid(editedPhone) &&
        isSiretFormatValid(editedSiret) &&
        isDateValid(e.target.availability.value)
      ) {
        // data updating the backend with the new inputs
        const data = {
          supplier_id: supplierInformations.id,
          organization: editedOrganization,
          phone: editedPhone,
          contact: editedContact,
          address: editedAddress,
          siret: editedSiret,
          terms_acceptance: true,
          supplier_assesment_id: supplierAssesmentToUpdateId,
          token: supplierAssesmentToUpdateToken,
          availability: editedAvailability,
        };

        checkInformationsCompletion(data)
          ? setAreUserInformationsCompleted(true)
          : setAreUserInformationsCompleted(false);

        saveSupplierInformationsInBackend(
          data,
          supplierInformations.id,
          setSelectedTool,
          setDisplayAssesmentQuestions
        );
        // }
      }
    }
  };

  const checkIfEmptyValues = (e) => {
    let areSomeValuesEmpty;

    if (
      (e.target.organization.value === "" &&
        supplierInformations.organization === "") ||
      (e.target.contact.value === "" && supplierInformations.contact === "") ||
      (e.target.phone.value === "" && supplierInformations.phone === "") ||
      (e.target.address.value === "" && supplierInformations.address === "") ||
      (e.target.siret.value === "" && supplierInformations.siret === "")
    ) {
      areSomeValuesEmpty = true;
    } else {
      areSomeValuesEmpty = false;
    }

    return areSomeValuesEmpty;
  };

  const inputsChecking = (e) => {
    e.target.organization.value === "" ||
    containsSpacesOnly(e.target.organization.value)
      ? (editedOrganization = supplierInformations.organization)
      : (editedOrganization = cleanInput(e.target.organization.value));

    e.target.contact.value === "" || containsSpacesOnly(e.target.contact.value)
      ? (editedContact = supplierInformations.contact)
      : (editedContact = cleanInput(e.target.contact.value));

    e.target.phone.value === "" || containsSpacesOnly(e.target.phone.value)
      ? (editedPhone = supplierInformations.phone)
      : (editedPhone = cleanInput(e.target.phone.value));

    e.target.address.value === "" || containsSpacesOnly(e.target.address.value)
      ? (editedAddress = supplierInformations.address)
      : (editedAddress = cleanInput(e.target.address.value));

    e.target.siret.value === "" || containsSpacesOnly(e.target.siret.value)
      ? (editedSiret = supplierInformations.siret)
      : (editedSiret = cleanInput(e.target.siret.value));

    e.target.availability.value === "" ||
    containsSpacesOnly(e.target.availability.value)
      ? (editedAvailability = supplierInformations.availability)
      : (editedAvailability = parseDate(
          cleanInput(e.target.availability.value)
        ));
  };

  const containsSpacesOnly = (string) => {
    const onlySpaces = new RegExp(/^\s*$/);
    return onlySpaces.test(string);
  };

  const isPhoneFormatValid = (phoneNumber) => {
    const validationResult = validatePhoneFormat(phoneNumber);
    return validationResult ? true : false;
  };

  const isSiretFormatValid = (siret) => {
    const validationResult = validateSIRETFormat(siret);
    return validationResult ? true : false;
  };

  const isDateValid = (date) => {
    if (date !== "") {
      // Check if the day is not saturday or sunday, between 9h and 17h59.
      const selectedDay = new Date(date);

      const isWeekday = selectedDay.getDay() >= 1 && selectedDay.getDay() <= 5;

      const isBusinessHours =
        selectedDay.getHours() >= 9 && selectedDay.getHours() <= 17;

      if (!isWeekday || !isBusinessHours) {
        // alert(
        //   "Pour être rappelé, veuillez choisir un jour ouvrable (du lundi au vendredi, entre 9h et 17h59)."
        // );
        setDateInputValidity(false);
        return false;
      } else {
        setDateInputValidity(true);
        return true;
      }
    } else {
      setDateInputValidity(true);
      return true;
    }
  };

  return (
    <>
      <div className="user-informations-request-container">
        <h1>
          Merci de vérifier les informations suivantes pour accéder à votre
          évaluation.
        </h1>
      </div>

      <div id="supplier-informations-edition-form">
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="all-informations-container">
            <div className="supplier-single-information-container">
              <div className="supplier-information-label">Société </div>
              <div className="supplier-information-text">
                <input
                  // required
                  maxLength="100"
                  type="text"
                  className="supplier-information-input"
                  aria-label="organization"
                  name="organization"
                  placeholder={
                    supplierInformations && supplierInformations.organization
                  }
                />
              </div>
            </div>
            {/* <div className="supplier-single-information-container">
              <div className="supplier-information-label">Email </div>
              <div className="supplier-information-text">
                <input
                  // required
                  maxLength="14"
                  type="email"
                  className="supplier-information-input"
                  aria-label="email"
                  name="email"
                  placeholder={
                    supplierInformations && supplierInformations.email
                  }
                />
              </div>
            </div> */}
            <div className="supplier-single-information-container">
              <div className="supplier-information-label">
                Personne à contacter{" "}
              </div>
              <div className="supplier-information-text">
                <input
                  // required
                  maxLength="100"
                  type="text"
                  className="supplier-information-input"
                  aria-label="contact"
                  name="contact"
                  placeholder={
                    supplierInformations && supplierInformations.contact
                  }
                />
              </div>
            </div>
            <div className="supplier-single-information-container">
              <div className="supplier-information-label">Téléphone </div>
              <div className="supplier-information-text">
                <input
                  // required
                  maxLength="10"
                  type="phone"
                  className="supplier-information-input"
                  aria-label="phone"
                  name="phone"
                  placeholder={
                    supplierInformations &&
                    parsePhoneNumber(supplierInformations.phone)
                  }
                />
              </div>
            </div>
            <div className="supplier-single-information-container">
              <div className="supplier-information-label">Adresse </div>
              <div className="supplier-information-text">
                <input
                  // required
                  maxLength="100"
                  type="text"
                  className="supplier-information-input"
                  aria-label="address"
                  name="address"
                  placeholder={
                    supplierInformations && supplierInformations.address
                  }
                />
              </div>
            </div>
            <div className="supplier-single-information-container">
              <div className="supplier-information-label">SIRET </div>
              <div className="supplier-information-text">
                <input
                  // required
                  maxLength="14"
                  type="text"
                  className="supplier-information-input"
                  aria-label="siret"
                  name="siret"
                  placeholder={
                    supplierInformations && supplierInformations.siret
                  }
                />
              </div>
            </div>

            <div className="supplier-single-information-container">
              <div className="supplier-information-label">
                Être rappelé par un expert
                <IoMdInformationCircle
                  color="#539bff"
                  size="1.1rem"
                  onClick={() => setShowTooltip(true)}
                  className={"cursor-pointer"}
                />
              </div>
              <div className="supplier-information-text">
                <input
                  // required
                  type="datetime-local"
                  name="availability"
                  min={
                    nextBusinessDay &&
                    nextBusinessDay.toISOString().slice(0, 16)
                  }
                  className="supplier-information-input"
                  // placeholder={}
                />
              </div>
            </div>

            <div className="supplier-single-information-container">
              <div className="supplier-information-label">
                CAPTCHA : recopiez <span className="font-green">{captcha}</span>
              </div>
              <div className="supplier-information-text">
                <input
                  required
                  type="text"
                  maxLength={10}
                  className="supplier-information-input"
                  name="captcha"
                  placeholder="CAPTCHA"
                />
              </div>
            </div>

            <div className="supplier-single-information-container">
              <div
                className="supplier-information-label text-decoration-underline cursor-pointer"
                onClick={() => setDisplayCGU(true)}
              >
                Conditions d'utilisation
              </div>
              <div className="">
                <input required type="checkbox" className="" />{" "}
              </div>
            </div>
          </div>

          <div id="supplier-informations-submit-container">
            <div>
              <button id="supplier-informations-submit-button" type="submit">
                Valider
              </button>
            </div>
          </div>
        </form>
      </div>

      {showTooltip && <Tooltip setShowTooltip={setShowTooltip} />}

      {displayCGU && (
        <>
          <CGUV3 setDisplayCGU={setDisplayCGU} />
        </>
      )}

      {displayErrorMessages && (
        <SupplierInformationsEditionFormErrorMessages
          phoneInputValidity={phoneInputValidity}
          siretInputValidity={siretInputValidity}
          dateInputValidity={dateInputValidity}
          isCaptchaValid={isCaptchaValid}
          setDisplayErrorMessages={setDisplayErrorMessages}
        />
      )}
    </>
  );
};

export default SupplierInformationsEditionForm;
