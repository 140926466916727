import { useAtom, useSetAtom } from "jotai";
import React from "react";
import {
  isSupplierAssesmentModifiedAtom,
  supplierAssesmentAnswersAtom,
  supplierAssesmentCommentsAtom,
  // supplierAssesmentToUpdateIdAtom,
  // supplierAssesmentToUpdateTokenAtom,
} from "../../../../atoms/SupplierAssesmentAtom";
import { cleanInput } from "../../../../utils/functions/Version3/functionsForFormsInputsManagement";
// import { updateSupplierAssesmentByToken } from "../../../utils/functions/Version2/functionsForSupplierAssesmentManagement";

const SupplierAssesmentQuestionTemplate = ({ question }) => {
  const [supplierAssesmentAnswers, setSupplierAssesmentAnswers] = useAtom(
    supplierAssesmentAnswersAtom
  );
  const [supplierAssesmentComments, setSupplierAssesmentComments] = useAtom(
    supplierAssesmentCommentsAtom
  );

  const setIsSupplierAssesmentModified = useSetAtom(
    isSupplierAssesmentModifiedAtom
  );

  const updateAnswer = (e) => {
    // console.log("e.target.value", e.target.value);

    switch (e.target.value) {
      case "oui":
        setSupplierAssesmentAnswers({
          ...supplierAssesmentAnswers,
          [`Q${question.id}Answer`]: "oui",
        });
        break;

      case "non":
        setSupplierAssesmentAnswers({
          ...supplierAssesmentAnswers,
          [`Q${question.id}Answer`]: "non",
        });
        break;

      case "NA":
        setSupplierAssesmentAnswers({
          ...supplierAssesmentAnswers,
          [`Q${question.id}Answer`]: "NA",
        });
        break;

      default:
    }

    setIsSupplierAssesmentModified(true);
  };

  const updateComment = (e) => {
    const sanitizedComment = cleanInput(e.target.value);
    setSupplierAssesmentComments({
      ...supplierAssesmentComments,
      [`Q${question.id}Comment`]: sanitizedComment,
    });

    setIsSupplierAssesmentModified(true);
  };

  return (
    <>
      <div className="supplier-assesment-single-question-container">
        <div className="supplier-assesment-single-question-header">
          <div className="supplier-assesment-question-id-container">
            Question {question.id}
          </div>
        </div>
        <div className="supplier-assesment-question-content-container">
          {question.content}
        </div>

        <div className="supplier-assesment-answer-container">
          <div className="supplier-assesment-input-container">
            <input
              type="radio"
              id={`Q${question.id}-oui`}
              name={`Q${question.id}Answer`}
              value="oui"
              className="cursor-pointer"
              checked={
                supplierAssesmentAnswers &&
                supplierAssesmentAnswers[`Q${question.id}Answer`] === "oui"
                  ? true
                  : false
              }
              onChange={(e) => updateAnswer(e)}
            />
            <label
              htmlFor={`Q${question.id}-oui`}
              className="supplier-assesment-input-label"
            >
              oui
            </label>

            <div className="supplier-assesment-input-container">
              <input
                type="radio"
                id={`Q${question.id}-non`}
                name={`Q${question.id}Answer`}
                value="non"
                className="cursor-pointer"
                checked={
                  supplierAssesmentAnswers &&
                  supplierAssesmentAnswers[`Q${question.id}Answer`] === "non"
                    ? true
                    : false
                }
                onChange={(e) => updateAnswer(e)}
              />
              <label
                htmlFor={`Q${question.id}-non`}
                className="supplier-assesment-input-label"
              >
                non
              </label>
            </div>

            <div className="supplier-assesment-input-container">
              <input
                type="radio"
                id={`Q${question.id}-NA`}
                name={`Q${question.id}Answer`}
                value="NA"
                className=" cursor-pointer"
                checked={
                  supplierAssesmentAnswers &&
                  supplierAssesmentAnswers[`Q${question.id}Answer`] === "NA"
                    ? true
                    : false
                }
                onChange={(e) => updateAnswer(e)}
              />
              <label
                htmlFor={`Q${question.id}-NA`}
                className="supplier-assesment-input-label"
              >
                non applicable / je ne sais pas
              </label>
            </div>
          </div>

          <div className="supplier-assesment-comment-container">
            <div>
              <span className="supplier-assesment-comment-label">
                Commentaire :
              </span>
            </div>

            <div>
              <textarea
                name={`Q${question.id}Comment`}
                onInput={(e) => updateComment(e)}
                className="supplier-assesment-comment-input"
                placeholder={
                  supplierAssesmentComments &&
                  supplierAssesmentComments[`Q${question.id}Comment`] &&
                  supplierAssesmentComments[`Q${question.id}Comment`]
                }
              />
            </div>

            {/* <label for={`Q${question.id}Comment`}>Commentaire :</label> */}
            {/* 
            <input
              type="text"
              name={`Q${question.id}Comment`}
              placeholder={
                supplierAssesmentComments &&
                supplierAssesmentComments[`Q${question.id}Comment`] &&
                supplierAssesmentComments[`Q${question.id}Comment`]
              }
              className="comment"
              onChange={(e) => updateComment(e)}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierAssesmentQuestionTemplate;
