import { useAtom, useSetAtom } from "jotai";
import React, { useEffect, useState } from "react";
import {
  areUserInformationsCompletedAtom,
  authUserDetailsAtom,
} from "../../../atoms/AuthenticationAtom";
import { getAuthUserDetails } from "../../../utils/functions/Version3/functionsForUserInformationsManagement";
import {
  cleanInput,
  parseDate,
  parsePhoneNumber,
  validatePhoneFormat,
  validateSIRETFormat,
} from "../../../utils/functions/Version3/functionsForFormsInputsManagement";
import { IoMdInformationCircle } from "react-icons/io";
import {
  checkInformationsCompletion,
  saveUserInformationsInBackend,
} from "../../../utils/functions/Version3/functionsForUserInformationsManagement";
import { useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import Tooltip from "./components/Tooltip";
import UserInformationsEditionFormErrorMessages from "./components/UserInformationsEditionFormErrorMessages";
import manWithBigPencil from "../../../images/man-with-big-pencil.png";

const UserInformationsEditionV3 = () => {
  var editedOrganization;
  var editedPhone;
  var editedContact;
  var editedAddress;
  var editedSiret;
  var editedAvailability;

  const [phoneInputValidity, setPhoneInputValidity] = useState(true);
  const [siretInputValidity, setSiretInputValidity] = useState(true);
  const [dateInputValidity, setDateInputValidity] = useState(true);
  const navigate = useNavigate();
  const [nextBusinessDay, setNextBusinessDay] = useState();
  const setAreUserInformationsCompleted = useSetAtom(
    areUserInformationsCompletedAtom
  );
  const [userDetails, setUserDetails] = useAtom(authUserDetailsAtom);
  const [displayErrorMessages, setDisplayErrorMessages] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAuthUserDetails(setUserDetails);

    // setCaptcha(generateCaptcha());
    getNextBusinessDay();
  }, [setUserDetails]);

  const getNextBusinessDay = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();

    // Définir le jour actuel comme le jour de départ
    let nextWorkingDay = new Date(today);

    // Si c'est vendredi (jour 5), ajoutez 3 jours pour passer à lundi
    if (dayOfWeek === 5) {
      nextWorkingDay.setDate(today.getDate() + 3);
    } else {
      // Sinon, ajoutez 1 jour pour passer au jour ouvré suivant
      nextWorkingDay.setDate(today.getDate() + 2);
    }
    // Définir l'heure sur 9h
    nextWorkingDay.setHours(9, 0, 0, 0);

    setNextBusinessDay(nextWorkingDay);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log("dispo : ", e.target.availability.value)

    // Captcha checking
    // validateCaptcha(e.target.captcha.value, captcha)
    //   ? setIsCaptchaValid(true)
    //   : setIsCaptchaValid(false);

    // Check inputs content + check script injections
    inputsChecking(e);

    // Check phone format
    if (isPhoneFormatValid(editedPhone)) {
      setPhoneInputValidity(true);
    } else {
      setPhoneInputValidity(false);
      setDisplayErrorMessages(true);
    }

    // Check siret format
    if (isSiretFormatValid(editedSiret)) {
      setSiretInputValidity(true);
    } else {
      setSiretInputValidity(false);
      setDisplayErrorMessages(true);
    }

    // Check date validity
    if (isDateValid(e.target.availability.value)) {
      setDateInputValidity(true);
    } else {
      setDateInputValidity(false);
      setDisplayErrorMessages(true);
    }

    if (
      // validateCaptcha(e.target.captcha.value, captcha) &&
      isPhoneFormatValid(editedPhone) &&
      isSiretFormatValid(editedSiret) &&
      isDateValid(e.target.availability.value)
    ) {
      // data updating the backend with the new inputs
      const data = {
        organization: editedOrganization,
        phone: editedPhone,
        contact: editedContact,
        address: editedAddress,
        siret: editedSiret,
        availability: editedAvailability,
      };

      checkInformationsCompletion(data)
        ? setAreUserInformationsCompleted(true)
        : setAreUserInformationsCompleted(false);

      saveUserInformationsInBackend(data);

      // data updating the local storage with the new inputs
      // authUserData.user.organization = editedOrganization;
      // authUserData.user.phone = editedPhone;
      // authUserData.user.contact = editedContact;
      // authUserData.user.address = editedAddress;
      // authUserData.user.siret = editedSiret;
      // authUserData.user.availability = editedAvailability;
      // setAuthUserData(authUserData);

      navigate("/user-informations");
    }
  };

  const inputsChecking = (e) => {
    e.target.organization.value === "" ||
    containsSpacesOnly(e.target.organization.value)
      ? (editedOrganization = userDetails.organization)
      : (editedOrganization = cleanInput(e.target.organization.value));

    e.target.contact.value === "" || containsSpacesOnly(e.target.contact.value)
      ? (editedContact = userDetails.contact)
      : (editedContact = cleanInput(e.target.contact.value));

    e.target.phone.value === "" || containsSpacesOnly(e.target.phone.value)
      ? (editedPhone = userDetails.phone)
      : (editedPhone = cleanInput(e.target.phone.value));

    e.target.address.value === "" || containsSpacesOnly(e.target.address.value)
      ? (editedAddress = userDetails.address)
      : (editedAddress = cleanInput(e.target.address.value));

    e.target.siret.value === "" || containsSpacesOnly(e.target.siret.value)
      ? (editedSiret = userDetails.siret)
      : (editedSiret = cleanInput(e.target.siret.value));

    e.target.availability.value === "" ||
    containsSpacesOnly(e.target.availability.value)
      ? (editedAvailability = userDetails.availability)
      : (editedAvailability = parseDate(
          cleanInput(e.target.availability.value)
        ));
  };

  const containsSpacesOnly = (string) => {
    const onlySpaces = new RegExp(/^\s*$/);
    return onlySpaces.test(string);
  };

  const isPhoneFormatValid = (phoneNumber) => {
    const validationResult = validatePhoneFormat(phoneNumber);
    return validationResult ? true : false;
  };

  const isSiretFormatValid = (siret) => {
    const validationResult = validateSIRETFormat(siret);
    return validationResult ? true : false;
  };

  const isDateValid = (date) => {
    if (date !== "") {
      // Check if the day is not saturday or sunday, between 9h and 17h59.
      const selectedDay = new Date(date);

      const isWeekday = selectedDay.getDay() >= 1 && selectedDay.getDay() <= 5;

      const isBusinessHours =
        selectedDay.getHours() >= 9 && selectedDay.getHours() <= 17;

      if (!isWeekday || !isBusinessHours) {
        setDateInputValidity(false);
        return false;
      } else {
        setDateInputValidity(true);
        return true;
      }
    } else {
      setDateInputValidity(true);
      return true;
    }
  };

  return (
    <>
      <div className="user-informations-container">
        <div className="page-title-container">
          <div className="page-title-icon-and-text">
            <div className="page-title-icon">
              {" "}
              <FaUserCircle />
            </div>
            <div className="page-title-text">Édition des informations</div>
          </div>
        </div>
        <div className="user-informations-request-container">
          <h1>
            Veuillez compléter ces informations avant d'accéder au tableau de
            bord.
          </h1>
        </div>

        <form onSubmit={(e) => handleSubmit(e)}>
          <div id="user-informations-buttons-container">
            <div>
              <button id="user-informations-validation-button" type="submit">
                VALIDER
              </button>
            </div>

            <div onClick={() => navigate("/user-informations")}>
              <button id="user-informations-cancel-button">ANNULER</button>
            </div>
          </div>

          <div className="all-informations-container">
            <div className="single-information-container">
              <div className="information-label">Société </div>
              <div className="information-text">
                <input
                  // required
                  maxLength="100"
                  type="text"
                  className="information-input"
                  aria-label="organization"
                  name="organization"
                  placeholder={userDetails && userDetails.organization}
                />
              </div>
            </div>
            <div className="single-information-container">
              <div className="information-label">Utilisateur du compte </div>
              <div className="information-text">
                <input
                  // required
                  maxLength="100"
                  type="text"
                  className="information-input"
                  aria-label="contact"
                  name="contact"
                  placeholder={userDetails && userDetails.contact}
                />
              </div>
            </div>
            <div className="single-information-container">
              <div className="information-label">Téléphone </div>
              <div className="information-text">
                <input
                  // required
                  maxLength="10"
                  type="phone"
                  className="information-input"
                  aria-label="phone"
                  name="phone"
                  placeholder={
                    userDetails && parsePhoneNumber(userDetails.phone)
                  }
                />
              </div>
            </div>
            <div className="single-information-container">
              <div className="information-label">Adresse </div>
              <div className="information-text">
                <input
                  // required
                  maxLength="100"
                  type="text"
                  className="information-input"
                  aria-label="address"
                  name="address"
                  placeholder={userDetails && userDetails.address}
                />
              </div>
            </div>
            <div className="single-information-container">
              <div className="information-label">SIRET </div>
              <div className="information-text">
                <input
                  // required
                  maxLength="14"
                  type="text"
                  className="information-input"
                  aria-label="siret"
                  name="siret"
                  placeholder={userDetails && userDetails.siret}
                />
              </div>
            </div>
            <div className="single-information-container">
              <div className="information-label">Email </div>
              <div className="information-text">
                {userDetails && userDetails.email}
              </div>
            </div>
            <div className="single-information-container">
              <div className="information-label">
                Être rappelé par un expert
                <IoMdInformationCircle
                  color="#539bff"
                  size="1.1rem"
                  onClick={() => setShowTooltip(true)}
                  className={"cursor-pointer"}
                />
              </div>
              <div className="information-text">
                <input
                  // required
                  type="datetime-local"
                  name="availability"
                  min={
                    nextBusinessDay &&
                    nextBusinessDay.toISOString().slice(0, 16)
                  }
                  className="information-input"
                  // placeholder={}
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      {showTooltip && <Tooltip setShowTooltip={setShowTooltip} />}

      {displayErrorMessages && (
        <UserInformationsEditionFormErrorMessages
          phoneInputValidity={phoneInputValidity}
          siretInputValidity={siretInputValidity}
          dateInputValidity={dateInputValidity}
          setDisplayErrorMessages={setDisplayErrorMessages}
        />
      )}

      <div id="user-informations-edition-illustration-container">
          <img
            src={manWithBigPencil}
            alt="illustration de cybersécurité"
            className="user-informations-edition-illustration"
          />
      </div>
    </>
  );
};

export default UserInformationsEditionV3;
