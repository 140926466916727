import React, { useEffect } from "react";

import { passwordStrength } from "check-password-strength";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../../utils/functions/Version3/functionsForResetUserPassword";

import { cleanInput } from "../../../utils/functions/Version3/functionsForFormsInputsManagement";
import { selectedToolAtom } from "../../../atoms/MenusSelectionAtom";
import { useSetAtom } from "jotai";
import Cookies from "js-cookie";
import StrongPasswordInstructionsV3 from "../SignInUpV3/components/StrongPasswordInstructionsV3";

const ResetPasswordFormV3 = () => {
  const token = useParams().token;
  const navigate = useNavigate();
  // const [displayPasswordInstructions, setDisplayPasswordInstructions] =
  //   useState(false);

  const setSelectedTool = useSetAtom(selectedToolAtom);

  useEffect(() => {
    if (Cookies.get(process.env.REACT_APP_COOKIES_TOKEN_SESSION)) {
      Cookies.remove(process.env.REACT_APP_COOKIES_TOKEN_SESSION);
      Cookies.remove(process.env.REACT_APP_COOKIES_AUTHENTICATED_USER_ID);
    }

    setSelectedTool("no-selection");
  }, [setSelectedTool]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const password = cleanInput(e.target.password.value);
    const passwordConfirmation = cleanInput(
      e.target.passwordconfirmation.value
    );

    if (passwordStrength(password).value !== "Strong") {
      alert("Mot de passe trop faible.");
    } else if (password !== passwordConfirmation) {
      alert("Les mots de passe saisis ne correspondent pas.");
    } else {
      resetPassword(token, password, passwordConfirmation);
      navigate("/user-informations");
    }
  };

  return (
    <>
      <div id="reset-password-modal">
        <form onSubmit={(e) => handleSubmit(e)} id="reset-password-form">
          <div className="margin-bottom-20px">
            <h2 className="font-dark-blue1">
              Réinitialisation de mot de passe
            </h2>
          </div>

          <StrongPasswordInstructionsV3 />

          <div className="reset-password-input-container">
            <input
              required
              minLength="12"
              maxLength="50"
              type="password"
              name="password"
              placeholder="Nouveau mot de passe"
              className="reset-password-input"
            />
          </div>
          <div className="reset-password-input-container">
            <input
              required
              minLength="12"
              maxLength="50"
              type="password"
              name="passwordconfirmation"
              placeholder="Confirmer mot de passe"
              className="reset-password-input"
            />
          </div>

          <div id="reset-password-submit-button-container">
            <button type="submit" id="reset-password-submit-button">
              OK
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ResetPasswordFormV3;
