import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import manWithShield from "../../../../images/character-shield.png";

const ToolDescription = ({ setShowToolDescription }) => {
  const [toolTitle, setToolTitle] = useState("");
  const [toolDescription, setToolDescription] = useState("");
  const [CTAText, setCTAText] = useState("");

  const location = useLocation();

  useEffect(() => {
    const defineTexts = () => {
      if (location.pathname === "/preaudit-welcome") {
        setToolTitle("Audit gratuit simplifié ISO 27001");
        setToolDescription(
          "Alliance Cyber Technologies met à disposition de votre entreprise un questionnaire d'audit préliminaire ISO 27001 comportant 20 questions, conçu pour renforcer la sécurité de votre organisation de manière proactive. La norme ISO 27001 est une référence mondiale en matière de gestion de la sécurité de l'information, et notre outil vous permet de définir votre premier plan d'action."
        );
        setCTAText("Auditez-vous maintenant !");
      }

      if (location.pathname === "/supplier-assesment-welcome") {
        setToolTitle(
          "Évaluation gratuite de la cybersécurité de vos fournisseurs"
        );
        setToolDescription(
          "La cybersécurité de vos fournisseurs est une composante essentielle de la protection globale de votre entreprise. Alliance Cyber Technologies a créé un questionnaire gratuit d'évaluation de la cybersécurité des fournisseurs qui vous offre une approche proactive pour identifier et atténuer les risques à travers 66 questions de cybersécurité."
        );
        setCTAText("Évaluez vos fournisseurs maintenant !");
      }

      if (location.pathname === "/audit-rgpd-welcome") {
        setToolTitle("Audit gratuit et simplifié RGPD");
        setToolDescription(
          "Naviguez dans le monde complexe de la protection des données avec l’outil d'audit RGPD simple et efficace d’Alliance Cyber Technologies. L'audit RGPD est essentiel pour garantir que votre organisation respecte les normes de confidentialité et protège les informations personnelles de vos clients. Notre questionnaire d'audit RGPD vous offre une vue d'ensemble claire des données personnelles traitées par votre organisation."
        );
        setCTAText("Commencez votre audit RPGD !");
      }
    };
    defineTexts();
  }, [location.pathname]);

  return (
    <>
      <div id="tool-description-container">
        <div id="tool-description-title-container">
          <h2 id="tool-description-title">{toolTitle}</h2>
        </div>
        <div id="tool-description-image4-container">
          <img
            src={manWithShield}
            alt="logo de la société Alliance Cyber Technologies"
          />
        </div>

        <div id="tool-description-text-container">
          <p id="tool-description-text">{toolDescription}</p>{" "}
        </div>
        <div id="tool-description-benefits-container">
          <ul>
            <li>- Générez facilement vos indicateurs via un graphique.</li>
            <li>
              - Contactez gratuitement un expert en cybersécurité et obtenez une
              analyse détaillée de vos résultats.
            </li>
            <li>
              - Vos données sont stockées sur des serveurs sécurisés en Europe
              dans un environnement conforme au RGPD.
            </li>
          </ul>
        </div>

        <div id="tool-description-cta-container">
          <button
            id="tool-description-cta-button"
            onClick={() => setShowToolDescription(false)}
          >
            {CTAText}
          </button>
        </div>
      </div>
    </>
  );
};

export default ToolDescription;
