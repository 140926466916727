import { nanoid } from "nanoid";
import React from "react";
import QuestionStatus from "./QuestionStatus";
import { BsArrowUpCircleFill } from "react-icons/bs";
import { JSONPreaudit } from "../../../../../utils/JSON/preauditQuestions";
import { FiSave } from "react-icons/fi";

const PreauditProgress = ({ handleClick }) => {
  return (
    <>
      <div id="progress-container" className="preaudit-progress-animation">
        <div id="progress-header-container">
          <div id="progress-title-container" onClick={() => handleClick()}>
            <FiSave size="2rem" color="white" />
          </div>
          <div id="back-to-top-container">
            <div>
              <abbr title="Vers le haut de page">
                <BsArrowUpCircleFill
                  size="2rem"
                  color="white"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                />
              </abbr>
            </div>
          </div>
        </div>

        <div id="all-status-container">
          {JSONPreaudit.map((question) => (
            <QuestionStatus questionId={question.id} key={nanoid()} />
          ))}
        </div>
      </div>
    </>
  );
};

export default PreauditProgress;
