import React, { useEffect, useRef, useState } from "react";
import {
  supplierAuditThemes,
  supplierAudit,
} from "../../../../../utils/JSON/supplierAssesmentQuestions";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { useAtomValue } from "jotai";
import {
  supplierAssesmentAnswersAtom,
  supplierAssesmentCommentsAtom,
} from "../../../../../atoms/SupplierAssesmentAtom";
import { FaCheck } from "react-icons/fa";
import SupplierAssesmentAnswersAndCommentsModal from "./SupplierAssesmentAnswersAndCommentsModal";
import { parseDate } from "../../../../../utils/functions/Version3/functionsForFormsInputsManagement";
import { IoIosCloseCircle } from "react-icons/io";
import { FaCircle } from "react-icons/fa";
import { BsArrowUpCircleFill } from "react-icons/bs";
////////////////////////////////////////////////////////////////////////////
// All comments in this page are kept as a memo of the PDF feature coding //
////////////////////////////////////////////////////////////////////////////

// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import { BsFiletypePdf } from "react-icons/bs";

ChartJS.register(ArcElement, Tooltip, Legend);

const SupplierAssesmentCharts = ({
  supplierAssesment,
  setDisplaySupplierAssesmentCharts,
}) => {
  // const supplierName = useAtomValue(supplierNameAtom);
  const printRef = useRef();

  // const handleDownloadPdf = async () => {
  //   const element = printRef.current;
  //   const canvas = await html2canvas(element);
  //   const data = canvas.toDataURL("image/png");

  //   const pdf = new jsPDF();
  //   const imgProperties = pdf.getImageProperties(data);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
  //   pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight, "SLOW");
  //   pdf.save("alliance-cyber-technologies.pdf");
  // };

  const supplierAssesmentAnswers = useAtomValue(supplierAssesmentAnswersAtom);
  const supplierAssesmentComments = useAtomValue(supplierAssesmentCommentsAtom);
  const [displayAnswersAndComments, setDisplayAnswersAndComments] =
    useState(false);
  const [questionsThemeForModal, setQuestionsThemeForModal] = useState();
  const [answersAndCommentsForModal, setAnswersAndCommentsForModal] =
    useState();

  const [totalQuestionsByThemes, setTotalQuestionsByThemes] = useState([]);

  const [yesQtyForTheme1, setYesQtyForTheme1] = useState(0);
  const [noQtyForTheme1, setNoQtyForTheme1] = useState(0);
  const [naQtyForTheme1, setNAQtyForTheme1] = useState(0);

  const [yesQtyForTheme2, setYesQtyForTheme2] = useState(0);
  const [noQtyForTheme2, setNoQtyForTheme2] = useState(0);
  const [naQtyForTheme2, setNAQtyForTheme2] = useState(0);

  const [yesQtyForTheme3, setYesQtyForTheme3] = useState(0);
  const [noQtyForTheme3, setNoQtyForTheme3] = useState(0);
  const [naQtyForTheme3, setNAQtyForTheme3] = useState(0);

  const [yesQtyForTheme4, setYesQtyForTheme4] = useState(0);
  const [noQtyForTheme4, setNoQtyForTheme4] = useState(0);
  const [naQtyForTheme4, setNAQtyForTheme4] = useState(0);

  const [yesQtyForTheme5, setYesQtyForTheme5] = useState(0);
  const [noQtyForTheme5, setNoQtyForTheme5] = useState(0);
  const [naQtyForTheme5, setNAQtyForTheme5] = useState(0);

  const [yesQtyForTheme6, setYesQtyForTheme6] = useState(0);
  const [noQtyForTheme6, setNoQtyForTheme6] = useState(0);
  const [naQtyForTheme6, setNAQtyForTheme6] = useState(0);

  const [yesQtyForTheme7, setYesQtyForTheme7] = useState(0);
  const [noQtyForTheme7, setNoQtyForTheme7] = useState(0);
  const [naQtyForTheme7, setNAQtyForTheme7] = useState(0);

  const [yesQtyForTheme8, setYesQtyForTheme8] = useState(0);
  const [noQtyForTheme8, setNoQtyForTheme8] = useState(0);
  const [naQtyForTheme8, setNAQtyForTheme8] = useState(0);

  const [yesQtyForTheme9, setYesQtyForTheme9] = useState(0);
  const [noQtyForTheme9, setNoQtyForTheme9] = useState(0);
  const [naQtyForTheme9, setNAQtyForTheme9] = useState(0);

  const [yesQtyForTheme10, setYesQtyForTheme10] = useState(0);
  const [noQtyForTheme10, setNoQtyForTheme10] = useState(0);
  const [naQtyForTheme10, setNAQtyForTheme10] = useState(0);

  const [yesQtyForTheme11, setYesQtyForTheme11] = useState(0);
  const [noQtyForTheme11, setNoQtyForTheme11] = useState(0);
  const [naQtyForTheme11, setNAQtyForTheme11] = useState(0);

  const [yesQtyForTheme12, setYesQtyForTheme12] = useState(0);
  const [noQtyForTheme12, setNoQtyForTheme12] = useState(0);
  const [naQtyForTheme12, setNAQtyForTheme12] = useState(0);

  const [yesQtyForTheme13, setYesQtyForTheme13] = useState(0);
  const [noQtyForTheme13, setNoQtyForTheme13] = useState(0);
  const [naQtyForTheme13, setNAQtyForTheme13] = useState(0);

  const [yesQtyForTheme14, setYesQtyForTheme14] = useState(0);
  const [noQtyForTheme14, setNoQtyForTheme14] = useState(0);
  const [naQtyForTheme14, setNAQtyForTheme14] = useState(0);

  const [yesQtyForTheme15, setYesQtyForTheme15] = useState(0);
  const [noQtyForTheme15, setNoQtyForTheme15] = useState(0);
  const [naQtyForTheme15, setNAQtyForTheme15] = useState(0);

  const [yesQtyForTheme16, setYesQtyForTheme16] = useState(0);
  const [noQtyForTheme16, setNoQtyForTheme16] = useState(0);
  const [naQtyForTheme16, setNAQtyForTheme16] = useState(0);

  const [yesQtyForTheme17, setYesQtyForTheme17] = useState(0);
  const [noQtyForTheme17, setNoQtyForTheme17] = useState(0);
  const [naQtyForTheme17, setNAQtyForTheme17] = useState(0);

  const [yesQtyForTheme18, setYesQtyForTheme18] = useState(0);
  const [noQtyForTheme18, setNoQtyForTheme18] = useState(0);
  const [naQtyForTheme18, setNAQtyForTheme18] = useState(0);

  const [yesQtyForTheme19, setYesQtyForTheme19] = useState(0);
  const [noQtyForTheme19, setNoQtyForTheme19] = useState(0);
  const [naQtyForTheme19, setNAQtyForTheme19] = useState(0);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
      // [ChartDataLabels],

      datalabels: {
        display: (context) => {
          return context.dataset.data[context.dataIndex] !== 0;
        },
        color: "black",
        formatter: (value, context) => {
          return value !== 0 ? value.toString() : "";
        },
      },
    },
  };

  const dataForTheme1 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme1, noQtyForTheme1, naQtyForTheme1],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme2 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme2, noQtyForTheme2, naQtyForTheme2],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme3 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme3, noQtyForTheme3, naQtyForTheme3],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme4 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme4, noQtyForTheme4, naQtyForTheme4],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme5 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme5, noQtyForTheme5, naQtyForTheme5],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme6 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme6, noQtyForTheme6, naQtyForTheme6],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme7 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme7, noQtyForTheme7, naQtyForTheme7],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme8 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme8, noQtyForTheme8, naQtyForTheme8],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme9 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme9, noQtyForTheme9, naQtyForTheme9],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme10 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme10, noQtyForTheme10, naQtyForTheme10],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme11 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme11, noQtyForTheme11, naQtyForTheme11],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme12 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme12, noQtyForTheme12, naQtyForTheme12],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme13 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme13, noQtyForTheme13, naQtyForTheme13],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme14 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme14, noQtyForTheme14, naQtyForTheme14],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme15 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme15, noQtyForTheme15, naQtyForTheme15],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme16 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme16, noQtyForTheme16, naQtyForTheme16],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme17 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme17, noQtyForTheme17, naQtyForTheme17],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme18 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme18, noQtyForTheme18, naQtyForTheme18],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForTheme19 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForTheme19, noQtyForTheme19, naQtyForTheme19],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const countTotalQuestionsByThemes = () => {
      const themeCounts = [];

      for (let i = 0; i < supplierAuditThemes.length; i++) {
        const themeName = supplierAuditThemes[i].name;
        const occurrences = supplierAudit.reduce((count, item) => {
          if (item.theme === themeName) {
            return count + 1;
          }
          return count;
        }, 0);

        themeCounts.push(occurrences);
      }

      setTotalQuestionsByThemes(themeCounts);
    };

    const calculateStatsForTheme1 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index === 1) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme1(yesCounts);
      setNoQtyForTheme1(noCounts);
      setNAQtyForTheme1(naCounts);
    };

    const calculateStatsForTheme2 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index >= 2 && index <= 10) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme2(yesCounts);
      setNoQtyForTheme2(noCounts);
      setNAQtyForTheme2(naCounts);
    };

    const calculateStatsForTheme3 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index >= 11 && index <= 13) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme3(yesCounts);
      setNoQtyForTheme3(noCounts);
      setNAQtyForTheme3(naCounts);
    };

    const calculateStatsForTheme4 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index >= 14 && index <= 16) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme4(yesCounts);
      setNoQtyForTheme4(noCounts);
      setNAQtyForTheme4(naCounts);
    };

    const calculateStatsForTheme5 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index === 17) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme5(yesCounts);
      setNoQtyForTheme5(noCounts);
      setNAQtyForTheme5(naCounts);
    };

    const calculateStatsForTheme6 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index >= 18 && index <= 19) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme6(yesCounts);
      setNoQtyForTheme6(noCounts);
      setNAQtyForTheme6(naCounts);
    };

    const calculateStatsForTheme7 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index >= 20 && index <= 22) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme7(yesCounts);
      setNoQtyForTheme7(noCounts);
      setNAQtyForTheme7(naCounts);
    };

    const calculateStatsForTheme8 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index >= 23 && index <= 28) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme8(yesCounts);
      setNoQtyForTheme8(noCounts);
      setNAQtyForTheme8(naCounts);
    };

    const calculateStatsForTheme9 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index >= 29 && index <= 35) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme9(yesCounts);
      setNoQtyForTheme9(noCounts);
      setNAQtyForTheme9(naCounts);
    };

    const calculateStatsForTheme10 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index >= 36 && index <= 38) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme10(yesCounts);
      setNoQtyForTheme10(noCounts);
      setNAQtyForTheme10(naCounts);
    };

    const calculateStatsForTheme11 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index >= 39 && index <= 42) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme11(yesCounts);
      setNoQtyForTheme11(noCounts);
      setNAQtyForTheme11(naCounts);
    };

    const calculateStatsForTheme12 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index === 43) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme12(yesCounts);
      setNoQtyForTheme12(noCounts);
      setNAQtyForTheme12(naCounts);
    };

    const calculateStatsForTheme13 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index >= 44 && index <= 48) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme13(yesCounts);
      setNoQtyForTheme13(noCounts);
      setNAQtyForTheme13(naCounts);
    };

    const calculateStatsForTheme14 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index === 49) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme14(yesCounts);
      setNoQtyForTheme14(noCounts);
      setNAQtyForTheme14(naCounts);
    };

    const calculateStatsForTheme15 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index === 50) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme15(yesCounts);
      setNoQtyForTheme15(noCounts);
      setNAQtyForTheme15(naCounts);
    };

    const calculateStatsForTheme16 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index >= 51 && index <= 52) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme16(yesCounts);
      setNoQtyForTheme16(noCounts);
      setNAQtyForTheme16(naCounts);
    };

    const calculateStatsForTheme17 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index === 53) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme17(yesCounts);
      setNoQtyForTheme17(noCounts);
      setNAQtyForTheme17(naCounts);
    };

    const calculateStatsForTheme18 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index >= 54 && index <= 55) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme18(yesCounts);
      setNoQtyForTheme18(noCounts);
      setNAQtyForTheme18(naCounts);
    };

    const calculateStatsForTheme19 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in supplierAssesmentAnswers) {
        const index = parseSupplierAssesmentAnswersKey(key);
        if (index === 56) {
          supplierAssesmentAnswers[key] === "oui" && yesCounts++;
          supplierAssesmentAnswers[key] === "non" && noCounts++;
          supplierAssesmentAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForTheme19(yesCounts);
      setNoQtyForTheme19(noCounts);
      setNAQtyForTheme19(naCounts);
    };

    const initializeAnswersAndComments = () => {
      let answersArray = Object.values(supplierAssesmentAnswers);
      let commentsArray = Object.values(supplierAssesmentComments);
      let answersAndComments = [];

      for (let i = 0; i < answersArray.length; i++) {
        answersAndComments.push(
          answersArray[i] + ". Commentaire : " + commentsArray[i]
        );
      }

      setAnswersAndCommentsForModal(answersAndComments);
    };

    calculateStatsForTheme1();
    calculateStatsForTheme2();
    calculateStatsForTheme3();
    calculateStatsForTheme4();
    calculateStatsForTheme5();
    calculateStatsForTheme6();
    calculateStatsForTheme7();
    calculateStatsForTheme8();
    calculateStatsForTheme9();
    calculateStatsForTheme10();
    calculateStatsForTheme11();
    calculateStatsForTheme12();
    calculateStatsForTheme13();
    calculateStatsForTheme14();
    calculateStatsForTheme15();
    calculateStatsForTheme16();
    calculateStatsForTheme17();
    calculateStatsForTheme18();
    calculateStatsForTheme19();
    countTotalQuestionsByThemes();
    initializeAnswersAndComments();
  }, [supplierAssesmentAnswers, supplierAssesmentComments]);

  const parseSupplierAssesmentAnswersKey = (key) => {
    const parsedKey = parseInt(key.match(/\d+/)[0]);
    return parsedKey;
  };

  // const resetSupplierAssesment = () => {
  //   if (window.confirm("Les données seront remises à zéro. Continuer ?")) {
  //     let initialSupplierAssesmentAnswers = {};
  //     let initialSupplierAssesmentComments = {};

  //     supplierAudit.map((question) => {
  //       initialSupplierAssesmentAnswers = {
  //         ...initialSupplierAssesmentAnswers,
  //         [`Q${question.id}Answer`]: "",
  //       };
  //       initialSupplierAssesmentComments = {
  //         ...initialSupplierAssesmentComments,
  //         [`Q${question.id}Comment`]: "",
  //       };
  //       return question;
  //     });
  //     setSupplierAssesmentAnswers(initialSupplierAssesmentAnswers);
  //     setSupplierAssesmentComments(initialSupplierAssesmentComments);
  //   }
  // };

  const getAnswersAndComments = (questionsTheme) => {
    setQuestionsThemeForModal(questionsTheme);
    setDisplayAnswersAndComments(true);
  };

  return (
    <>
      <div id="supplier-assesment-charts-modal">
        <div id="supplier-assesment-title-container">
          <h2>Évaluation du {parseDate(supplierAssesment.created_at)}</h2>
          <div
            className="cursor-pointer"
            onClick={() => setDisplaySupplierAssesmentCharts(false)}
          >
            <IoIosCloseCircle size="1.75rem" color="#f76a66" />
          </div>
        </div>

        {/* ************************************************************************************************ */}
        {/* Note these charts can not be displayed dynamically with the react-chartjs-2 and chartjs librairies*/}
        {/* Each chart has to be created with its own datas    ***********************************************/}
        {/* ************************************************************************************************ */}

        <div id="supplier-assesment-charts-container">
          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[0])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[0].id}. {supplierAuditThemes[0].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme1 + noQtyForTheme1 + naQtyForTheme1} /{" "}
                    {totalQuestionsByThemes[0]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme1 + noQtyForTheme1 + naQtyForTheme1 ===
                    totalQuestionsByThemes[0] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme1} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme1} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme1} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme1} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[1])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[1].id}. {supplierAuditThemes[1].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme2 + noQtyForTheme2 + naQtyForTheme2} /{" "}
                    {totalQuestionsByThemes[1]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme2 + noQtyForTheme2 + naQtyForTheme2 ===
                    totalQuestionsByThemes[1] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme2} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme2} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme2} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme2} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[2])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[2].id}. {supplierAuditThemes[2].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme3 + noQtyForTheme3 + naQtyForTheme3} /{" "}
                    {totalQuestionsByThemes[2]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme3 + noQtyForTheme3 + naQtyForTheme3 ===
                    totalQuestionsByThemes[2] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme3} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme3} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme3} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme3} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[3])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[3].id}. {supplierAuditThemes[3].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme4 + noQtyForTheme4 + naQtyForTheme4} /{" "}
                    {totalQuestionsByThemes[3]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme4 + noQtyForTheme4 + naQtyForTheme4 ===
                    totalQuestionsByThemes[3] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme4} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme4} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme4} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme4} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[4])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[4].id}. {supplierAuditThemes[4].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme5 + noQtyForTheme5 + naQtyForTheme5} /{" "}
                    {totalQuestionsByThemes[4]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme5 + noQtyForTheme5 + naQtyForTheme5 ===
                    totalQuestionsByThemes[4] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme5} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme5} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme5} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme5} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[5])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[5].id}. {supplierAuditThemes[5].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme6 + noQtyForTheme6 + naQtyForTheme6} /{" "}
                    {totalQuestionsByThemes[5]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme6 + noQtyForTheme6 + naQtyForTheme6 ===
                    totalQuestionsByThemes[5] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme6} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme6} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme6} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme6} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[6])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[6].id}. {supplierAuditThemes[6].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme7 + noQtyForTheme7 + naQtyForTheme7} /{" "}
                    {totalQuestionsByThemes[6]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme7 + noQtyForTheme7 + naQtyForTheme7 ===
                    totalQuestionsByThemes[6] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme7} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme7} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme7} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme7} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[7])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[7].id}. {supplierAuditThemes[7].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme8 + noQtyForTheme8 + naQtyForTheme8} /{" "}
                    {totalQuestionsByThemes[7]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme8 + noQtyForTheme8 + naQtyForTheme8 ===
                    totalQuestionsByThemes[7] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme8} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme8} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme8} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme8} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[8])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[8].id}. {supplierAuditThemes[8].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme9 + noQtyForTheme9 + naQtyForTheme9} /{" "}
                    {totalQuestionsByThemes[8]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme9 + noQtyForTheme9 + naQtyForTheme9 ===
                    totalQuestionsByThemes[8] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme9} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme9} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme9} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme9} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[9])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[9].id}. {supplierAuditThemes[9].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme10 + noQtyForTheme10 + naQtyForTheme10} /{" "}
                    {totalQuestionsByThemes[9]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme10 + noQtyForTheme10 + naQtyForTheme10 ===
                    totalQuestionsByThemes[9] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme10} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme10} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme10} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme10} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[10])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[10].id}. {supplierAuditThemes[10].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme11 + noQtyForTheme11 + naQtyForTheme11} /{" "}
                    {totalQuestionsByThemes[10]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme11 + noQtyForTheme11 + naQtyForTheme11 ===
                    totalQuestionsByThemes[10] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme11} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme11} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme11} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme11} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[11])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[11].id}. {supplierAuditThemes[11].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme12 + noQtyForTheme12 + naQtyForTheme12} /{" "}
                    {totalQuestionsByThemes[11]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme12 + noQtyForTheme12 + naQtyForTheme12 ===
                    totalQuestionsByThemes[11] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme12} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme12} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme12} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme12} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[12])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[12].id}. {supplierAuditThemes[12].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme13 + noQtyForTheme13 + naQtyForTheme13} /{" "}
                    {totalQuestionsByThemes[12]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme13 + noQtyForTheme13 + naQtyForTheme13 ===
                    totalQuestionsByThemes[12] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme13} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme13} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme13} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme13} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[13])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[13].id}. {supplierAuditThemes[13].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme14 + noQtyForTheme14 + naQtyForTheme14} /{" "}
                    {totalQuestionsByThemes[13]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme14 + noQtyForTheme14 + naQtyForTheme14 ===
                    totalQuestionsByThemes[13] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme14} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme14} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme14} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme14} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[14])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[14].id}. {supplierAuditThemes[14].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme15 + noQtyForTheme15 + naQtyForTheme15} /{" "}
                    {totalQuestionsByThemes[14]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme15 + noQtyForTheme15 + naQtyForTheme15 ===
                    totalQuestionsByThemes[14] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme15} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme15} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme15} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme15} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[15])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[15].id}. {supplierAuditThemes[15].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme16 + noQtyForTheme16 + naQtyForTheme16} /{" "}
                    {totalQuestionsByThemes[15]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme16 + noQtyForTheme16 + naQtyForTheme16 ===
                    totalQuestionsByThemes[15] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme16} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme16} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme16} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme16} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[16])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[16].id}. {supplierAuditThemes[16].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme17 + noQtyForTheme17 + naQtyForTheme17} /{" "}
                    {totalQuestionsByThemes[16]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme17 + noQtyForTheme17 + naQtyForTheme17 ===
                    totalQuestionsByThemes[16] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme17} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme17} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme17} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme17} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[17])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[17].id}. {supplierAuditThemes[17].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme18 + noQtyForTheme18 + naQtyForTheme18} /{" "}
                    {totalQuestionsByThemes[17]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme18 + noQtyForTheme18 + naQtyForTheme18 ===
                    totalQuestionsByThemes[17] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme18} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme18} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme18} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme18} NA</div>
            </div>
          </div>

          <div
            className="single-supplier-assesment-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[18])}
          >
            <div className="supplier-assesment-chart-title-container">
              <div className="supplier-assesment-title-header">
                <div className="supplier-assesment-category-container">
                  {supplierAuditThemes[18].id}. {supplierAuditThemes[18].name}
                </div>

                <div className="supplier-assesment-total-questions-container">
                  <div className="supplier-assesment-total-questions">
                    Réponses données :{" "}
                    {yesQtyForTheme19 + noQtyForTheme19 + naQtyForTheme19} /{" "}
                    {totalQuestionsByThemes[18]}
                  </div>
                  <div className="supplier-assesment-completion-icon-container">
                    {yesQtyForTheme19 + noQtyForTheme19 + naQtyForTheme19 ===
                    totalQuestionsByThemes[18] ? (
                      <>
                        <FaCircle size="0.90rem" className="font-green" />
                      </>
                    ) : (
                      <>
                        <FaCircle size="0.90rem" className="font-red" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="supplier-assesment-chart-container">
              <Pie data={dataForTheme19} options={options} />
            </div>

            <div className="answers-quantities-container">
              <div className="answers-quantity-yes">{yesQtyForTheme19} OUI</div>

              <div className="answers-quantity-no">{noQtyForTheme19} NON</div>

              <div className="answers-quantity-na">{naQtyForTheme19} NA</div>
            </div>
          </div>
        </div>

        {/* <div id="supplier-assesment-charts-container" ref={printRef}>
          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[0])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[0].id}. {supplierAuditThemes[0].name}
            </div>

            <div className="chart-container">
              <Pie data={dataForTheme1} options={options} />
            </div>

            <div className="total-questions-container">
              <div className="total-questions">
                Réponses : {yesQtyForTheme1 + noQtyForTheme1 + naQtyForTheme1} /{" "}
                {totalQuestionsByThemes[0]}
              </div>
              <div className="completion-icon-container">
                {yesQtyForTheme1 + noQtyForTheme1 + naQtyForTheme1 ===
                  totalQuestionsByThemes[0] && (
                  <>
                    <FaCheck size="0.75rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[1])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[1].id}. {supplierAuditThemes[1].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme2} options={options} />
            </div>
            <div className="total-questions-container">
              <div className="total-questions">
                Réponses : {yesQtyForTheme2 + noQtyForTheme2 + naQtyForTheme2} /{" "}
                {totalQuestionsByThemes[1]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme2 + noQtyForTheme2 + naQtyForTheme2 ===
                  totalQuestionsByThemes[1] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[2])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[2].id}. {supplierAuditThemes[2].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme3} options={options} />
            </div>

            <div className="total-questions-container">
              <div className="total-questions">
                Réponses : {yesQtyForTheme3 + noQtyForTheme3 + naQtyForTheme3} /{" "}
                {totalQuestionsByThemes[2]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme3 + noQtyForTheme3 + naQtyForTheme3 ===
                  totalQuestionsByThemes[2] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[3])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[3].id}. {supplierAuditThemes[3].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme4} options={options} />
            </div>

            <div className="total-questions-container">
              <div className="total-questions">
                Réponses : {yesQtyForTheme4 + noQtyForTheme4 + naQtyForTheme4} /{" "}
                {totalQuestionsByThemes[3]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme4 + noQtyForTheme4 + naQtyForTheme4 ===
                  totalQuestionsByThemes[3] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[4])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[4].id}. {supplierAuditThemes[4].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme5} options={options} />
            </div>
            <div className="total-questions-container">
              <div className="total-questions">
                Réponses : {yesQtyForTheme5 + noQtyForTheme5 + naQtyForTheme5} /{" "}
                {totalQuestionsByThemes[4]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme5 + noQtyForTheme5 + naQtyForTheme5 ===
                  totalQuestionsByThemes[4] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[5])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[5].id}. {supplierAuditThemes[5].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme6} options={options} />
            </div>
            <div className="total-questions-container">
              <div className="total-questions">
                Réponses : {yesQtyForTheme6 + noQtyForTheme6 + naQtyForTheme6} /{" "}
                {totalQuestionsByThemes[5]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme6 + noQtyForTheme6 + naQtyForTheme6 ===
                  totalQuestionsByThemes[5] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[6])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[6].id}. {supplierAuditThemes[6].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme7} options={options} />
            </div>
            <div className="total-questions-container">
              <div className="total-questions">
                Réponses : {yesQtyForTheme7 + noQtyForTheme7 + naQtyForTheme7} /{" "}
                {totalQuestionsByThemes[6]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme7 + noQtyForTheme7 + naQtyForTheme7 ===
                  totalQuestionsByThemes[6] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[7])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[7].id}. {supplierAuditThemes[7].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme8} options={options} />
            </div>
            <div className="total-questions-container">
              <div className="total-questions">
                Réponses : {yesQtyForTheme8 + noQtyForTheme8 + naQtyForTheme8} /{" "}
                {totalQuestionsByThemes[7]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme8 + noQtyForTheme8 + naQtyForTheme8 ===
                  totalQuestionsByThemes[7] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[8])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[8].id}. {supplierAuditThemes[8].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme9} options={options} />
            </div>
            <div className="total-questions-container">
              <div className="total-questions">
                Réponses : {yesQtyForTheme9 + noQtyForTheme9 + naQtyForTheme9} /{" "}
                {totalQuestionsByThemes[8]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme9 + noQtyForTheme9 + naQtyForTheme9 ===
                  totalQuestionsByThemes[8] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[9])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[9].id}. {supplierAuditThemes[9].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme10} options={options} />
            </div>
            <div className="total-questions-container">
              <div className="total-questions">
                Réponses :{" "}
                {yesQtyForTheme10 + noQtyForTheme10 + naQtyForTheme10} /{" "}
                {totalQuestionsByThemes[9]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme10 + noQtyForTheme10 + naQtyForTheme10 ===
                  totalQuestionsByThemes[9] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[10])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[10].id}. {supplierAuditThemes[10].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme11} options={options} />
            </div>
            <div className="total-questions-container">
              <div className="total-questions">
                Réponses :{" "}
                {yesQtyForTheme11 + noQtyForTheme11 + naQtyForTheme11} /{" "}
                {totalQuestionsByThemes[10]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme11 + noQtyForTheme11 + naQtyForTheme11 ===
                  totalQuestionsByThemes[10] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[11])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[11].id}. {supplierAuditThemes[11].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme12} options={options} />
            </div>
            <div className="total-questions-container">
              <div className="total-questions">
                Réponses :{" "}
                {yesQtyForTheme12 + noQtyForTheme12 + naQtyForTheme12} /{" "}
                {totalQuestionsByThemes[11]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme12 + noQtyForTheme12 + naQtyForTheme12 ===
                  totalQuestionsByThemes[11] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[12])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[12].id}. {supplierAuditThemes[12].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme13} options={options} />
            </div>
            <div className="total-questions-container">
              <div className="total-questions">
                Réponses :{" "}
                {yesQtyForTheme13 + noQtyForTheme13 + naQtyForTheme13} /{" "}
                {totalQuestionsByThemes[12]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme13 + noQtyForTheme13 + naQtyForTheme13 ===
                  totalQuestionsByThemes[12] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[13])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[13].id}. {supplierAuditThemes[13].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme14} options={options} />
            </div>
            <div className="total-questions-container">
              <div className="total-questions">
                Réponses :{" "}
                {yesQtyForTheme14 + noQtyForTheme14 + naQtyForTheme14} /{" "}
                {totalQuestionsByThemes[13]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme14 + noQtyForTheme14 + naQtyForTheme14 ===
                  totalQuestionsByThemes[13] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[14])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[14].id}. {supplierAuditThemes[14].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme15} options={options} />
            </div>
            <div className="total-questions-container">
              <div className="total-questions">
                Réponses :{" "}
                {yesQtyForTheme15 + noQtyForTheme15 + naQtyForTheme15} /{" "}
                {totalQuestionsByThemes[14]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme15 + noQtyForTheme15 + naQtyForTheme15 ===
                  totalQuestionsByThemes[14] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[15])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[15].id}. {supplierAuditThemes[15].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme16} options={options} />
            </div>
            <div className="total-questions-container">
              <div className="total-questions">
                Réponses :{" "}
                {yesQtyForTheme16 + noQtyForTheme16 + naQtyForTheme16} /{" "}
                {totalQuestionsByThemes[15]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme16 + noQtyForTheme16 + naQtyForTheme16 ===
                  totalQuestionsByThemes[15] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[16])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[16].id}. {supplierAuditThemes[16].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme17} options={options} />
            </div>
            <div className="total-questions-container">
              <div className="total-questions">
                Réponses :{" "}
                {yesQtyForTheme17 + noQtyForTheme17 + naQtyForTheme17} /{" "}
                {totalQuestionsByThemes[16]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme17 + noQtyForTheme17 + naQtyForTheme17 ===
                  totalQuestionsByThemes[16] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[17])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[17].id}. {supplierAuditThemes[17].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme18} options={options} />
            </div>
            <div className="total-questions-container">
              <div className="total-questions">
                Réponses :{" "}
                {yesQtyForTheme18 + noQtyForTheme18 + naQtyForTheme18} /{" "}
                {totalQuestionsByThemes[17]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme18 + noQtyForTheme18 + naQtyForTheme18 ===
                  totalQuestionsByThemes[17] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className="single-chart-container"
            onClick={() => getAnswersAndComments(supplierAuditThemes[18])}
          >
            <div className="chart-title-container">
              {supplierAuditThemes[18].id}. {supplierAuditThemes[18].name}
            </div>
            <div className="chart-container">
              <Pie data={dataForTheme19} options={options} />
            </div>
            <div className="total-questions-container">
              <div className="total-questions">
                Réponses :{" "}
                {yesQtyForTheme19 + noQtyForTheme19 + naQtyForTheme19} /{" "}
                {totalQuestionsByThemes[18]}
              </div>

              <div className="completion-icon-container">
                {yesQtyForTheme19 + noQtyForTheme19 + naQtyForTheme19 ===
                  totalQuestionsByThemes[18] && (
                  <>
                    <FaCheck size="1rem" className="font-green" />
                  </>
                )}
              </div>
            </div>
          </div>
        </div> */}
      </div>

      {displayAnswersAndComments && (
        <SupplierAssesmentAnswersAndCommentsModal
          // supplierAssesment={supplierAssesment}
          setDisplayAnswersAndComments={setDisplayAnswersAndComments}
          questionsTheme={questionsThemeForModal}
          answersAndCommentsForModal={answersAndCommentsForModal}
        />
      )}
    </>
  );
};

export default SupplierAssesmentCharts;
