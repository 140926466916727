import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const supplierNameAtom = atom();
export const supplierAssesmentAnswersAtom = atomWithStorage(
  "supplier-assesment-answers"
);
export const supplierAssesmentCommentsAtom = atomWithStorage(
  "supplier-assesment-comments"
);
export const supplierAssesmentToSaveAtom = atomWithStorage(
  "supplier-assesment-to-save"
);

export const supplierAssesmentToUpdateIdAtom = atomWithStorage(
  "supplierAssesmentId"
);
export const supplierAssesmentToUpdateTokenAtom = atomWithStorage(
  "supplierAssesmentToken"
);
export const supplierAssesmentToUpdateSupplierAtom = atomWithStorage(
  "supplierAssesmentSupplier"
);
export const isSupplierAssesmentModifiedAtom = atom(false);
