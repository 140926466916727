import React, { useEffect, useRef, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { authUserDetailsAtom } from "../../../../atoms/AuthenticationAtom";
// import { useUA } from "use-ua-parser-js";
import { useNavigate } from "react-router-dom";
import {
  auditRGPDAnswersAtom,
  auditRGPDCommentsAtom,
  auditRGPDProgressAtom,
  isAuditRGPDModifiedAtom,
} from "../../../../atoms/AuditRGPDAtom ";
import Cookies from "js-cookie";
import {
  // checkInformationsCompletion,
  getAuthUserDetails,
} from "../../../../utils/functions/Version3/functionsForUserInformationsManagement";
// import { auditRGPDQuestions } from "../../../utils/JSON/auditRGPDQuestions";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
import { getCurrentUserAuditRGPD } from "../../../../utils/functions/Version3/functionsForAuditRGPDManagement";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import AuditRGPDCharts from "./components/AuditRGPDCharts";
import AuditRGPDValidationPrompt from "./components/AuditRGPDValidationPrompt";

const AuditRGPDDashboardV3 = () => {
  const printRef = useRef();

  const [userDetails, setAuthUserDetails] = useAtom(authUserDetailsAtom);
  const setAuditRGPDAnswers = useSetAtom(auditRGPDAnswersAtom);
  const setAuditRGPDComments = useSetAtom(auditRGPDCommentsAtom);
  const isAuditRGPDModified = useAtomValue(isAuditRGPDModifiedAtom);
  // const printRef = useRef();
  const [
    displayAuditRGPDValidationPrompt,
    setDisplayAuditRGPDValidationPrompt,
  ] = useState(false);
  const [auditRGPDProgress, setAuditRGPDProgress] = useAtom(
    auditRGPDProgressAtom
  );
  // const [displayAuditRGPDCharts, setDisplayAuditRGPDCharts] = useState(true);
  // const uADetails = useUA();
  const navigate = useNavigate();

  useEffect(() => {
    isAuditRGPDModified
      ? setDisplayAuditRGPDValidationPrompt(true)
      : getCurrentUserAuditRGPD(
          Cookies.get(process.env.REACT_APP_COOKIES_AUTHENTICATED_USER_ID),
          setAuditRGPDAnswers,
          setAuditRGPDComments
        );

    setAuditRGPDProgress(null);

    // Here we get the userDetails if the page has been refreshed and then the userDetailsAtom reinitialized. These are used by the function checkInformationsCompletion in handlePreaudit.
    if (!userDetails) {
      getAuthUserDetails(setAuthUserDetails);
    }
  }, [
    isAuditRGPDModified,
    auditRGPDProgress,
    setAuditRGPDProgress,
    userDetails,
    setAuthUserDetails,
    setAuditRGPDAnswers,
    setAuditRGPDComments,
  ]);

  // const handleEditAuditRGPD = () => {
  //   if (checkInformationsCompletion(userDetails)) {
  //     navigate("/audit-rgpd-questions");
  //   } else {
  //     alert(
  //       "Avant d'utiliser l'audit RGPD, merci de compléter les informations qui vont suivre."
  //     );
  //     navigate("/user-informations-edition");
  //   }
  // };

  const handleDownloadPdf = async () => {
    if (window.confirm("Télécharger les résultats au format PDF ?")) {
      const element = printRef.current;
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/png");

      const pdf = new jsPDF();
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight, "SLOW");
      pdf.save("alliance-cyber-technologies.pdf");
    }
  };

  return (
    <>
      <div id="audit-rgpd-dashboard-container">
        <div id="#audit-rgpd-description-container">
          L'audit RGPD est essentiel pour garantir que votre organisation
          respecte les normes de confidentialité et protège les informations
          personnelles de vos clients. Notre questionnaire d'audit RGPD vous
          offre une vue d'ensemble claire des données personnelles traitées par
          votre organisation.
          <br />
          <br /> Pour obtenir une analyse par l'un de nos experts en
          cybersécurité, <u>à titre grâcieux</u>, demandez à être contacté
          depuis l'onglet{" "}
          <span
            className="cursor-pointer font-orange"
            onClick={() => navigate("/user-informations-edition")}
          >
            vos informations
          </span>
          .
        </div>
        <div id="audit-rgpd-dashboard-charts-and-buttons-container">
          <div id="audit-rgpd-dashboard-buttons-container">
            <div
              className="audit-rgpd-dashboard-button-container"
              onClick={() => navigate("/audit-rgpd-questions")}
            >
              <button
                id="audit-rgpd-questions-button"
                className="audit-rgpd-dashboard-button"
              >
                QUESTIONNAIRE
                <br />
                RGPD
              </button>
            </div>
            {/* <div
            className="audit-rgpd-dashboard-button-container"
            // onClick={() => resetPreaudit()}
          >
            <button id="audit-rgpd-raz-button" className="audit-rgpd-dashboard-button">
              R A Z
            </button>
          </div> */}
            <div
              className="audit-rgpd-dashboard-button-container"
              onClick={handleDownloadPdf}
            >
              <button
                id="audit-rgpd-pdf-button"
                className="audit-rgpd-dashboard-button"
              >
                PDF
              </button>
            </div>
          </div>

          <div id="audit-rgpd-charts-container" ref={printRef}>
            <AuditRGPDCharts />
          </div>
        </div>
      </div>

      {displayAuditRGPDValidationPrompt && (
        <AuditRGPDValidationPrompt
          setDisplayAuditRGPDValidationPrompt={
            setDisplayAuditRGPDValidationPrompt
          }
        />
      )}
    </>
  );
};

export default AuditRGPDDashboardV3;
