import React, { useEffect, useState } from "react";
import { isItEven } from "../../../../../utils/functions/Version3/functionsForNumbers";
import SupplierDetails from "./SupplierDetails";
import SupplierAssesmentListing from "./SupplierAssesmentListing";
import { sendAssesmentLink } from "../../../../../utils/functions/Version3/functionsForSupplierAssesmentManagement";
import { checkInformationsCompletion } from "../../../../../utils/functions/Version3/functionsForUserInformationsManagement";
import {
  areUserInformationsCompletedAtom,
  authUserDetailsAtom,
} from "../../../../../atoms/AuthenticationAtom";
import { useAtomValue, useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { FaChartPie } from "react-icons/fa";
import { GoEye } from "react-icons/go";

const SupplierSummaryTemplate = ({ supplier, index }) => {
  const [lineStyle, setLineStyle] = useState();
  const [displaySupplierDetails, setDisplaySupplierDetails] = useState(false);
  const [
    displaySupplierAssesmentsListing,
    setDisplaySupplierAssesmentsListing,
  ] = useState(false);
  const userDetails = useAtomValue(authUserDetailsAtom);
  const setAreUserInformationsCompleted = useSetAtom(
    areUserInformationsCompletedAtom
  );
  const navigate = useNavigate();

  useEffect(() => {
    isItEven(index) ? setLineStyle("even-line") : setLineStyle("odd-line");
  }, [index, setLineStyle]);

  const evaluate = () => {
    if (checkInformationsCompletion(userDetails)) {
      setAreUserInformationsCompleted(true);
      if (
        window.confirm(
          `Un questionnaire va être envoyé à ${supplier.organization}. Poursuivre ?`
        )
      ) {
        sendAssesmentLink(supplier.id);
      }
    } else {
      setAreUserInformationsCompleted(false);
      alert("Des informations doivent être complétées avant de poursuivre.");
      navigate("/user-informations-edition");
    }
  };

  return (
    <>
      <div
        className={`supplier-summary-container ${
          (displaySupplierDetails || displaySupplierAssesmentsListing) &&
          "supplier-is-selected"
        }`}
      >
        <div
          className="supplier-container"
          onClick={() => setDisplaySupplierDetails(!displaySupplierDetails)}
        >
          {index}. {supplier.organization}
        </div>

        <div className="suppliers-listing-button-container">
          <button
            className="suppliers-listing-button"
            onClick={() => evaluate()}
          >
            <FaChartPie />{" "}
            <span className="margin-left-10px font-dark-blue1 font-family-designosaur">
              Évaluer
            </span>
          </button>
        </div>

        <div className="suppliers-listing-button-container">
          <button
            className="suppliers-listing-button"
            onClick={() =>
              setDisplaySupplierAssesmentsListing(
                !displaySupplierAssesmentsListing
              )
            }
          >
            <GoEye />{" "}
            <span className="margin-left-10px font-dark-blue1 font-family-designosaur">
              Évaluations
            </span>
          </button>
        </div>
      </div>

      {displaySupplierDetails && (
        <SupplierDetails
          supplier={supplier}
          setDisplaySupplierDetails={setDisplaySupplierDetails}
        />
      )}

      {displaySupplierAssesmentsListing && (
        <SupplierAssesmentListing supplier={supplier} />
      )}
    </>
  );
};

export default SupplierSummaryTemplate;
