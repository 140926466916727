import React, { useEffect, useState } from "react";
import logoACT from "../../../images/logo2023Dark.png";
import manWithShield from "../../../images/character-shield.png";
import manAtDesktop from "../../../images/character-desktop.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAtom, useSetAtom } from "jotai";
import {
  isAuditRGPDSelectedAtom,
  isPreauditSelectedAtom,
  isSupplierAssesmentSelectedAtom,
  selectedToolAtom,
} from "../../../atoms/MenusSelectionAtom";
import Cookies from "js-cookie";
import { toolTitleAtom } from "../../../atoms/HeaderAtoms";
// import PreauditAdvantages from "./components/PreauditAdvantages";
// import SupplierAssesmentAdvantages from "./components/SupplierAssesmentAdvantages";
// import AuditRGPDAdvantages from "./components/AuditRGPDAdvantages";
import SignInFormV3 from "./components/SignInFormV3";
import SignUpFormV3 from "./components/SignUpFormV3";
import ToolDescription from "./components/ToolDescription";

const SignInUpV3 = () => {
  const [FormToDisplay, whichFormToDisplay] = useState("signin");
  const currentLocation = useLocation();
  const setIsPreauditSelected = useSetAtom(isPreauditSelectedAtom);
  const setIsSupplierAssesmentSelected = useSetAtom(
    isSupplierAssesmentSelectedAtom
  );
  const setIsAuditRGPDSelected = useSetAtom(isAuditRGPDSelectedAtom);
  const [selectedTool, setSelectedTool] = useAtom(selectedToolAtom);
  // const [welcomeText, setWelcomeText] = useState();
  const [toolTitle, setToolTitle] = useAtom(toolTitleAtom);
  // const [toolNameToDisplay, setToolNameToDisplay] = useState();
  const [showToolDescription, setShowToolDescription] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const definetToolTitle = () => {
      if (currentLocation.pathname === "/preaudit-welcome") {
        setToolTitle("AUDIT PRÉLIMINAIRE ISO 27001");
        // setToolNameToDisplay("preaudit");
        setSelectedTool("preaudit");
      }

      if (currentLocation.pathname === "/supplier-assesment-welcome") {
        setToolTitle("ÉVALUATION FOURNISSEUR");
        // setToolNameToDisplay("supplier-assesment");
        setSelectedTool("supplier-assesment");
      }

      if (currentLocation.pathname === "/audit-rgpd-welcome") {
        setToolTitle("AUDIT RGPD");
        // setToolNameToDisplay("audit-rgpd");
        setSelectedTool("audit-rgpd");
      }

      if (currentLocation.pathname === "/act-access") {
        setToolTitle("ACCÈS RÉSERVÉ");
        // setToolNameToDisplay("backstage");
      }
    };

    const displaySignInUp = () => {
      if (
        Cookies.get(process.env.REACT_APP_COOKIES_TOKEN_SESSION) &&
        selectedTool !== "no-selection"
      ) {
        navigate("/user-informations");
        return false;
      } else {
        setSelectedTool("no-selection");
        return true;
      }
    };

    if (displaySignInUp()) {
      definetToolTitle();
    }

    window.scrollTo({
      top: 0,
      // left: 0,
      behavior: "smooth",
    });
  }, [currentLocation, setToolTitle, selectedTool, setSelectedTool, navigate]);

  return (
    <>
      <div id="signinup-container">
        <div id="title-container">
          {" "}
          <Link
            to="https://www.alliancecybertech.com/tools"
            target="_blank"
            className="text-decoration-none"
          >
            <div id="logo-container">
              <div id="act-logo">
                <img
                  src={logoACT}
                  alt="logo de la société Alliance Cyber Technologies"
                />
              </div>
              <div id="act-name">
                <span className="font-family-designosaur font-size-2rem ">
                  Alliance Cyber
                  <br />
                  Technologies
                </span>
              </div>
            </div>
          </Link>
          <div id="image3-container">
            <img
              src={manAtDesktop}
              alt="outils de cybersécurité"
              width="100%"
            />
          </div>
          <div id="tool-title-container">
            <span className="font-family-designosaur">{toolTitle}</span>
          </div>
        </div>

        {showToolDescription ? (
          <ToolDescription setShowToolDescription={setShowToolDescription} />
        ) : (
          <div id="connnexion-container">
            <div id="image4-container">
              <img
                src={manWithShield}
                alt="logo de la société Alliance Cyber Technologies"
              />
            </div>
            {FormToDisplay === "signin" && (
              <SignInFormV3
                whichFormToDisplay={whichFormToDisplay}
                currentURL={currentLocation.pathname}
                setIsPreauditSelected={setIsPreauditSelected}
                setIsSupplierAssesmentSelected={setIsSupplierAssesmentSelected}
                setIsAuditRGPDSelected={setIsAuditRGPDSelected}
                setSelectedTool={setSelectedTool}
              />
            )}

            {FormToDisplay === "signup" && (
              <SignUpFormV3
                whichFormToDisplay={whichFormToDisplay}
                currentURL={currentLocation.pathname}
                setIsPreauditSelected={setIsPreauditSelected}
                setIsSupplierAssesmentSelected={setIsSupplierAssesmentSelected}
                setIsAuditRGPDSelected={setIsAuditRGPDSelected}
                setSelectedTool={setSelectedTool}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SignInUpV3;
