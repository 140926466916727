import { useAtom } from "jotai";
import React from "react";
import { Q4NoneFromOtherSectorsSubSectorsAtom } from "../../../../../atoms/NIS2Atom";
import { answersManagementForQ3OtherSectorsOfActivity } from "../../../../../utils/functions/Version3/functionsForNIS2Management";

const NoneFromOtherSectorsSubSectors = () => {
  const [Q4NoneFromOtherSectors, setQ4NoneFromOtherSectors] = useAtom(
    Q4NoneFromOtherSectorsSubSectorsAtom
  );

  return (
    <>
      <div className="nis2-sub-sectors-container">
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="identifiée comme un Opérateur de Services Essentiels"
              checked={Q4NoneFromOtherSectors.includes(
                "identifiée comme un Opérateur de Services Essentiels"
              )}
              onChange={(e) =>
                answersManagementForQ3OtherSectorsOfActivity(
                  e,
                  Q4NoneFromOtherSectors,
                  setQ4NoneFromOtherSectors
                )
              }
            />
            Votre organisation a été identifiée avant le 16 janvier 2023 comme
            un Opérateur de Services Essentiels conformément à la directive (UE)
            2016/1148 ou au droit national (NIS1).
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="recensée en tant qu’entité critique"
              checked={Q4NoneFromOtherSectors.includes(
                "recensée en tant qu’entité critique"
              )}
              onChange={(e) =>
                answersManagementForQ3OtherSectorsOfActivity(
                  e,
                  Q4NoneFromOtherSectors,
                  setQ4NoneFromOtherSectors
                )
              }
            />
            Votre organisation a été recensée en tant qu’entité critique en
            vertu de la directive (UE) 2022/ 2557.{" "}
          </label>
        </div>

        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="aucun de la liste"
              checked={Q4NoneFromOtherSectors.includes("aucun de la liste")}
              onChange={(e) =>
                answersManagementForQ3OtherSectorsOfActivity(
                  e,
                  Q4NoneFromOtherSectors,
                  setQ4NoneFromOtherSectors
                )
              }
            />
            Aucun de la liste
          </label>
        </div>
      </div>
    </>
  );
};

export default NoneFromOtherSectorsSubSectors;
