import { BrowserRouter, Routes, Route } from "react-router-dom";

/////////// Design V1 //////////////
// import "../src/styles/Version1/stylesV1.scss";
// import SharedLayoutV1 from "./layouts/SharedLayoutV1/SharedLayoutV1";
// import ProtectedRoutesV1 from "./pages/Version1/ProtectedRoutesV1/ProtectedRoutesV1";
// import Homepage from "./pages/HomeV1/Homepage";
// import ProspectPage from "./pages/ProspectV1/ProspectPage";
// import SupplierAssesmentHomepage from "./pages/SupplierAssesmentV1/SupplierAssesmentHomepage";
// import SupplierAssesmentStartPage from "./pages/SupplierAssesmentV1/SupplierAssesmentStartPage";
// import ChartPageV1 from "./pages/ChartV1/ChartPageV1";
// import PreauditPageV1 from "./pages/PreauditV1/PreauditPageV1";
// import BackstagePageV1 from "./pages/Version1/BackstageV1/BackstagePageV1";
// import ProspectsManagement from "./pages/Version1/BackstageV1/Prospects/ProspectsManagement";
// import ShowProspect from "./pages/Version1/BackstageV1/Prospects/ShowProspect";
// import Page404V1 from "./pages/Version1/Page404V1/Page404V1";

/////////// Design V2 //////////////
// import "../src/styles/Version2/stylesV2.scss";
// import HomepageV2 from "./pages/Version2/HomepageV2/HomepageV2";
// import SharedLayoutV2 from "./pages/Version2/SharedLayoutV2/SharedLayoutV2";
// import ProtectedRoutesV2 from "./pages/Version2/ProtectedRoutesV2/ProtectedRoutesV2";
// import ProtectionForPreaudit from "./pages/Version2/ProtectedRoutesV2/components/ProtectionForPreaudit";
// import ProtectionForSupplierAssesment from "./pages/Version2/ProtectedRoutesV2/components/ProtectionForSupplierAssesment";
// import ProtectionForAuditRGPD from "./pages/Version2/ProtectedRoutesV2/components/ProtectionForAuditRGPD";
// import ProtectionForBackstage from "./pages/Version2/ProtectedRoutesV2/components/ProtectionForBackstage";

// import PreauditWelcomePage from "./pages/Version2/PreauditV2/PreauditWelcomePage";
// import PreauditDashboard from "./pages/Version2/PreauditV2/PreauditDashboard";
// import PreauditQuestions from "./pages/Version2/PreauditV2/PreauditQuestions";

// import SupplierAssesmentWelcomePage from "./pages/Version2/SupplierAssesmentV2/SupplierAssesmentWelcomePage";
// import SupplierAssesmentDashboard from "./pages/Version2/SupplierAssesmentV2/SupplierAssesmentDashboard";
// import SupplierAssesmentQuestions from "./pages/Version2/SupplierAssesmentV2/SupplierAssesmentQuestions";
// import SupplierAssesmentTokenLandingPage from "./pages/Version2/SupplierAssesmentV2/SupplierAssesmentTokenLandingPage";
// import SuppliersListing from "./pages/Version2/SupplierAssesmentV2/components/SuppliersListing";
// import SupplierInformationsEdition from "./pages/Version2/SupplierAssesmentV2/components/SupplierInformationsEdition";

// import AuditRGPDWelcomePage from "./pages/Version2/AuditRGPDV2/AuditRGPDWelcomePage";
// import AuditRGPDDashboard from "./pages/Version2/AuditRGPDV2/AuditRGPDDashboard";
// import AuditRGPDQuestions from "./pages/Version2/AuditRGPDV2/AuditRGPDQuestions";

// import BackstageWelcomePage from "./pages/Version2/BackstageV2/BackstageWelcomePage";
// import UsersListing from "./pages/Version2/BackstageV2/components/UsersListing";

// import UserInformationsV2 from "./pages/Version2/UserInformationsV2/UserInformationsV2";
// import UserInformationsEditionV2 from "./pages/Version2/UserInformationsV2/UserInformationsEditionV2";

// import Page404V2 from "./pages/Version2/Page404V2/Page404V2";
// import ResetPasswordFormV2 from "./pages/Version2/ResetPasswordV2/ResetPasswordFormV2";
// import Tests from "./pages/Tests";

/////////// Design V3 //////////////
import "../src/styles/Version3/stylesV3.scss";
import SharedLayoutV3 from "./pages/Version3/SharedLayoutV3/SharedLayoutV3";
import HomepageV3 from "./pages/Version3/HomepageV3/HomepageV3";
import SignInUpV3 from "./pages/Version3/SignInUpV3/SignInUpV3";
import ResetPasswordFormV3 from "./pages/Version3/ResetPasswordV3/ResetPasswordFormV3";
import ProtectedRoutesV3 from "./pages/Version3/ProtectedRoutesV3/ProtectedRoutesV3";
import UserInformationsV3 from "./pages/Version3/UserInformationsV3/UserInformationsV3";
import UserInformationsEditionV3 from "./pages/Version3/UserInformationsV3/UserInformationsEditionV3";
import DashboardV3 from "./pages/Version3/DashboardV3/DashboardV3";
import PreauditQuestions from "./pages/Version3/DashboardV3/PreauditDashboard/components/PreauditQuestions";
import SupplierAssesmentTokenLandingPage from "./pages/Version3/SupplierAssesmentAnswering/SupplierAssesmentTokenLandingPage";
import SupplierInformationsEdition from "./pages/Version3/SupplierAssesmentAnswering/components/SupplierInformationsEdition";
import SupplierAssesmentQuestions from "./pages/Version3/SupplierAssesmentAnswering/components/SupplierAssesmentQuestions";
import AuditRGPDQuestions from "./pages/Version3/DashboardV3/AuditRGPDDashboard/components/AuditRGPDQuestions";
import Tests from "./pages/Tests";
import NIS2FormWelcome from "./pages/Version3/NIS2/NIS2FormWelcome";
import SubmitAnswersForm from "./pages/Version3/NIS2/components/SubmitAnswersForm";

const App = () => {
  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          {/******************************************************************************************/}
          {/* Route for design V1 */}

          {/* <Route path="/" element={<SharedLayoutV1 />}> */}
          {/* <Route index element={<Homepage />} /> */}
          {/* <Route path="/preaudit-page" element={<PreauditPageV1 />} />
            <Route path="/prospect-page" element={<ProspectPage />} />
            <Route path="/chart-page" element={<ChartPageV1 />} /> */}
          {/* <Route path="/supplier-assesment-homepage" element={<SupplierAssesmentHomepage/>}/>
            <Route path="/supplier-assesment-startpage" element={<SupplierAssesmentStartPage/>}/> */}
          {/******************************************************************************************/}

          {/******************************************************************************************/}
          {/* Route for design V2 */}

          {/* <Route path="/" element={<SharedLayoutV2 />}>
            <Route path="/" element={<HomepageV2 />} />

            <Route path="/preaudit-welcome" element={<PreauditWelcomePage />} />

            <Route
              path="/supplier-assesment-welcome"
              element={<SupplierAssesmentWelcomePage />}
            />
            <Route
              path="/supplier-assesment/:supplierAssesmentId/:supplierAssesmentToken"
              element={<SupplierAssesmentTokenLandingPage />}
            />
            <Route
              path="/supplier-assesment-questions/:theme"
              element={<SupplierAssesmentQuestions />}
            />

            <Route
              path="/supplier-informations-edition"
              element={<SupplierInformationsEdition />}
            />

            <Route
              path="/audit-rgpd-welcome"
              element={<AuditRGPDWelcomePage />}
            />

            <Route path="/act-access" element={<BackstageWelcomePage />} />

            <Route
              path="/new_password/:token"
              element={<ResetPasswordFormV2 />}
            />

            <Route path="/*" element={<Page404V2 />} /> */}

          {/* PROTECTED ROUTES BY AUTENTICATION */}

          {/* <Route element={<ProtectedRoutesV2 />}>
              <Route path="/tests" element={<Tests />} />

              <Route
                path="/user-informations"
                element={<UserInformationsV2 />}
              />
              <Route
                path="/user-informations-edition"
                element={<UserInformationsEditionV2 />}
              />

              <Route element={<ProtectionForPreaudit />}>
                <Route
                  path="/preaudit-dashboard"
                  element={<PreauditDashboard />}
                />
                <Route
                  path="/preaudit-questions"
                  element={<PreauditQuestions />}
                />
              </Route>

              <Route element={<ProtectionForSupplierAssesment />}>
                <Route
                  path="/supplier-assesment-dashboard"
                  element={<SupplierAssesmentDashboard />}
                />

                <Route
                  path="/supplier-assesment/suppliers-listing"
                  element={<SuppliersListing />}
                />
              </Route>

              <Route element={<ProtectionForAuditRGPD />}>
                <Route
                  path="/audit-rgpd-dashboard"
                  element={<AuditRGPDDashboard />}
                />
                <Route
                  path="/audit-rgpd-questions"
                  element={<AuditRGPDQuestions />}
                />
              </Route>

              <Route element={<ProtectionForBackstage />}>
                <Route
                  path="/act-access/users-listing"
                  element={<UsersListing />}
                />
              </Route>
            </Route>
          </Route> */}
          {/******************************************************************************************/}

          {/******************************************************************************************/}
          {/* Route for design V3 */}

          <Route path="/" element={<SharedLayoutV3 />}>
            <Route index path="/" element={<HomepageV3 />} />

            <Route path="/preaudit-welcome" element={<SignInUpV3 />} />

            <Route
              path="/supplier-assesment-welcome"
              element={<SignInUpV3 />}
            />
            <Route path="/audit-rgpd-welcome" element={<SignInUpV3 />} />

            <Route
              path="/new_password/:token"
              element={<ResetPasswordFormV3 />}
            />

            <Route
              path="/supplier-assesment/:supplierAssesmentId/:supplierAssesmentToken"
              element={<SupplierAssesmentTokenLandingPage />}
            />

            <Route
              path="/supplier-informations-edition"
              element={<SupplierInformationsEdition />}
            />

            <Route
              path="/supplier-assesment-questions/:theme"
              element={<SupplierAssesmentQuestions />}
            />

            <Route path="/nis2-form" element={<NIS2FormWelcome />} />

            <Route
              path="/nis2-organisation-informations-form"
              element={<SubmitAnswersForm />}
            />

            {/* Protection by authentication */}
            <Route element={<ProtectedRoutesV3 />}>
              <Route path="/tests" element={<Tests />} />

              <Route
                path="/user-informations"
                element={<UserInformationsV3 />}
              />

              <Route
                path="/user-informations-edition"
                element={<UserInformationsEditionV3 />}
              />

              <Route path="/dashboard" element={<DashboardV3 />} />

              <Route
                path="/preaudit-questions"
                element={<PreauditQuestions />}
              />

              <Route
                path="/audit-rgpd-questions"
                element={<AuditRGPDQuestions />}
              />
            </Route>
          </Route>

          {/******************************************************************************************/}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
