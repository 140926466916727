import { useAtom, useSetAtom } from "jotai";
import React from "react";
import {
  Q3WhatNumericInfrastructureSubSectorsAtom,
  Q4WhatSizeAndRevenueAtom,
  Q5WhichOnesAreCorrectAtom,
  Q6WhatActivitiesAtom,
  displayQ5WhichOnesAreCorrectAtom,
  displayQ6WhatActivitiesAtom,
} from "../../../../../atoms/NIS2Atom";
import {
  answersManagementForQ2SectorsOfActivity,
  resetQ5WhichOnesAreCorrect,
  resetQ6WhatActivties,
  resetSizeAndRevenue,
} from "../../../../../utils/functions/Version3/functionsForNIS2Management";

const NumericInfrastructureSubSectors = ({
  setDisplayQ4WhatSizeAndRevenue,
}) => {
  const [
    Q3WhatNumericInfrastructureSubSectors,
    setQ3WhatNumericInfrastructureSubSectors,
  ] = useAtom(Q3WhatNumericInfrastructureSubSectorsAtom);

  const setQ4WhatSizeAndRevenue = useSetAtom(Q4WhatSizeAndRevenueAtom);

  const setQ5WhichOnesAreCorrect = useSetAtom(Q5WhichOnesAreCorrectAtom);
  const setDisplayQ5WhichOnesAreCorrect = useSetAtom(
    displayQ5WhichOnesAreCorrectAtom
  );

  const setDisplayQ6WhatActivities = useSetAtom(displayQ6WhatActivitiesAtom);

  const setQ6WhatActivities = useSetAtom(Q6WhatActivitiesAtom);

  return (
    <>
      <div className="nis2-sub-sectors-container">
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="fournisseurs de points d'échange Internet"
              checked={Q3WhatNumericInfrastructureSubSectors.includes(
                "fournisseurs de points d'échange Internet"
              )}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatNumericInfrastructureSubSectors,
                  setQ3WhatNumericInfrastructureSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Fournisseurs de points d'échange Internet
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="fournisseurs de service DNS"
              checked={Q3WhatNumericInfrastructureSubSectors.includes(
                "fournisseurs de service DNS"
              )}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatNumericInfrastructureSubSectors,
                  setQ3WhatNumericInfrastructureSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Fournisseurs de service DNS
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="registre de noms de domaines de premier niveau"
              checked={Q3WhatNumericInfrastructureSubSectors.includes(
                "registre de noms de domaines de premier niveau"
              )}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatNumericInfrastructureSubSectors,
                  setQ3WhatNumericInfrastructureSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Registre de noms de domaines de premier niveau
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="fournisseurs de services informatiques en nuage"
              checked={Q3WhatNumericInfrastructureSubSectors.includes(
                "fournisseurs de services informatiques en nuage"
              )}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatNumericInfrastructureSubSectors,
                  setQ3WhatNumericInfrastructureSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Fournisseurs de services informatiques en nuage
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="fournisseurs de services de centres de données"
              checked={Q3WhatNumericInfrastructureSubSectors.includes(
                "fournisseurs de services de centres de données"
              )}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatNumericInfrastructureSubSectors,
                  setQ3WhatNumericInfrastructureSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Fournisseurs de services de centres de données
          </label>
        </div>

        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="fournisseurs de réseaux de diffusion de contenu"
              checked={Q3WhatNumericInfrastructureSubSectors.includes(
                "fournisseurs de réseaux de diffusion de contenu"
              )}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatNumericInfrastructureSubSectors,
                  setQ3WhatNumericInfrastructureSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Fournisseurs de réseaux de diffusion de contenu
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="prestataire de services de confiance"
              checked={Q3WhatNumericInfrastructureSubSectors.includes(
                "prestataire de services de confiance"
              )}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatNumericInfrastructureSubSectors,
                  setQ3WhatNumericInfrastructureSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Prestataire de services de confiance
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="fournisseurs de réseaux de communications électroniques publiques"
              checked={Q3WhatNumericInfrastructureSubSectors.includes(
                "fournisseurs de réseaux de communications électroniques publiques"
              )}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatNumericInfrastructureSubSectors,
                  setQ3WhatNumericInfrastructureSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Fournisseurs de réseaux de communications électroniques publiques
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="fournisseurs de services de communications électroniques accessibles au publique"
              checked={Q3WhatNumericInfrastructureSubSectors.includes(
                "fournisseurs de services de communications électroniques accessibles au publique"
              )}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatNumericInfrastructureSubSectors,
                  setQ3WhatNumericInfrastructureSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Fournisseurs de services de communications électroniques accessibles
            au publique
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="aucun de la liste"
              checked={Q3WhatNumericInfrastructureSubSectors.includes(
                "aucun de la liste"
              )}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatNumericInfrastructureSubSectors,
                  setQ3WhatNumericInfrastructureSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Aucun de la liste
          </label>
        </div>
      </div>
    </>
  );
};

export default NumericInfrastructureSubSectors;
