import React, { useState } from "react";
import { JSONNIS2Questions } from "../../../../utils/JSON/NIS2FormQuestions";
import {
  displayQ6WhatActivitiesAtom,
  NIS2StatusAtom,
  organisationNIS2StatusAtom,
  Q5WhichOnesAreCorrectAtom,
  Q6WhatActivitiesAtom,
} from "../../../../atoms/NIS2Atom";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  answersManagementForQ5WhichOnesAreCorrect,
  resetQ6WhatActivties,
} from "../../../../utils/functions/Version3/functionsForNIS2Management";
import Q6WhatActivities from "./Q6WhatActivities";
import ThanksForUsingOurFormAndSubmit from "./PopUps/ThanksForUsingOurFormAndSubmit";
import GiveAtLeastOneAnswer from "./PopUps/GiveAtLeastOneAnswer";
import { FaLongArrowAltRight } from "react-icons/fa";

const Q5WhichOnesAreCorrect = () => {
  const NIS2Status = useAtomValue(NIS2StatusAtom);
  const setOrganisationNIS2Status = useSetAtom(organisationNIS2StatusAtom);

  const [Q5WhichOnesAreCorrect, setQ5WhichOnesAreCorrect] = useAtom(
    Q5WhichOnesAreCorrectAtom
  );

  const setQ6WhatActivities = useSetAtom(Q6WhatActivitiesAtom);

  const [displayQ6WhatActivities, setDisplayQ6WhatActivities] = useAtom(
    displayQ6WhatActivitiesAtom
  );

  const [displayThanksPopUp, setDisplayThanksPopUp] = useState(false);
  const [displayGiveAtLeastOneAnswer, setDisplayGiveAtLeastOneAnswer] =
    useState(false);
  const [answerType, setAnswerType] = useState(null);

  const answersAnlaysis = () => {
    // Analyse user's answers before running next step :
    if (isFormValid()) {
      // 1 - Case : the organisation is an "entité gouvernementale"
      if (Q5WhichOnesAreCorrect.includes("entité gouvernementale centrale")) {
        setDisplayQ6WhatActivities(true);
        window.scrollBy({
          top: 200,
          left: 0,
          behavior: "smooth",
        });
      }

      // 2 - Case : the organisation is an "Entité exclue"
      else if (
        Q5WhichOnesAreCorrect.length === 1 &&
        Q5WhichOnesAreCorrect[0] === "aucun de la liste"
      ) {
        setOrganisationNIS2Status(NIS2Status[0]);
        setDisplayThanksPopUp(true);
      }

      // 3 - Case : Case : the organisation is an "Entité critique"
      // This case did previously end by a simple "thanks for using our form" and nothing more. So, we replace it by our usual form
      // in order to collect the user data
      else {
        // setDisplayContactUs(true);
        setOrganisationNIS2Status(NIS2Status[3]);
        setDisplayThanksPopUp(true);
      }
    }
  };

  const isFormValid = () => {
    if (Q5WhichOnesAreCorrect.length === 0) {
      setDisplayGiveAtLeastOneAnswer(true);
      setAnswerType("poursuivre");
      return false;
    } else return true;
  };

  return (
    <>
      <div id="Q5WhichOnesAreCorrect" className="nis2form-question-container">
        <div className="nis2form-question-content">
          {JSONNIS2Questions[4].content}
        </div>
        <div id="q2-sectors-of-activity-container">
          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="entité gouvernementale centrale"
                checked={Q5WhichOnesAreCorrect.includes(
                  "entité gouvernementale centrale"
                )}
                onChange={(e) =>
                  answersManagementForQ5WhichOnesAreCorrect(
                    e,
                    Q5WhichOnesAreCorrect,
                    setQ5WhichOnesAreCorrect
                  )
                }
                onClick={() => {
                  setDisplayQ6WhatActivities(false);
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Votre organisation est une entité gouvernementale centrale ou une
              entité régionale qui fournit des services dont l'interruption
              pourrait avoir un impact significatif sur des activités sociétales
              ou économiques critiques.
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="seul fournisseur d'un service"
                checked={Q5WhichOnesAreCorrect.includes(
                  "seul fournisseur d'un service"
                )}
                onChange={(e) =>
                  answersManagementForQ5WhichOnesAreCorrect(
                    e,
                    Q5WhichOnesAreCorrect,
                    setQ5WhichOnesAreCorrect
                  )
                }
                onClick={() => {
                  setDisplayQ6WhatActivities(false);
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Votre organisation est le seul fournisseur d'un service à l'État,
              qui est essentiel à des activités sociétales ou économiques
              critiques.
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="fournit des services en utilisant des réseaux"
                checked={Q5WhichOnesAreCorrect.includes(
                  "fournit des services en utilisant des réseaux"
                )}
                onChange={(e) =>
                  answersManagementForQ5WhichOnesAreCorrect(
                    e,
                    Q5WhichOnesAreCorrect,
                    setQ5WhichOnesAreCorrect
                  )
                }
                onClick={() => {
                  setDisplayQ6WhatActivities(false);
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Votre organisation fournit des services en utilisant des réseaux
              publics de communication électronique ou des services de
              communication électronique accessibles au public, des fournisseurs
              de services de confiance, des registres de noms de domaines de
              premier niveau et des fournisseurs de services DNS.
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="fournit un service pour la santé publique"
                checked={Q5WhichOnesAreCorrect.includes(
                  "fournit un service pour la santé publique"
                )}
                onChange={(e) =>
                  answersManagementForQ5WhichOnesAreCorrect(
                    e,
                    Q5WhichOnesAreCorrect,
                    setQ5WhichOnesAreCorrect
                  )
                }
                onClick={() => {
                  setDisplayQ6WhatActivities(false);
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Votre organisation fournit un service dont l'interruption pourrait
              avoir un impact significatif sur la santé publique, la sécurité
              publique ou la sûreté publique.
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="identifiée comme un Opérateur de Services Essentiels"
                checked={Q5WhichOnesAreCorrect.includes(
                  "identifiée comme un Opérateur de Services Essentiels"
                )}
                onChange={(e) =>
                  answersManagementForQ5WhichOnesAreCorrect(
                    e,
                    Q5WhichOnesAreCorrect,
                    setQ5WhichOnesAreCorrect
                  )
                }
                onClick={() => {
                  setDisplayQ6WhatActivities(false);
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Votre organisation a été identifiée avant le 16 janvier 2023 comme
              un Opérateur de Services Essentiels conformément à la directive
              (UE) 2016/1148 ou au droit national (NIS1)
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="risque systémique"
                checked={Q5WhichOnesAreCorrect.includes("risque systémique")}
                onChange={(e) =>
                  answersManagementForQ5WhichOnesAreCorrect(
                    e,
                    Q5WhichOnesAreCorrect,
                    setQ5WhichOnesAreCorrect
                  )
                }
                onClick={() => {
                  setDisplayQ6WhatActivities(false);
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Votre organisation fournit un service dont l'interruption pourrait
              entraîner un risque systémique (transfrontalier).
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="importance spécifique au niveau national ou régional"
                checked={Q5WhichOnesAreCorrect.includes(
                  "importance spécifique au niveau national ou régional"
                )}
                onChange={(e) =>
                  answersManagementForQ5WhichOnesAreCorrect(
                    e,
                    Q5WhichOnesAreCorrect,
                    setQ5WhichOnesAreCorrect
                  )
                }
                onClick={() => {
                  setDisplayQ6WhatActivities(false);
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Votre organisation est critique en raison de son importance
              spécifique au niveau national ou régional pour le secteur ou le
              type de service en question.
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="aucun de la liste"
                checked={Q5WhichOnesAreCorrect.includes("aucun de la liste")}
                onChange={(e) =>
                  answersManagementForQ5WhichOnesAreCorrect(
                    e,
                    Q5WhichOnesAreCorrect,
                    setQ5WhichOnesAreCorrect
                  )
                }
                onClick={() => {
                  setDisplayQ6WhatActivities(false);
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Aucun de la liste
            </label>
          </div>
        </div>
      </div>

      {displayQ6WhatActivities ? (
        <Q6WhatActivities />
      ) : (
        <div
          className="nis2-validation-button-container"
          onClick={() => answersAnlaysis()}
        >
          <div className="nis2-validation-button-text">SUIVANT</div>
          <FaLongArrowAltRight className="nis2-validation-button-arrow" />
        </div>
      )}

      {displayThanksPopUp && <ThanksForUsingOurFormAndSubmit />}
      {displayGiveAtLeastOneAnswer && (
        <GiveAtLeastOneAnswer
          setDisplayGiveAtLeastOneAnswer={setDisplayGiveAtLeastOneAnswer}
          answerType={answerType}
        />
      )}
    </>
  );
};

export default Q5WhichOnesAreCorrect;
