import React, { useState } from "react";
import ResetInstructionsFormV3 from "../../../ResetPasswordV3/ResetInstructionsFormV3";

const AuthenticationFailed = ({ setAuthenticationStatus }) => {
  const [displayResetInstructionsForm, setDisplayResetInstructionsForm] =
    useState(false);

  return (
    <>
      <div className="signinup-error-message-modal">
        <div className="signinup-error-message-container">
          <div>
            <span className="font-dark-blue1">
              Identifiant ou mot de passe incorrect
            </span>
          </div>

          <div
            className="signinup-error-message-close-button-container"
            onClick={() => setAuthenticationStatus(false)}
          >
            <button className="signinup-error-message-close-button">
              Fermer
            </button>
          </div>
        </div>
      </div>

      {displayResetInstructionsForm && (
        <>
          <ResetInstructionsFormV3
            setDisplayResetInstructionsForm={setDisplayResetInstructionsForm}
          />
        </>
      )}
    </>
  );
};

export default AuthenticationFailed;
