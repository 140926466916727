import React from "react";

const PrivacyPolicy = ({ setDisplayPrivacyPolicy }) => {
  return (
    <>
      <div id="privacy-policy-modal">
        <div id="privacy-policy-container">
          <h2 className="font-dark-blue1">Politique de confidentialité</h2>

          <h5 className="font-dark-blue1 margin-bottom-30px">
            Dernière mise à jour : 16/10/2023
          </h5>

          <div id="privacy-policy-full-text">
            <p className="font-dark-blue1 margin-bottom-30px">
              <h1>
                1. Protection des données personnelles – identité du responsable
                de traitement :
              </h1>
              <div className="privacy-policy-paragraph">
                La présente politique de confidentialité est réalisée par et
                concerne exclusivement le site https://forms.alliancecybertech.com
                de la société Alliance Cyber Technologies - capital social de 20
                000 €, immatriculé au RCS de Lille sous le numéro 844 104 950 –
                229 rue de Solferino à Lille. Cette dernière s’engage à
                respecter les obligations légales relatives à la protection des
                données personnelles, notamment :
                <ul>
                  <li>
                    - la loi relative à l’informatique, aux fichiers et aux
                    libertés du 6 janvier 1978 modifiée ;
                  </li>
                  <li>
                    - la loi relative à la confiance en l’économie numérique du
                    10 juin 2004.
                  </li>
                </ul>
              </div>
              <div className="privacy-policy-paragraph">
                La présente politique de confidentialité a pour principal
                objectif d’informer les personnes dont les données sont
                susceptibles d’être collectées, de la manière dont leurs données
                sont traitées par le responsable de traitement, d’Alliance Cyber
                Technologies, et de leurs droits.
              </div>
              <h1>2. Utilisation de vos données personnelles : </h1>
              <div className="privacy-policy-paragraph">
                Vous ne serez amenés à nous fournir des données personnelles
                vous concernant que lors de l’utilisation des services mis
                grâcieusement à disposition par Alliance Cyber Technologies,
                dans la sections "Vos informations" et lors de la réalisation
                d'une évaluation de fournisseur. A cette occasion, les données
                suivantes doivent obligatoirement être fournies : nom de la
                société, nom de l'utilisateur du service, adresse de la société,
                un email de contact, numéro de téléphone et SIRET de la société.
                Lorsque vous acceptez de nous transmettre vos données, celles-ci
                ne sont traitées que dans le seul but de permettre aux experts
                d’Alliance Cyber Technologies de répondre à vos demandes. Vous
                pourrez également choisir de recevoir les newsletters d’Alliance
                Cyber Technologies. Dans ce cas les données renseignées seront
                traitées à cette fin. Elles ne seront conservées que le temps
                nécessaire à gestion de votre demande et, le cas échéant, le
                temps de votre inscription à la newsletter.
              </div>
              <h1>3. Destinataires et transferts de données :</h1>
              <div className="privacy-policy-paragraph">
                Les données collectées sur le site ne seront transmises à aucun
                partenaire externes et restent exclusivement réservées à
                Alliance Cyber Technologies.
              </div>
              <h1>4. Gestion de la violation de données :</h1>{" "}
              <div className="privacy-policy-paragraph">
                {" "}
                Dans le cas d’une violation des données collectées, Alliance
                Cyber Technologies s’engage à respecter le délai de 72 heures au
                plus tard pour notifier la violation à l’autorité de contrôle
                compétente (la CNIL). Lorsque la violation est susceptible
                d’engendrer des risques élevés pour les personnes, Alliance
                Cyber Technologies s’engage à les notifier dans les meilleurs
                délais.{" "}
              </div>
              <h1>5. Vos droits :</h1>{" "}
              <div className="privacy-policy-paragraph">
                {" "}
                En votre qualité de personnes dont les données ont été
                collectées, vous disposez de différents droits, à savoir :
                <ul>
                  <li>
                    - droit d’accès : il vous permet de demander à Alliance
                    Cyber Technologies de vous confirmer qu’elle détient des
                    informations vous concernant, des conditions de leur
                    traitement ;
                  </li>
                  <li>
                    - droit de rectification : vous pouvez demander à Alliance
                    Cyber Technologies la modification des données personnelles
                    qui vous aviez fournies lorsqu’elles sont inexactes ;
                  </li>
                  <li>
                    - droit à l’effacement : il vous permet de réclamer à
                    Alliance Cyber Technologies l’effacement, en tout ou partie,
                    des données collectées ;
                  </li>
                  <li>
                    - droit d’opposition : vous permet de vous opposer, à tout
                    moment, pour des raisons particulières à ce que Alliance
                    Cyber Technologies traite vos données.
                  </li>
                </ul>{" "}
              </div>
              <div className="privacy-policy-paragraph">
                Si vous souhaitez exercer l’un de ces droits, merci de contacter
                Alliance Cyber Technologies à l’adresse suivante :
                contact[@]alliancecybertech.com. Votre demande doit être
                accompagnée des éléments suivants : d’une copie d’un justificatif
                d’identité, droit que vous souhaitez exercer, motifs.
              </div>
            </p>

            <div id="privacy-policy-close-button-container">
              <button
                onClick={() => setDisplayPrivacyPolicy(false)}
                id="privacy-policy-close-button"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
