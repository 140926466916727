import React from "react";
import cyberMalveillanceLogo from "../../../images/cyber-malveillance.png";

const LegalMentions = ({ setDisplayLegalMentions }) => {
  return (
    <>
      <div id="legal-mentions-modal">
        <div id="legal-mentions-container">
          <h2 className="font-dark-blue1">Mentions légales</h2>

          <h5 className="font-dark-blue1 margin-bottom-30px">
            Dernière mise à jour : 16/10/2023
          </h5>

          <div id="legal-mentions-full-text">
            <p className="font-dark-blue1 margin-bottom-30px">
              <h1>1. Identification des parties prenantes :</h1>
              <div className="legal-mentions-paragraph">
                En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004
                pour la confiance dans l'économie numérique, il est précisé aux
                utilisateurs du site https://forms.alliancecybertech.com
                l'identité des différents intervenants dans le cadre de sa
                réalisation et de son suivi :
              </div>
              <div className="legal-mentions-paragraph">
                <ul>
                  <li>
                    - Propriétaire : Alliance Cyber Technologies - capital
                    social de 20 000 €, immatriculé au RCS de Lille sous le
                    numéro 844 104 950 – 229 rue de Solferino à Lille
                  </li>
                  <li>
                    - Créateur : Dany Corgiat Numéro de téléphone : 06 68 30 11
                    37
                  </li>
                  <li>- Webmaster : contact@alliancecybertech.com</li>
                  <li> - Hébergeur du backend : Heroku</li>

                  <li> - Hébergeur du frontend : Vercel</li>
                </ul>
              </div>
              <div className="legal-mentions-paragraph">
                <img
                  src={cyberMalveillanceLogo}
                  alt="Logo de Cyber Malveillance"
                  width="25%"
                />
              </div>
              <h1>2. Description du Site – Utilisation et Contenu : </h1>
              <div className="legal-mentions-paragraph">
                Ce site est normalement accessible à tout moment aux
                utilisateurs. Une interruption pour raison de maintenance
                technique peut être toutefois décidée par Alliance Cyber
                Technologies, qui s’efforcera alors de communiquer préalablement
                aux utilisateurs les dates et heures de l’intervention. Le site
                https://forms.alliancecybertech.com a pour objet de fournir une
                information relative à l’ensemble de l’offre de services
                d’Alliance Cyber Technologies concernant l’accompagnement en
                cyber sécurité et la mise en conformité au RGPD (Règlement
                Général de la Protection des Données).
              </div>
              <div className="legal-mentions-paragraph">
                Alliance Cyber Technologies s’efforce de fournir sur le site
                https://forms.alliancecybertech.com des informations aussi
                précises que possible. Toutefois, elle ne pourra être tenu
                responsable des omissions, des inexactitudes et des carences
                dans la mise à jour, qu’elles soient de son fait ou du fait des
                tiers partenaires qui lui fournissent ces informations.Toutes
                les informations indiquées sur le site
                https://forms.alliancecybertech.com sont données à titre
                indicatif, et sont susceptibles d’évoluer. Par ailleurs, les
                renseignements figurant sur le site
                https://forms.alliancecybertech.com ne sont pas exhaustifs. Ils
                sont donnés sous réserve de modifications ayant été apportées
                depuis leur mise en ligne.
              </div>
              <h1>3. Propriété intellectuelle et contrefaçons :</h1>
              <div className="legal-mentions-paragraph">
                Le contenu général présenté sur le site
                https://forms.alliancecybertech.com, notamment les textes, les
                images (animées ou non), les sons, graphismes, logos ou icônes
                (liste non exhaustive) sont la propriété exclusive de la SASU
                Alliance Cyber Technologies. Toute reproduction, représentation,
                modification, publication, adaptation de tout ou partie des
                éléments du site, quel que soit le moyen ou le procédé utilisé,
                est interdite, sauf autorisation écrite et expresse préalable
                d’Alliance Cyber Technologies. Toute exploitation ou
                reproduction non autorisée du site ou de l’un quelconque des
                éléments qu’il contient sera considérée comme constitutive d’une
                contrefaçon et poursuivie conformément aux dispositions des
                articles L.335-2 et suivants du Code de Propriété
                Intellectuelle.
              </div>
              <h1>4. Limitations de responsabilité :</h1>{" "}
              <div className="legal-mentions-paragraph">
                Alliance Cyber Technologies ne pourra être tenu responsable des
                dommages directs et indirects causés au matériel de
                l’utilisateur, lors de l’accès au site
                https://forms.alliancecybertech.com, et résultant soit de
                l’utilisation d’un matériel ne disposant pas de protections
                logicielles adéquates, soit de l’apparition d’un bug ou d’une
                incompatibilité.
              </div>
              <div className="legal-mentions-paragraph">
                Alliance Cyber Technologies ne pourra également être tenu
                responsable des dommages indirects (tels par exemple qu’une
                perte de marché ou perte d’une chance) consécutifs à
                l’utilisation du site https://forms.alliancecybertech.com .
              </div>
              <div className="legal-mentions-paragraph">
                Les informations contenues dans ce site ne constituent
                aucunement des offres commerciales de produits ou de services et
                ne sauraient avoir une valeur contractuelle. Le contenu des
                pages, diffusé à titre purement informatif, ne saurait engager
                la responsabilité de l’éditeur du site.
              </div>
              <h1>5. Liens hypertextes : </h1>{" "}
              <div className="legal-mentions-paragraph">
                Le site https://forms.alliancecybertech.com contient un certain
                nombre de liens hypertextes vers d’autres sites, mis en place
                avec l’autorisation d’Alliance Cyber Technologies.
              </div>
              <h1>6. Droit applicable et attribution de juridiction : </h1>{" "}
              <div className="legal-mentions-paragraph">
                Tout litige en relation avec l’utilisation du site
                https://forms.alliancecybertech.com est soumis au droit
                français.
              </div>
            </p>

            <div id="legal-mentions-close-button-container">
              <button
                onClick={() => setDisplayLegalMentions(false)}
                id="legal-mentions-close-button"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalMentions;
