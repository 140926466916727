// Function to send the NIS2 organisation status by email, through the backend
export const sendNIS2StatusThroughBackend = (
  organisationNIS2Status,
  Q1IsInEuropeanUnionAnswer,
  Q2WhatSectorsOfActivity,
  Q3WhatOtherSectorsOfActivity,
  Q3WhatEnergySubSectors,
  Q3WhatTransportSubSectors,
  Q3WhatNumericInfrastructureSubSectors,
  Q3WhatTICServicesSubSectors,
  Q3WhatPublicAdministrationSubSectors,
  Q4WhatManufacturingSubSectors,
  Q4NoneFromOtherSectorsSubSectors,
  Q4WhatSizeAndRevenue,
  Q5WhichOnesAreCorrect,
  Q6WhatActivities,
  organisationName,
  organisationEmail,
  organisationFirstName,
  organisationLastName,
  organisationPhone,
  setIsRequestLoading,
  setDisplayEmailSentConfirmation,
  setDisplayEmailSentError
) => {
  setIsRequestLoading(true);
  fetch(process.env.REACT_APP_BACKEND_URL + "nis2_forms", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      organisationNIS2Status,
      Q1IsInEuropeanUnionAnswer,
      Q2WhatSectorsOfActivity,
      Q3WhatOtherSectorsOfActivity,
      Q3WhatEnergySubSectors,
      Q3WhatTransportSubSectors,
      Q3WhatNumericInfrastructureSubSectors,
      Q3WhatTICServicesSubSectors,
      Q3WhatPublicAdministrationSubSectors,
      Q4WhatManufacturingSubSectors,
      Q4NoneFromOtherSectorsSubSectors,
      Q4WhatSizeAndRevenue,
      Q5WhichOnesAreCorrect,
      Q6WhatActivities,
      organisationName,
      organisationEmail,
      organisationFirstName,
      organisationLastName,
      organisationPhone,
    }),
  })
    .then((response) => {
      // console.log("RESPONSE from sendNIS2StatusThroughBackend :\n", response);
      return response.json();
    })
    .then((data) => {
      setIsRequestLoading(false);

      // console.log("DATA from sendNIS2StatusThroughBackend :\n", data);
      if (data.message === "Email envoyé") {
        setDisplayEmailSentConfirmation(true);
      } else {
        setDisplayEmailSentError(true);
      }
    });
  // .catch((error) =>
  // console.error("sendNIS2StatusThroughBackend -> ERROR", error.message)
  // );
};

// Function to remove a specific value from an array
export const removeValueFromArray = (array, value) => {
  return array.filter((item) => item !== value);
};

// Function to add answers to an array or remove them according to user's choice
// specific to sectors of activity asked in the first list
export const answersManagementForQ2SectorsOfActivity = (
  e,
  arrayAtom,
  setArrayAtom,
  setQ3WhatEnergySubSectors,
  setQ3WhatTransportSubSectors,
  setQ3WhatNumericInfrastructureSubSectors,
  setQ3WhatTICServicesSubSectors,
  setQ3WhatPublicAdministrationSubSectors
) => {
  const { name, checked } = e.target;

  let newSubSectors = [...arrayAtom];

  if (name === "aucun de la liste") {
    // Handle "aucun de la liste" separately
    setArrayAtom(checked ? ["aucun de la liste"] : []);
  } else {
    // Remove "aucun de la liste" if any other option is selected
    newSubSectors = removeValueFromArray(newSubSectors, "aucun de la liste");

    if (checked) {
      if (!newSubSectors.includes(name)) {
        newSubSectors.push(name);
      }
    } else {
      newSubSectors = removeValueFromArray(newSubSectors, name);
      name === "énergie" && setQ3WhatEnergySubSectors([]);
      name === "transport" && setQ3WhatTransportSubSectors([]);
      name === "infrastructure numérique" &&
        setQ3WhatNumericInfrastructureSubSectors([]);
      name === "gestion des services TIC" && setQ3WhatTICServicesSubSectors([]);
      name === "administration publique" &&
        setQ3WhatPublicAdministrationSubSectors([]);
    }

    setArrayAtom(newSubSectors);
  }
};

// Function to add answers to an array or remove them according to user's choice
// specific to sectors of activity asked in the second list
export const answersManagementForQ3OtherSectorsOfActivity = (
  e,
  arrayAtom,
  setArrayAtom,
  setQ4WhatManufacturingSubSectors,
  setQ4NoneFromOtherSectors
) => {
  const { name, checked } = e.target;

  let newSubSectors = [...arrayAtom];

  if (name === "aucun de la liste") {
    // Handle "aucun de la liste" separately
    if (checked) {
      setArrayAtom(["aucun de la liste"]);
    }
    if (!checked) {
      setArrayAtom([]);
      setQ4NoneFromOtherSectors([]);
    }
  } else {
    // Remove "aucun de la liste" if any other option is selected
    newSubSectors = removeValueFromArray(newSubSectors, "aucun de la liste");

    if (checked) {
      if (!newSubSectors.includes(name)) {
        newSubSectors.push(name);
      }
    } else {
      newSubSectors = removeValueFromArray(newSubSectors, name);
      name === "fabrication" && setQ4WhatManufacturingSubSectors([]);
    }

    setArrayAtom(newSubSectors);
  }
};

// Function to add answers to an array or remove them according to user's choice
// specific to Q5WhichOnesAreCorrect form
export const answersManagementForQ5WhichOnesAreCorrect = (
  e,
  arrayAtom,
  setArrayAtom
) => {
  const { name, checked } = e.target;

  let newSubSectors = [...arrayAtom];

  if (name === "aucun de la liste") {
    // Handle "aucun de la liste" separately
    setArrayAtom(checked ? ["aucun de la liste"] : []);
  } else {
    // Remove "aucun de la liste" if any other option is selected
    newSubSectors = removeValueFromArray(newSubSectors, "aucun de la liste");

    if (checked) {
      if (!newSubSectors.includes(name)) {
        newSubSectors.push(name);
      }
    } else {
      newSubSectors = removeValueFromArray(newSubSectors, name);
    }

    setArrayAtom(newSubSectors);
  }
};

// Function to add answers to an array or remove them according to user's choice
// specific to Q6WhatActivities form
export const answersManagementForQ6WhatActivities = (
  e,
  arrayAtom,
  setArrayAtom
) => {
  const { name, checked } = e.target;

  let newSubSectors = [...arrayAtom];

  if (name === "aucun de la liste") {
    // Handle "aucun de la liste" separately
    setArrayAtom(checked ? ["aucun de la liste"] : []);
  } else {
    // Remove "aucun de la liste" if any other option is selected
    newSubSectors = removeValueFromArray(newSubSectors, "aucun de la liste");

    if (checked) {
      if (!newSubSectors.includes(name)) {
        newSubSectors.push(name);
      }
    } else {
      newSubSectors = removeValueFromArray(newSubSectors, name);
    }

    setArrayAtom(newSubSectors);
  }
};

export const resetSubSectors = (
  setQ3WhatEnergySubSectors,
  setDisplayEnergySubSectors,
  setQ3WhatTransportSubSectors,
  setDisplayTransportSubSectors,
  setQ3WhatNumericInfrastructureSubSectors,
  setDisplayNumericInfrastructureSubSectors,
  setQ3WhatTICServicesSubSectors,
  setDisplayTICServicesSubSectors,
  setQ3WhatPublicAdministrationSubSectors,
  setDisplayPublicAdministrationSubSectors
) => {
  setQ3WhatEnergySubSectors([]);
  setDisplayEnergySubSectors(false);
  setQ3WhatTransportSubSectors([]);
  setDisplayTransportSubSectors(false);
  setQ3WhatNumericInfrastructureSubSectors([]);
  setDisplayNumericInfrastructureSubSectors(false);
  setQ3WhatTICServicesSubSectors([]);
  setDisplayTICServicesSubSectors(false);
  setQ3WhatPublicAdministrationSubSectors([]);
  setDisplayPublicAdministrationSubSectors(false);
};

export const resetOtherSectors = (
  setQ3WhatOtherSectorsOfActivity,
  setQ4WhatManufacturingSubSectors,
  setQ4NoneFromOtherSectors,
  setDisplayOtherSectorsOfActivity
) => {
  setQ3WhatOtherSectorsOfActivity([]);
  setQ4WhatManufacturingSubSectors([]);
  setQ4NoneFromOtherSectors([]);
  setDisplayOtherSectorsOfActivity(false);
};

export const resetSizeAndRevenue = (
  setQ4WhatSizeAndRevenue,
  setDisplayQ4WhatSizeAndRevenue
) => {
  setQ4WhatSizeAndRevenue([]);
  setDisplayQ4WhatSizeAndRevenue(false);
};

export const resetQ5WhichOnesAreCorrect = (
  setQ5WhichOnesAreCorrect,
  setDisplayQ5WhichOnesAreCorrect
) => {
  setQ5WhichOnesAreCorrect([]);
  setDisplayQ5WhichOnesAreCorrect(false);
};

export const resetQ6WhatActivties = (
  setQ6WhatActivities,
  setDisplayQ6WhatActivities
) => {
  setQ6WhatActivities([]);
  setDisplayQ6WhatActivities(false);
};

export const resetNoneFromOtherSectorsSubSectors = (
  setQ4NoneFromOtherSectors,
  setDisplayNoneFromOtherSectorsSubSectors
) => {
  setQ4NoneFromOtherSectors([]);
  setDisplayNoneFromOtherSectorsSubSectors(false);
};

// Function that redirect to the collect information on organsiation form
export const redirectToCollectInformationsForm = (navigate) => {
  navigate("/nis2-organisation-informations-form");
};
