import React, { useEffect, useState } from "react";

const UserInformationsEditionFormErrorMessages = ({
  phoneInputValidity,
  siretInputValidity,
  dateInputValidity,
  setDisplayErrorMessages,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const defineErrorMessage = () => {
      !phoneInputValidity &&
        setErrorMessage(
          "Le numéro de téléphone doit être au format 0102030405."
        );

      !siretInputValidity &&
        setErrorMessage("Le numéro SIRET doit être composé de 14 chiffres.");

      !dateInputValidity &&
        setErrorMessage(
          "Pour être rappelé, veuillez choisir un jour ouvrable (du lundi au vendredi, entre 9h et 17h59)."
        );
    };

    defineErrorMessage();
  }, [phoneInputValidity, siretInputValidity, dateInputValidity]);

  return (
    <>
      <div id="edition-form-error-modal">
        <div id="edition-form-error-container">
          <div id="edition-form-error">{errorMessage}</div>
          <div
            id="edition-form-error-close-button-container"
            onClick={() => setDisplayErrorMessages(false)}
          >
            <button id="edition-form-error-close-button">Fermer</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInformationsEditionFormErrorMessages;
