import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";

const GiveAtLeastOneAnswer = ({
  setDisplayGiveAtLeastOneAnswer,
  answerType,
}) => {
  return (
    <>
      <div id="nis-pop-up-modal">
        <div id="nis2-pop-up-container">
          <div className="nis2-pop-up-content">
            <p className="text-center font-dark-blue1 font-size-1-50rem margin-bottom-30px">
              Réponse manquante
            </p>
            Vous devez choisir au moins une réponse pour{" "}
            <strong>
              <u>{answerType}</u>
            </strong>
            .
            <div
              className="nis2-pop-up-button-container"
              onClick={() => setDisplayGiveAtLeastOneAnswer(false)}
            >
              <div className="nis2-pop-up-button-text">FERMER</div>
              <FaLongArrowAltRight className="nis2-pop-up-button-arrow" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GiveAtLeastOneAnswer;
