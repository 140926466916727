import React from "react";

// Bar chart
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import { useAtomValue } from "jotai";
import {
  preauditAnswersAtom,
  preauditBarOptionsAtom,
} from "../../../../../atoms/PreauditAtom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MobileChart = () => {
  const preauditAnswers = useAtomValue(preauditAnswersAtom);
  const preauditBarOptions = useAtomValue(preauditBarOptionsAtom);
  defaults.font.size = 10;
  defaults.font.weight = "bold";
  const labels = preauditAnswers && Object.keys(preauditAnswers);
  const data = {
    labels,
    datasets: [
      {
        label: "vos réponses",
        data: preauditAnswers && Object.values(preauditAnswers),
        borderColor: "rgba(73, 190, 255, 1)",
        backgroundColor: "rgba(73, 190, 255, 0.2)",
      },
    ],
  };

  return (
    <Bar
      datasetIdKey="id"
      data={data}
      options={preauditBarOptions}
      height={400}
    />
  );
};

export default MobileChart;
