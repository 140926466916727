import React from "react";

import { Radar } from "react-chartjs-2";

// Radar Chart
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import {
  preauditAnswersAtom,
  preauditRadarOptionsAtom,
} from "../../../../../atoms/PreauditAtom";
import { useAtomValue } from "jotai";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const DesktopChart = () => {
  const preauditAnswers = useAtomValue(preauditAnswersAtom);
  const preauditRadarOptions = useAtomValue(preauditRadarOptionsAtom);

  //   const options = preauditRadarOptions;

  // useEffect(() => {
  //   console.log(preauditAnswers);
  // }, [preauditAnswers]);

  const data = {
    labels: preauditAnswers && Object.keys(preauditAnswers),
    datasets: [
      {
        id: "1",
        label: "Votre score",
        data: preauditAnswers && Object.values(preauditAnswers),
        backgroundColor: "rgba(73, 190, 255, 0.50)",
        borderColor: "rgba(73, 190, 255)",
        borderWidth: 2,
      },
      {
        id: "2",
        label: "",
        data: [0],
        backgroundColor: "rgba(0, 0, 0, 0)",
        borderColor: "rgba(0, 0, 0, 1)",
        borderWidth: 0,
      },
      {
        id: "3",
        label: "Référence",
        data: [
          10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10,
          10, 10, 10, 10, 10, 10, 10,
        ],
        backgroundColor: "rgb(255, 255, 255, 0)",
        borderColor: "white",
        borderWidth: 2,
      },
    ],
  };

  return (
    <>
      <Radar datasetIdKey="id" data={data} options={preauditRadarOptions} />
    </>
  );
};

export default DesktopChart;
