// Functions to RESET the user's password
export const sendResetInstructions = (
  email,
  setDisplayResetInstructionsForm
) => {
  fetch(process.env.REACT_APP_BACKEND_URL + "users/password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user: { email: email } }),
  })
    .then((response) => {
      // console.log("RESPONSE from sendResetInstructions :\n", response)
      response.status === 200
        ? alert(
            `Si ${email} fait partie de nos utilisateurs, un lien de réinitialisation du mot de passe lui a été envoyé par mail.`
          )
        : alert(
            `Opération non réalisée : écrivez à ${process.env.REACT_APP_ACT_MAIL_CONTACT} si le problème persiste.`
          );
    })
    .then(() => setDisplayResetInstructionsForm(false));
  // .catch(error => console.error("askResetInstructions -> ERROR", error.message))
};

export const resetPassword = (token, password, passwordConfirmation) => {
  fetch(process.env.REACT_APP_BACKEND_URL + `users/password`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user: {
        reset_password_token: token,
        password: password,
        password_confirmation: passwordConfirmation,
      },
    }),
  }).then((response) => {
    response.status === 200
      ? alert("Le mot de passe vient d'être changé :) . Reconnectez-vous.")
      : alert(
          `Opération non réalisée : écrivez à ${process.env.REACT_APP_ACT_MAIL_CONTACT} assistance si le problème persiste.`
        );
  });
  //   .catch(error => console.error("resetPassword -> ERROR :", error.message))
};
