import React, { useEffect, useState } from "react";
// import { useAtomValue } from "jotai";
// import {
//   Q1IsInEuropeanUnionAnswerAtom,
//   Q2WhatSectorsOfActivityAtom,
//   Q3WhatEnergySubSectorsAtom,
//   Q3WhatNumericInfrastructureSubSectorsAtom,
//   Q3WhatOtherSectorsOfActivityAtom,
//   Q3WhatPublicAdministrationSubSectorsAtom,
//   Q3WhatTICServicesSubSectorsAtom,
//   Q3WhatTransportSubSectorsAtom,
//   Q4NoneFromOtherSectorsSubSectorsAtom,
//   Q4WhatManufacturingSubSectorsAtom,
//   Q4WhatSizeAndRevenueAtom,
//   Q5WhichOnesAreCorrectAtom,
//   Q6WhatActivitiesAtom,
// } from "../../../../atoms/NIS2Atom";
import Q1IsInEuropeanUnionQuestion from "./components/Q1IsInEuropeanUnionQuestion";
import NIS2Image from "../../../images/NIS2.png";

const NIS2FormWelcome = () => {
  const [displayNIS2Form, setDisplayNIS2Form] = useState(false);

  // const Q1IsInEuropeanUnionAnswer = useAtomValue(Q1IsInEuropeanUnionAnswerAtom);

  // const Q2WhatSectorsOfActivityAnswer = useAtomValue(
  //   Q2WhatSectorsOfActivityAtom
  // );

  // const Q3WhatEnergySubSectorsAnswers = useAtomValue(
  //   Q3WhatEnergySubSectorsAtom
  // );

  // const Q3WhatTransportSubSectorsAnswers = useAtomValue(
  //   Q3WhatTransportSubSectorsAtom
  // );

  // const Q3WhatNumericInfrastructureSubSectorsAnswers = useAtomValue(
  //   Q3WhatNumericInfrastructureSubSectorsAtom
  // );

  // const Q3WhatTICServicesSubSectorsAnswers = useAtomValue(
  //   Q3WhatTICServicesSubSectorsAtom
  // );

  // const Q3WhatPublicAdministrationSubSectorsAnswers = useAtomValue(
  //   Q3WhatPublicAdministrationSubSectorsAtom
  // );

  // const Q3WhatOtherSectorsOfActivityAnswers = useAtomValue(
  //   Q3WhatOtherSectorsOfActivityAtom
  // );

  // const Q4WhatManufacturingSubSectorsAnswers = useAtomValue(
  //   Q4WhatManufacturingSubSectorsAtom
  // );

  // const Q4NoneFromOtherSectorsSubSectorsAnswers = useAtomValue(
  //   Q4NoneFromOtherSectorsSubSectorsAtom
  // );

  // const Q4WhatSizeAndRevenue = useAtomValue(Q4WhatSizeAndRevenueAtom);

  // const Q5WhichOnesAreCorrect = useAtomValue(Q5WhichOnesAreCorrectAtom);

  // const Q6WhatActivities = useAtomValue(Q6WhatActivitiesAtom);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  // const showAllGivenAnswers = () => {
  //   console.clear();
  //   console.log("Les réponses données jusqu'ici sont :\n");
  //   console.log("- Q1 Union Européenne > ", Q1IsInEuropeanUnionAnswer);
  //   console.log("- Q2 Secteurs d'activité > " + Q2WhatSectorsOfActivityAnswer);
  //   console.log(
  //     "- Q3 Sous-secteurs d'activité > " +
  //       Q3WhatEnergySubSectorsAnswers +
  //       " || " +
  //       Q3WhatTransportSubSectorsAnswers +
  //       " || " +
  //       Q3WhatNumericInfrastructureSubSectorsAnswers +
  //       " || " +
  //       Q3WhatTICServicesSubSectorsAnswers +
  //       " || " +
  //       Q3WhatPublicAdministrationSubSectorsAnswers
  //   );
  //   console.log(
  //     "- Q3 Autres secteurs d'activité > " + Q3WhatOtherSectorsOfActivityAnswers
  //   );
  //   console.log(
  //     "- Q4 Autres sous secteurs d'activité > " +
  //       Q4WhatManufacturingSubSectorsAnswers +
  //       " || " +
  //       Q4NoneFromOtherSectorsSubSectorsAnswers
  //   );
  //   console.log("- Q4 Taille moyenne à grande > " + Q4WhatSizeAndRevenue);
  //   console.log("- Q5 Quelles affirmations ? > " + Q5WhichOnesAreCorrect);
  //   console.log("- Q6 Quelle activité ? > " + Q6WhatActivities);
  // };

  return (
    <>
      <div className="nis2-form-container">
        <div id="nis2-form-title-container">
          <h1>NIS 2 en octobre 2024</h1>
          {/* <h4>Votre organisation est-elle concernée ?</h4> */}
        </div>

        <div id="nis2-form-subtitle-container">
          <h4>Votre organisation est-elle concernée ?</h4>
          <div className="woman-sitting-with-computer-for-nis2-form"></div>
          <div className="two-men-climbing-for-nis2-form"></div>
        </div>

        <div id="nis2-form-image-title-container">
          <img
            src={NIS2Image}
            alt="illustration de NIS2"
            className="nis2-form-image-title"
          />
        </div>

        <div id="nis2-form-catchphrase-container">
          <a
            href="https://www.alliancecybertech.com"
            target="_blank"
            rel="noreferrer"
            className="font-weight-700"
          >
            Alliance Cyber Technologies
          </a>{" "}
          vous propose son questionnaire pour déterminer en moins d'une minute
          si votre organisation doit se conformer à la directive NIS 2.
        </div>

        {displayNIS2Form ? (
          <form>
            <Q1IsInEuropeanUnionQuestion />
          </form>
        ) : (
          <>
            <div id="nis2-form-start-button-container">
              <button
                id="nis2-form-start-button"
                onClick={() => setDisplayNIS2Form(true)}
              >
                <div>
                  <div>
                    <span className="font-size-1-50rem font-family-jakarta-sansvariable font-weight-bold">
                      QUESTIONNAIRE
                    </span>
                  </div>
                  <div>
                    <span className="font-size-090rem">
                      (moins d'une minute)
                    </span>
                  </div>
                </div>
              </button>

              <div className="green-arrow-for-nis2-form"></div>
            </div>
          </>
        )}
      </div>

      <div>
        <button
          // onClick={() => showAllGivenAnswers()}
          className="background-color-red display-none"
        >
          Voir les réponses données
        </button>
      </div>
    </>
  );
};

export default NIS2FormWelcome;
