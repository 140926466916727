import { useAtomValue } from "jotai";
import React, { useEffect } from "react";
import { selectedToolAtom } from "../../../atoms/MenusSelectionAtom";
import PreauditDashboardV3 from "./PreauditDashboard/PreauditDashboardV3";
import SupplierAssesmentDashboardV3 from "./SupplierAssesmentDashboard/SupplierAssesmentDashboardV3";
import AuditRGPDDashboardV3 from "./AuditRGPDDashboard/AuditRGPDDashboardV3";
import { authUserDetailsAtom } from "../../../atoms/AuthenticationAtom";
import { useNavigate } from "react-router-dom";
import { checkInformationsCompletion } from "../../../utils/functions/Version3/functionsForUserInformationsManagement";
import { ImStatsBars2 } from "react-icons/im";

const DashboardV3 = () => {
  const selectedTool = useAtomValue(selectedToolAtom);

  const navigate = useNavigate();
  const userDetails = useAtomValue(authUserDetailsAtom);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      // left: 0,
      behavior: "smooth",
    });

    if (userDetails) {
      if (checkInformationsCompletion(userDetails)) {
        navigate("/dashboard");
      } else {
        // alert("Des informations doivent être complétées avant de poursuivre.");
        navigate("/user-informations-edition");
      }
    }
  }, [navigate, userDetails]);

  return (
    <>
      <div id="dashboard-container">
        <div className="page-title-container">
          <div className="page-title-icon-and-text">
            <div className="page-title-icon">
              <ImStatsBars2 />
            </div>
            <div className="page-title-text">Tableau de bord</div>
          </div>
        </div>

        {/* <div id="dashboard-description-container">
          <h2>
            Pour obtenir une analyse par l'un de nos experts en cybersécurité,{" "}
            <u>à titre grâcieux</u>, demandez à être contacté depuis l'onglet{" "}
            <span
              className="cursor-pointer font-orange"
              onClick={() => navigate("/user-informations-edition")}
            >
              vos informations
            </span>
            .
          </h2>

          <h1>Visualisez vos indicateurs depuis ce tableau de bord.</h1>
        </div> */}

        {selectedTool === "preaudit" && (
          <>
            <PreauditDashboardV3 />
          </>
        )}
        {selectedTool === "supplier-assesment" && (
          <SupplierAssesmentDashboardV3 />
        )}
        {selectedTool === "audit-rgpd" && <AuditRGPDDashboardV3 />}
      </div>
    </>
  );
};

export default DashboardV3;
