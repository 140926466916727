import { atom } from "jotai";

export const organisationNIS2StatusAtom = atom();
export const Q1IsInEuropeanUnionAnswerAtom = atom(null);
export const Q2WhatSectorsOfActivityAtom = atom([]);
export const Q3WhatOtherSectorsOfActivityAtom = atom([]);
export const Q3WhatEnergySubSectorsAtom = atom([]);
export const Q3WhatTransportSubSectorsAtom = atom([]);
export const Q3WhatNumericInfrastructureSubSectorsAtom = atom([]);
export const Q3WhatTICServicesSubSectorsAtom = atom([]);
export const Q3WhatPublicAdministrationSubSectorsAtom = atom([]);
export const Q4WhatManufacturingSubSectorsAtom = atom([]);
export const Q4NoneFromOtherSectorsSubSectorsAtom = atom([]);
export const Q4WhatSizeAndRevenueAtom = atom([]);
export const Q5WhichOnesAreCorrectAtom = atom([]);
export const displayQ5WhichOnesAreCorrectAtom = atom(false);
export const Q6WhatActivitiesAtom = atom([]);
export const displayQ6WhatActivitiesAtom = atom(false);
export const NIS2StatusAtom = atom([
  "organisation non concernée par NIS2",
  "entité essentielle et doit donc se conformer à NIS2",
  "entité importante et doit donc se conformer à NIS2",
  "entité critique et doit donc se conformer à NIS2"
]);
