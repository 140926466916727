import React, { useState } from "react";
import SupplierInformationsEditionForm from "./SupplierInformationsEditionForm";
import { FaChartPie } from "react-icons/fa";
import { FiSave } from "react-icons/fi";
import { BiSolidRightArrow } from "react-icons/bi";

const SupplierInformationsEdition = () => {
  const [displayAssesmentQuestions, setDisplayAssesmentQuestions] =
    useState(false);

  return (
    <>
      <div id="supplier-assesment-answering-container">
        <div className="page-title-container">
          <div className="page-title-icon-and-text">
            <div className="page-title-icon">
              <FaChartPie />
            </div>
            <div className="page-title-text">Évaluation de fournisseur</div>
          </div>
        </div>

        {displayAssesmentQuestions ? (
          <div id="supplier-information-description">
            Utilisez{" "}
            <BiSolidRightArrow
              className="menu-icon background-color-orange"
              color="white"
            />
            pour afficher les questions et{" "}
            <FiSave className="menu-icon background-color-red" color="white" />{" "}
            pour enregistrer vos réponses.
          </div>
        ) : (
          <>
            <SupplierInformationsEditionForm
              setDisplayAssesmentQuestions={setDisplayAssesmentQuestions}
            />
          </>
        )}
      </div>
    </>
  );
};

export default SupplierInformationsEdition;
