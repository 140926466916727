import React from "react";

const LoginAlreadyExists = ({ setDoesLoginAlreadyExist }) => {
  return (
    <>
      <div className="signinup-error-message-modal">
        <div className="signinup-error-message-container">
          <div>
            <span className="font-dark-blue1">
              Cet email ne peut pas être utilisé pour s'inscrire.
              <br />
              Merci d'en choisir un autre.
            </span>
          </div>

          <div
            className="signinup-error-message-close-button-container"
            onClick={() => setDoesLoginAlreadyExist(false)}
          >
            <button>Fermer</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginAlreadyExists;
