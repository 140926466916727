import React from "react";
import { useNavigate } from "react-router-dom";
import { savePreauditInBackend } from "../../../../../utils/functions/Version3/functionsForPreauditManagement";
import { useAtomValue, useSetAtom } from "jotai";
import { authUserDetailsAtom } from "../../../../../atoms/AuthenticationAtom";
import {
  isPreauditModifiedAtom,
  preauditAnswersAtom,
} from "../../../../../atoms/PreauditAtom";

const PreauditValidationPrompt = ({ setDisplayValidationPrompt }) => {
  const navigate = useNavigate();
  const authUserDetails = useAtomValue(authUserDetailsAtom);
  const preauditAnswers = useAtomValue(preauditAnswersAtom);
  const setIsPreauditmodified = useSetAtom(isPreauditModifiedAtom);

  const handleValidate = () => {
    savePreauditInBackend(
      authUserDetails.id,
      preauditAnswers,
      setIsPreauditmodified,
      navigate
    );

    setDisplayValidationPrompt(false);
  };

  const handleCancel = () => {
    setDisplayValidationPrompt(false);
    navigate("/preaudit-questions");
  };

  return (
    <>
      <div id="preaudit-validation-prompt-modal">
        <div id="preaudit-validation-prompt">
          <div className="font-dark-blue1 margin-bottom-30px">
            Vous devez valider vos réponses avant de poursuivre vers le tableau
            de bord.
          </div>

          <div id="preaudit-validation-prompt-buttons-container">
            <button
              type="submit"
              id="preaudit-validation-prompt-submit-button"
              onClick={() => handleValidate()}
            >
              VALIDER
            </button>
            <button
              id="preaudit-validation-prompt-cancel-button"
              onClick={() => handleCancel()}
            >
              ANNULER
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreauditValidationPrompt;
