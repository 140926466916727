import React from "react";
import { redirectToCollectInformationsForm } from "../../../../../utils/functions/Version3/functionsForNIS2Management";
import { useNavigate } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";

const ThanksForUsingOurFormAndSubmit = () => {
  const navigate = useNavigate();

  return (
    <>
      <div id="nis-pop-up-modal">
        <div id="nis2-pop-up-container">
          <div className="nis2-pop-up-content">
            <p className="text-center font-dark-blue1 font-size-1-50rem margin-bottom-30px">
              Fin du questionnaire
            </p>
            <a
              href="https://www.alliancecybertech.com"
              className="font-dark-blue1 font-weight-bold"
              target="_bank"
            >
              Alliance Cyber Technologies
            </a>{" "}
            vous remercie d'avoir utilisé ce questionnaire.
            <br />
            Cliquez sur SUIVANT pour envoyer le résultat sur votre adresse
            email.
            <div
              className="nis2-pop-up-button-container"
              onClick={() => redirectToCollectInformationsForm(navigate)}
            >
              <div className="nis2-pop-up-button-text">SUIVANT</div>
              <FaLongArrowAltRight className="nis2-pop-up-button-arrow" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThanksForUsingOurFormAndSubmit;
