export const auditRGPDCategories = [
  {
    id: "1",
    name: "Questions globales",
  },

  {
    id: "2",
    name: "Questions de sécurité",
  },
];

export const auditRGPDQuestions = [
  {
    id: "1",
    category: auditRGPDCategories[0].name,
    content:
      "Les projets font-ils intervenir des sous-traitants dans les différentes phases du projet ? Des hébergeurs de données de santé ?",
  },

  {
    id: "2",
    category: auditRGPDCategories[0].name,
    content:
      "Existe-t-il une liste complète et à jour de ces sous-traitants (et tous les contrats et évaluations associés) ?",
  },

  {
    id: "3",
    category: auditRGPDCategories[0].name,
    content:
      "Les sous-traitants sont-ils certifiés RGPD ? (une évaluation de ces sous-traitants est-elle faite par le responsable de traitement ?",
  },

  {
    id: "4",
    category: auditRGPDCategories[0].name,
    content:
      "Existe-t-il  des procédures d’ajout ou de suppression des sous-traitants ?",
  },

  {
    id: "5",
    category: auditRGPDCategories[0].name,
    content:
      "Avez-vous défini des catégories distinctes pour différencier les personnes concernées par les traitements des données à caractère personnel (ex : salariés, clients, prospects, fournisseurs, etc.) ? Si oui, précisez ces catégories en commentaire.",
  },

  {
    id: "6",
    category: auditRGPDCategories[0].name,
    content:
      "Avez-vous défini le ou les cadre(s) dans lequel les données à caractère personnel sont collectées (ex : relations contractuelles, consentement des personnes, obligations légales de traiter les données, collectes par formulaires via des sites web, etc.) ? Si oui, inscrivez le ou les cadre(s) en commentaire.",
  },

  {
    id: "7",
    category: auditRGPDCategories[0].name,
    content:
      "Existe-t-il une procédure qui explique les étapes liées au devoir d’information des personnes concernées lors d'une collecte de données de santé ou de données personnelles ?",
  },

  {
    id: "8",
    category: auditRGPDCategories[0].name,
    content:
      "Les délais de conservation des données sont ils différents en fonction des catégories de données personnelles ?",
  },

  {
    id: "9",
    category: auditRGPDCategories[0].name,
    content:
      "Le paramétrage par défaut des solutions technologiques garantit-il le respect de la vie privée ? (privacy by design)",
  },

  {
    id: "10",
    category: auditRGPDCategories[0].name,
    content:
      "Connaissez-vous tous les fichiers dont dispose votre organisme, les types de données auxquels vous avez accès ainsi que l'endroit où vous les stockez ?",
  },

  {
    id: "11",
    category: auditRGPDCategories[0].name,
    content:
      "Votre organisme a-t-il un ou plusieurs registres des traitements ?",
  },

  {
    id: "12",
    category: auditRGPDCategories[0].name,
    content:
      "Distinguez-vous les données qui sont régulièrement exploitées (base active) de celles que vous archivez (base d’archive) ?",
  },

  {
    id: "13",
    category: auditRGPDCategories[0].name,
    content:
      "Les données présentes en base active ont toutes une finalité d'exploitation explicite (autrement dit aucune donnée n'est conservée par votre organisme uniquement dans l'hypothèse qu'elle présenterait un jour un intérêt) ?",
  },

  {
    id: "14",
    category: auditRGPDCategories[0].name,
    content:
      "Les fichiers qui contiennent des données sensibles (personnelles ou non) sont-ils régulièrement mis à jour ?",
  },

  {
    id: "15",
    category: auditRGPDCategories[0].name,
    content:
      "Les archives poursuivent-elles toutes un but bien identifié lors de la sauvegarde des données sensibles (personnelles ou non) ?",
  },

  {
    id: "16",
    category: auditRGPDCategories[0].name,
    content:
      "Avez-vous défini une politique d'archivage et de restauration des données sensibles ?",
  },

  {
    id: "17",
    category: auditRGPDCategories[0].name,
    content:
      "Lorsque la base légale de votre traitement est le consentement, existe-t-il un moyen permettant de le prouver ?",
  },

  {
    id: "18",
    category: auditRGPDCategories[0].name,
    content:
      "Votre organisme dispose-t-il où est-il susceptible de disposer de données concernant des mineurs ?",
  },

  {
    id: "19",
    category: auditRGPDCategories[0].name,
    content:
      "Votre organisme a-t-il mis en place une charte de protection des données personnelles permettant à chaque collaborateur de connaître les règles à respecter ",
  },

  {
    id: "20",
    category: auditRGPDCategories[0].name,
    content:
      "Votre organisme a-t-il désigné un délégué à la protection des données en charge de la mise en œuvre et du suivi du RGPD ?",
  },

  {
    id: "21",
    category: auditRGPDCategories[0].name,
    content:
      "Le site internet de votre organisation dispose-t-il d'un formulaire de collecte de données ?",
  },

  {
    id: "22",
    category: auditRGPDCategories[0].name,
    content:
      "Existe-t-il un lien qui renvoi vers la politique de confidentialité dans les mentions d'information de chaque formulaire ?",
  },

  {
    id: "23",
    category: auditRGPDCategories[0].name,
    content:
      "Les mentions légales et les mentions relatives à la protection des données personnelles sont-elles présentes sur des pages distinctes ?",
  },

  {
    id: "24",
    category: auditRGPDCategories[0].name,
    content: "Collectez-vous les adresses email pour envoyer des newsletters ?",
  },

  {
    id: "25",
    category: auditRGPDCategories[0].name,
    content: "Une partie du site est-elle destinée à la vente en ligne ?",
  },

  {
    id: "26",
    category: auditRGPDCategories[0].name,
    content:
      "Avez-vous identifié les différentes catégories de cookies déposées au cours de la navigation sur le site internet ?",
  },

  {
    id: "27",
    category: auditRGPDCategories[0].name,
    content:
      "Des cookies tiers sont-ils déposés au cours de la navigation sur le site internet ?",
  },

  {
    id: "28",
    category: auditRGPDCategories[0].name,
    content:
      "Est-ce qu'un bandeau d’information et de recueil du consentement des utilisateurs concernant les cookies apparaît lors de la navigation  ?",
  },

  {
    id: "29",
    category: auditRGPDCategories[0].name,
    content:
      "L'utilisateur peut-il accéder facilement à un module de gestion des cookies sur le site internet et retirer son consentement à tout moment ?",
  },

  {
    id: "30",
    category: auditRGPDCategories[0].name,
    content:
      "Votre registre des traitements recense-t-il l'ensemble des traitements auxquels nous avons fait référence jusque-là (formulaires de collecte, newsletter, vente en ligne et cookies) ?",
  },

  {
    id: "31",
    category: auditRGPDCategories[0].name,
    content:
      "Procédez-vous à un transfert des données personnelles hors UE ? (vers un cloud - norme ISO 27018 ?)",
  },

  {
    id: "32",
    category: auditRGPDCategories[0].name,
    content:
      "Un sous-traitant intervient-il dans la gestion du site internet ?",
  },

  {
    id: "33",
    category: auditRGPDCategories[0].name,
    content:
      "Existe-il un formulaire accessible sur le site afin de permettre aux personnes d'exercer leurs droits ? ",
  },

  {
    id: "34",
    category: auditRGPDCategories[0].name,
    content:
      "La politique de confidentialité mentionne-t-elle l'identité et les coordonnées du DPO (à jour) et une adresse email permettant de le contacter ?",
  },

  {
    id: "35",
    category: auditRGPDCategories[0].name,
    content:
      "Une politique interne de gestion des demandes d'exercice des droits liés aux données personnelles a-t-elle été rédigée ?",
  },

  {
    id: "36",
    category: auditRGPDCategories[0].name,
    content:
      "Existe-t-il une politique de gestion des durées de conservation des données collectées sur le site ?",
  },

  {
    id: "37",
    category: auditRGPDCategories[0].name,
    content:
      "Un plan de reprise et de continuité prévoit-il un plan d'archivage et de restauration des données personnelles en cas de sinistre ?",
  },
  {
    id: "38",
    category: auditRGPDCategories[0].name,
    content:
      "La gestion des données personnelles lors de l’archivage ou des restaurations se fait-elle de façon sécurisée (Certificats ? Flux sécurisés ?) ?",
  },
  {
    id: "39",
    category: auditRGPDCategories[0].name,
    content:
      "Les données personnelles sont-elles manipulées avec une méthode de chiffrement et des flux sécurisés ?",
  },
  {
    id: "40",
    category: auditRGPDCategories[0].name,
    content:
      "Existe-t-il des preuves d'effacement des données personnelles (dans des conditionnés sécurisées) ?",
  },
  {
    id: "41",
    category: auditRGPDCategories[0].name,
    content:
      "La sécurité du site et de l'entreprise est-elle définie dans une politique ?",
  },
  {
    id: "42",
    category: auditRGPDCategories[0].name,
    content: "Les événements à journaliser ont-ils été choisis ?",
  },
  {
    id: "43",
    category: auditRGPDCategories[0].name,
    content: "Le protocole TLS est-il en place sur le site internet ?",
  },
  {
    id: "44",
    category: auditRGPDCategories[0].name,
    content: "L'utilisateur peut-il créer un compte client sur le site ?",
  },
  {
    id: "45",
    category: auditRGPDCategories[0].name,
    content:
      "Les connexions au site internet sont-elles protégées par un pare-feu ?",
  },
  {
    id: "46",
    category: auditRGPDCategories[0].name,
    content:
      "Un antivirus, un EDR est-il déployé pour protéger  le site internet et plus globalement le SI ?",
  },
  {
    id: "47",
    category: auditRGPDCategories[0].name,
    content:
      "Des sondes IPS et IDS permettent-elles de protéger les données personnelles ? ",
  },
  {
    id: "48",
    category: auditRGPDCategories[0].name,
    content:
      "Des analyses des vulnérabilités, des audits ou tests d'intrusion sont ils régulièrement mis en oeuvre avec une remédiation et eventuellement, des contre-audits ?",
  },
  {
    id: "49",
    category: auditRGPDCategories[0].name,
    content:
      "Les accès pour l'administration du site internet sont-ils limités aux personnes ayant strictement la nécessité d'y accéder ?",
  },
  {
    id: "50",
    category: auditRGPDCategories[0].name,
    content:
      "Le site est-il régulièrement surveillé pour identifier les piratages et/ou tentatives d'intrusion ?",
  },
  {
    id: "51",
    category: auditRGPDCategories[0].name,
    content: "Le site est-il protégé contre les attaques de déni de service ?",
  },
  {
    id: "52",
    category: auditRGPDCategories[0].name,
    content: "Avez-vous réalisé un audit d'intrusion du site internet ?",
  },
  {
    id: "53",
    category: auditRGPDCategories[1].name,
    content:
      "Assurez-vous la sécurité des équipements assurant le stockage des données sensibles (données personnelles ou non) ? Si oui, indiquez en commentaire si vous utilisez le chiffrement, la pseudonymisation.",
  },
  {
    id: "54",
    category: auditRGPDCategories[1].name,
    content:
      "Assurez-vous  la protection des données en transite, en traitement, dans les bases de données ? Si oui, indiquez en commentaire si vous utilisez le chiffrement, la pseudonymisation.",
  },
  {
    id: "55",
    category: auditRGPDCategories[1].name,
    content:
      "Un annuaire Active Directory  est-il utilisé ? Dans l'affirmative, des audits de sécurité sur les comptes et les droits des utilisateurs sont-ils faits ?",
  },
  {
    id: "56",
    category: auditRGPDCategories[1].name,
    content:
      "Existe-t-il une liste des protocoles et des flux sécurisés qui permettent de faire transiter les données sensibles (données personnelles ou non) ?",
  },

  {
    id: "57",
    category: auditRGPDCategories[1].name,
    content:
      "Assurez-vous la sécurité de l'authentification dans le cadre des traitements de données sensibles (données personnelles ou non) ? Si oui, indiquez en commentaire la technologie utilisée (mot de passe fort ? SSO ? MAF ?)",
  },
  {
    id: "58",
    category: auditRGPDCategories[1].name,
    content:
      "Assurez-vous le suivi des connexions au réseau ? Si oui, indiquez en commentaire la pratique utilisée (journalisation des connexions ? analyse de logs ? alerte vers un SOC si comptes à privilèges ?)",
  },
  {
    id: "59",
    category: auditRGPDCategories[1].name,
    content:
      "Un circuit de gestion des incidents de sécurité existe-t-il ? (avec une escalade vers le DPO et ensuite, la CNIL)",
  },
  {
    id: "60",
    category: auditRGPDCategories[1].name,
    content: "Une cellule de gestion de crise existe-t-elle ?",
  },

  {
    id: "61",
    category: auditRGPDCategories[1].name,
    content: "Des simulations de crises existe-t-elles ?",
  },

  {
    id: "62",
    category: auditRGPDCategories[1].name,
    content:
      "Une certification ISO 27 001 ou les recommandations de cette norme sont-elles mises en œuvre ?",
  },
  {
    id: "63",
    category: auditRGPDCategories[1].name,
    content:
      "Une certification ISO 20 000 ou les recommandations de cette norme sont-elles mises en œuvre ?",
  },
  {
    id: "64",
    category: auditRGPDCategories[1].name,
    content:
      "Un PAS (Plan d’Assurance Sécurité) ou une évaluation existe-t-il pour évaluer les niveaux des risques liés à de nouveaux projets ?",
  },
  {
    id: "65",
    category: auditRGPDCategories[1].name,
    content:
      "Des sensibilisations ou formations sur le RGPD sont elles faites pour l'ensemble des salariés ?",
  },

  {
    id: "66",
    category: auditRGPDCategories[1].name,
    content:
      "Des analyses des risques sont-elles réalisées lors de nouveaux projets liés aux traitements de données personnelles (méthode EBIOS ? Norme ISO 2005 pour les risques RGPD ?)",
  },
];
