import React from "react";
import { Outlet } from "react-router-dom";
import NavbarV3 from "../../../../components/Version3/NavbarV3/NavbarV3";
import Footer from "../../../../components/Version3/Footer/Footer";

const ToolsLayout = () => {
  return (
    <>
      <main>
        <div id="main-container">
          <nav>
            <NavbarV3 />
          </nav>

          <div id="outlet-container">
            <Outlet />
          </div>
        </div>
      </main>

      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default ToolsLayout;
