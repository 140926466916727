import React, { useState } from "react";
import { JSONNIS2Questions } from "../../../../utils/JSON/NIS2FormQuestions";
import {
  Q3WhatOtherSectorsOfActivityAtom,
  organisationNIS2StatusAtom,
  Q4WhatManufacturingSubSectorsAtom,
  Q4NoneFromOtherSectorsSubSectorsAtom,
  Q4WhatSizeAndRevenueAtom,
  Q5WhichOnesAreCorrectAtom,
  displayQ5WhichOnesAreCorrectAtom,
  displayQ6WhatActivitiesAtom,
  Q6WhatActivitiesAtom,
  NIS2StatusAtom,
} from "../../../../atoms/NIS2Atom";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import {
  answersManagementForQ3OtherSectorsOfActivity,
  resetNoneFromOtherSectorsSubSectors,
  resetQ5WhichOnesAreCorrect,
  resetQ6WhatActivties,
  resetSizeAndRevenue,
} from "../../../../utils/functions/Version3/functionsForNIS2Management";
import ManufacturingSubSectors from "./SubSectors/ManufacturingSubSectors";
import NoneFromOtherSectorsSubSectors from "./SubSectors/NoneFromOtherSectorsSubSectors";
import Q4WhatSizeAndRevenue from "./Q4WhatSizeAndRevenue";
import ThanksForUsingOurFormAndSubmit from "./PopUps/ThanksForUsingOurFormAndSubmit";
import GiveAtLeastOneAnswer from "./PopUps/GiveAtLeastOneAnswer";
import { FaLongArrowAltRight } from "react-icons/fa";

const Q3WhatOthersSectorsOfActivity = () => {
  const NIS2Status = useAtomValue(NIS2StatusAtom);

  const setOrganisationNIS2Status = useSetAtom(organisationNIS2StatusAtom);

  const [Q3WhatOtherSectorsOfActivity, setQ3WhatOtherSectorsOfActivity] =
    useAtom(Q3WhatOtherSectorsOfActivityAtom);

  const [Q4WhatManufacturingSubSectors, setQ4WhatManufacturingSubSectors] =
    useAtom(Q4WhatManufacturingSubSectorsAtom);

  const [Q4NoneFromOtherSectors, setQ4NoneFromOtherSectors] = useAtom(
    Q4NoneFromOtherSectorsSubSectorsAtom
  );

  const [displayManufacturingSubSectors, setDisplayManufacturingSubSectors] =
    useState(false);

  const [
    displayNoneFromOtherSectorsSubSectors,
    setDisplayNoneFromOtherSectorsSubSectors,
  ] = useState(false);

  const [displayQ4WhatSizeAndRevenu, setDisplayQ4WhatSizeAndRevenue] =
    useState(false);

  const setQ4WhatSizeAndRevenue = useSetAtom(Q4WhatSizeAndRevenueAtom);

  const setQ5WhichOnesAreCorrect = useSetAtom(Q5WhichOnesAreCorrectAtom);
  const setDisplayQ5WhichOnesAreCorrect = useSetAtom(
    displayQ5WhichOnesAreCorrectAtom
  );

  const setDisplayQ6WhatActivities = useSetAtom(displayQ6WhatActivitiesAtom);

  const setQ6WhatActivities = useSetAtom(Q6WhatActivitiesAtom);

  const resetManufacturingSubSectors = () => {
    setQ4WhatManufacturingSubSectors([]);
    setDisplayManufacturingSubSectors(false);
  };

  const [displayThanksPopUp, setDisplayThanksPopUp] = useState(false);
  const [displayGiveAtLeastOneAnswer, setDisplayGiveAtLeastOneAnswer] =
    useState(false);
  const [answerType, setAnswerType] = useState(null);

  const answersAnlaysis = () => {
    // Analyse user's answers before running next step :
    if (isFormValid()) {
      // 1 - Case : the organisation is an "Entité exclue"
      if (
        (Q3WhatOtherSectorsOfActivity.length === 1 &&
          Q3WhatOtherSectorsOfActivity[0] === "fabrication" &&
          Q4WhatManufacturingSubSectors.length === 1 &&
          Q4WhatManufacturingSubSectors[0] === "aucun de la liste") ||
        Q4NoneFromOtherSectors[0] === "aucun de la liste"
      ) {
        setOrganisationNIS2Status(NIS2Status[0]);
        setDisplayThanksPopUp(true);
      }
      // 2 - Case : the organisation is an "Entité essentielle"
      else if (
        Q4NoneFromOtherSectors.includes(
          "identifiée comme un Opérateur de Services Essentiels"
        ) ||
        Q4NoneFromOtherSectors.includes("recensée en tant qu’entité critique")
      ) {
        setOrganisationNIS2Status(NIS2Status[1]);
        setDisplayThanksPopUp(true);
      }
      // 3 - Case : "Go question about organisation size and annual revenue"
      else {
        setDisplayQ4WhatSizeAndRevenue(true);
        window.scrollBy({
          top: 200,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  };

  const isFormValid = () => {
    return isOtherSectorsFormValid() &&
      isManufacturingFormValid() &&
      isNoneFromOtherSectorsFormValid()
      ? true
      : false;
  };

  const isOtherSectorsFormValid = () => {
    if (Q3WhatOtherSectorsOfActivity.length === 0) {
      setDisplayGiveAtLeastOneAnswer(true);
      setAnswerType("poursuivre");
      return false;
    } else return true;
  };

  const isManufacturingFormValid = () => {
    if (
      displayManufacturingSubSectors &&
      Q4WhatManufacturingSubSectors.length === 0
    ) {
      setDisplayGiveAtLeastOneAnswer(true);
      setAnswerType("Fabrication");
      return false;
    } else return true;
  };

  const isNoneFromOtherSectorsFormValid = () => {
    if (
      displayNoneFromOtherSectorsSubSectors &&
      Q4NoneFromOtherSectors.length === 0
    ) {
      setDisplayGiveAtLeastOneAnswer(true);
      setAnswerType("Aucun de la liste");
      return false;
    } else return true;
  };

  return (
    <>
      <div
        id="Q3WhatOtherSectorsOfActivity"
        className="nis2form-question-container"
      >
        <div className="nis2form-question-content">
          {JSONNIS2Questions[2].content}
        </div>
        <div id="q2-sectors-of-activity-container">
          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="services postaux et d'expédition"
                checked={Q3WhatOtherSectorsOfActivity.includes(
                  "services postaux et d'expédition"
                )}
                onChange={(e) =>
                  answersManagementForQ3OtherSectorsOfActivity(
                    e,
                    Q3WhatOtherSectorsOfActivity,
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors
                  )
                }
                onClick={() => {
                  resetNoneFromOtherSectorsSubSectors(
                    setQ4NoneFromOtherSectors,
                    setDisplayNoneFromOtherSectorsSubSectors
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Services postaux et d'expédition
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="gestion des déchets"
                checked={Q3WhatOtherSectorsOfActivity.includes(
                  "gestion des déchets"
                )}
                onChange={(e) =>
                  answersManagementForQ3OtherSectorsOfActivity(
                    e,
                    Q3WhatOtherSectorsOfActivity,
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors
                  )
                }
                onClick={() => {
                  resetNoneFromOtherSectorsSubSectors(
                    setQ4NoneFromOtherSectors,
                    setDisplayNoneFromOtherSectorsSubSectors
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Gestion des déchets
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="fabrication, production et distribution de produits chimiques"
                checked={Q3WhatOtherSectorsOfActivity.includes(
                  "fabrication, production et distribution de produits chimiques"
                )}
                onChange={(e) =>
                  answersManagementForQ3OtherSectorsOfActivity(
                    e,
                    Q3WhatOtherSectorsOfActivity,
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors
                  )
                }
                onClick={() => {
                  resetNoneFromOtherSectorsSubSectors(
                    setQ4NoneFromOtherSectors,
                    setDisplayNoneFromOtherSectorsSubSectors
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Fabrication, production et distribution de produits chimiques{" "}
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="production, transformation et distribution de denrées alimentaires"
                checked={Q3WhatOtherSectorsOfActivity.includes(
                  "production, transformation et distribution de denrées alimentaires"
                )}
                onChange={(e) =>
                  answersManagementForQ3OtherSectorsOfActivity(
                    e,
                    Q3WhatOtherSectorsOfActivity,
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors
                  )
                }
                onClick={() => {
                  resetNoneFromOtherSectorsSubSectors(
                    setQ4NoneFromOtherSectors,
                    setDisplayNoneFromOtherSectorsSubSectors
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Production, transformation et distribution de denrées alimentaires{" "}
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="fournisseurs numériques"
                checked={Q3WhatOtherSectorsOfActivity.includes(
                  "fournisseurs numériques"
                )}
                onChange={(e) =>
                  answersManagementForQ3OtherSectorsOfActivity(
                    e,
                    Q3WhatOtherSectorsOfActivity,
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors
                  )
                }
                onClick={() => {
                  resetNoneFromOtherSectorsSubSectors(
                    setQ4NoneFromOtherSectors,
                    setDisplayNoneFromOtherSectorsSubSectors
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Fournisseurs numériques
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="recherche"
                checked={Q3WhatOtherSectorsOfActivity.includes("recherche")}
                onChange={(e) =>
                  answersManagementForQ3OtherSectorsOfActivity(
                    e,
                    Q3WhatOtherSectorsOfActivity,
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors
                  )
                }
                onClick={() => {
                  resetNoneFromOtherSectorsSubSectors(
                    setQ4NoneFromOtherSectors,
                    setDisplayNoneFromOtherSectorsSubSectors
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Recherche
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="fabrication"
                checked={Q3WhatOtherSectorsOfActivity.includes("fabrication")}
                onChange={(e) =>
                  answersManagementForQ3OtherSectorsOfActivity(
                    e,
                    Q3WhatOtherSectorsOfActivity,
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors
                  )
                }
                onClick={() => {
                  setDisplayManufacturingSubSectors(
                    !displayManufacturingSubSectors
                  );
                  resetNoneFromOtherSectorsSubSectors(
                    setQ4NoneFromOtherSectors,
                    setDisplayNoneFromOtherSectorsSubSectors
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Fabrication
            </label>
            {displayManufacturingSubSectors && (
              <ManufacturingSubSectors
                setDisplayQ4WhatSizeAndRevenue={setDisplayQ4WhatSizeAndRevenue}
              />
            )}
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="aucun de la liste"
                checked={Q3WhatOtherSectorsOfActivity.includes(
                  "aucun de la liste"
                )}
                onChange={(e) => {
                  answersManagementForQ3OtherSectorsOfActivity(
                    e,
                    Q3WhatOtherSectorsOfActivity,
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors
                  );
                  resetManufacturingSubSectors();
                }}
                onClick={() => {
                  setDisplayNoneFromOtherSectorsSubSectors(
                    !displayNoneFromOtherSectorsSubSectors
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Aucun de la liste
            </label>
            {displayNoneFromOtherSectorsSubSectors && (
              <NoneFromOtherSectorsSubSectors />
            )}
          </div>
        </div>
      </div>

      {displayQ4WhatSizeAndRevenu ? (
        <Q4WhatSizeAndRevenue />
      ) : (
        <div
          className="nis2-validation-button-container"
          onClick={() => answersAnlaysis()}
        >
          <div className="nis2-validation-button-text">SUIVANT</div>
          <FaLongArrowAltRight className="nis2-validation-button-arrow" />
        </div>
      )}

      {displayThanksPopUp && <ThanksForUsingOurFormAndSubmit />}
      {displayGiveAtLeastOneAnswer && (
        <GiveAtLeastOneAnswer
          setDisplayGiveAtLeastOneAnswer={setDisplayGiveAtLeastOneAnswer}
          answerType={answerType}
        />
      )}
    </>
  );
};

export default Q3WhatOthersSectorsOfActivity;
