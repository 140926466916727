import React from "react";
import { BsDot } from "react-icons/bs";

const StrongPasswordInstructionsV3 = () => {
  return (
    <>
      <div className="text-left font-dark-blue1 font-size-075rem margin-bottom-20px ">
        <b>Recommandations pour un mot de passe fort :</b>
        <ul className="text-left">
          <li className="font-dark-blue1 font-size-075rem">
            <BsDot /> 12 caractères minimum
          </li>
          <li className="font-dark-blue1 font-size-075rem">
            <BsDot /> des minuscules
          </li>
          <li className="font-dark-blue1 font-size-075rem">
            <BsDot /> des majuscules
          </li>
          <li className="font-dark-blue1 font-size-075rem">
            <BsDot /> des chiffres
          </li>
          <li className="font-dark-blue1 font-size-075rem">
            <BsDot /> des caractères spéciaux parmi :<br />
            <span className="font-weight-bold font-dark-blue1">
              {
                " ! \" # $ % & ' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _ ` { | } ~ "
              }
            </span>
          </li>
          <li className="font-dark-blue1 font-size-075rem">
            <BsDot /> exemple : gedEoN#6471&
          </li>
        </ul>
      </div>
    </>
  );
};

export default StrongPasswordInstructionsV3;
