import { atom } from "jotai";

export const preauditAnswersAtom = atom();
export const preauditProgressAtom = atom();
export const isPreauditModifiedAtom = atom(false);
export const firstTimeAccessAtom = atom(true);
export const preauditRadarOptionsAtom = atom({
  // In order to display a 0 as mininum value and a 10 as maximum value in the radar, it's needed to create a MIN dataset and a MAX dataset in addition to the user audit, for lisibility purpose only.
  // Otherwise the chart show datas between the minimal rating and the maximal rating input by the user, which are not necessarily 0 and 10.
  scales: {
    radial: {
      pointLabels: {
        color: "rgba(83, 155, 255)",
        font: {
          size: 12.5,
          weight: "bold",
        },
      },
      ticks: {
        color: "$white-full-opacity",
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      anchor: "start",
      align: "start",
      color: "rgba(35, 34, 51)",
      font: {
        size: 10,
        weight: "bold",
      },
    },
  },
});

export const preauditBarOptionsAtom = atom({
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      labels: {
        font: {
          size: 20,
        },
      },
    },
    title: {
      display: false,
      text: "Chart.js Horizontal Bar Chart",
    },
  },
});
