import React from "react";
import { cleanInput } from "../../../../../utils/functions/Version3/functionsForFormsInputsManagement";
import { addNewSupplierForCurrentUser } from "../../../../../utils/functions/Version3/functionsForSuppliersManagement";
import { getAuthUserSuppliersList } from "../../../../../utils/functions/Version3/functionsForSuppliersManagement";

const AddSupplierForm = ({
  setDisplayAddSupplierForm,
  setSuppliersListing,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const supplierName = cleanInput(e.target.supplierName.value);
    const supplierEmail = cleanInput(e.target.supplierEmail.value);
    const supplierEmailConfirmation = cleanInput(
      e.target.supplierEmailConfirmation.value
    );

    if (supplierEmail === supplierEmailConfirmation) {
      addNewSupplierForCurrentUser(
        supplierName,
        supplierEmail,
        getAuthUserSuppliersList,
        setSuppliersListing,
        setDisplayAddSupplierForm
      );
    } else {
      alert(
        "Les adresses email ne correspondent pas, merci de vérifier votre saisie."
      );
    }
  };

  const handlePaste = (e) => {
    // Disable the pasting for the supplierEmailConfirmation.
    e.preventDefault();
  };

  return (
    <>
      <div id="add-supplier-form-modal">
        <form id="add-supplier-form" onSubmit={(e) => handleSubmit(e)}>
          <h3 className="font-dark-blue1 margin-bottom-5vh">
            NOUVEAU FOURNISSEUR
          </h3>

          {/* <div className="new-supplier-fields-container"> */}
          {/* <div className="add-supplier-form-label-container">
            <label htmlFor="supplierName" className="add-supplier-form-label">
              <span className="font-dark-blue1 font-weight-bold">
                Nom du fournisseur
              </span>
            </label>
          </div> */}

          <div className="add-supplier-form-input-container">
            <input
              required
              maxLength="100"
              type="text"
              name="supplierName"
              className="add-supplier-form-input"
              placeholder="Nom du fournisseur"
            />
          </div>
          {/* </div> */}

          {/* <div className="new-supplier-fields-container"> */}
          {/* <div className="add-supplier-form-label-container">
            <label htmlFor="supplierEmail" className="add-supplier-form-label">
              <span className="font-dark-blue1 font-weight-bold">
                Adresse e-mail du fournisseur{" "}
              </span>
            </label>
          </div> */}

          <div className="add-supplier-form-input-container">
            <input
              required
              maxLength="100"
              type="email"
              name="supplierEmail"
              className="add-supplier-form-input"
              placeholder="Adresse email du fournisseur"
              onPaste={(e) => handlePaste(e)}
            />
          </div>
          {/* </div> */}

          {/* <div className="new-supplier-fields-container"> */}
          {/* <div className="add-supplier-form-label-container">
            <label htmlFor="supplierEmail" className="add-supplier-form-label">
              <span className="font-dark-blue1 font-weight-bold">
                Confirmez l'adresse e-mail{" "}
              </span>
            </label>
          </div> */}

          <div className="add-supplier-form-input-container">
            <input
              required
              maxLength="100"
              type="email"
              name="supplierEmailConfirmation"
              className="add-supplier-form-input"
              placeholder="Confirmer l'adresse email"
              onPaste={(e) => handlePaste(e)}
            />
          </div>
          {/* </div> */}

          <div id="add-supplier-form-submit-buttons-container">
            <div>
              <button type="submit" id="add-supplier-form-submit-button">
                AJOUTER
              </button>
            </div>
            <div>
              <button
                id="add-supplier-form-cancel-button"
                onClick={() => setDisplayAddSupplierForm(false)}
              >
                ANNULER
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddSupplierForm;
