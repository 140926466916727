import React from "react";
import { signOut } from "../../../../utils/functions/Version3/functionsForUserSessionManagement";
import { FaPowerOff, FaUserCircle } from "react-icons/fa";
import { ImStatsBars2 } from "react-icons/im";

const MenuClassic = ({
  isUserInformationsSelected,
  isDashboardSelected,
  navigate,
  selectedTool,
  setSelectedTool,
}) => {
  return (
    <>
      <div id="navbar-buttons-container">
        <div
          className={`navbar-button-container ${
            isUserInformationsSelected && "navbar-button-is-selected"
          }`}
          onClick={() => navigate("/user-informations")}
        >
          {" "}
          <button className="navbar-button">
            <FaUserCircle />
          </button>
        </div>

        <div
          className={`navbar-button-container ${
            isDashboardSelected && "navbar-button-is-selected"
          }`}
          onClick={() => navigate("/dashboard")}
        >
          <button className="navbar-button">
            <ImStatsBars2 />
          </button>
        </div>

        <div id="navbar-button-logout-container">
          <button
            id="navbar-button-logout"
            onClick={() => signOut(navigate, selectedTool, setSelectedTool)}
          >
            <FaPowerOff />
          </button>
        </div>
      </div>
    </>
  );
};

export default MenuClassic;
