import React, { useState } from "react";
import { JSONNIS2Questions } from "../../../../utils/JSON/NIS2FormQuestions";
import {
  Q3WhatOtherSectorsOfActivityAtom,
  organisationNIS2StatusAtom,
  Q6WhatActivitiesAtom,
  Q2WhatSectorsOfActivityAtom,
  NIS2StatusAtom,
} from "../../../../atoms/NIS2Atom";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import { answersManagementForQ6WhatActivities } from "../../../../utils/functions/Version3/functionsForNIS2Management";
import ThanksForUsingOurFormAndSubmit from "./PopUps/ThanksForUsingOurFormAndSubmit";
import GiveAtLeastOneAnswer from "./PopUps/GiveAtLeastOneAnswer";
import { FaLongArrowAltRight } from "react-icons/fa";

const Q6WhatActivities = () => {
  const NIS2Status = useAtomValue(NIS2StatusAtom);

  const setOrganisationNIS2Status = useSetAtom(organisationNIS2StatusAtom);

  const [Q6WhatActivities, setQ6WhatActivities] = useAtom(Q6WhatActivitiesAtom);

  const Q2WhatSectorsOfActivity = useAtomValue(Q2WhatSectorsOfActivityAtom);

  const Q3WhatOtherSectorsOfActivity = useAtomValue(
    Q3WhatOtherSectorsOfActivityAtom
  );

  const [
    displayNoneFromOtherSectorsSubSectors,
    setDisplayNoneFromOtherSectorsSubSectors,
  ] = useState(false);

  const [displayThanksPopUp, setDisplayThanksPopUp] = useState(false);
  const [displayGiveAtLeastOneAnswer, setDisplayGiveAtLeastOneAnswer] =
    useState(false);
  const [answerType, setAnswerType] = useState(null);

  const answersAnlaysis = () => {
    // Analyse user's answers before running next step :
    if (isFormValid()) {
      // 1 - Case : the organisation is an "Entité exclue"
      if (
        Q6WhatActivities.length >= 1 &&
        !Q6WhatActivities.includes("aucun de la liste")
      ) {
        setOrganisationNIS2Status(NIS2Status[0]);
        setDisplayThanksPopUp(true);
      }
      // 2 - Case : the organisation is an "Entité essentielle"
      else if (
        Q6WhatActivities.length === 1 &&
        Q6WhatActivities[0] === "aucun de la liste" &&
        Q2WhatSectorsOfActivity.length >= 1 &&
        !Q2WhatSectorsOfActivity.includes("aucun de la liste")
      ) {
        setOrganisationNIS2Status(NIS2Status[1]);
        setDisplayThanksPopUp(true);
      }
      // 3 - Case : the organisation is an "Entité importante"
      else if (
        Q6WhatActivities.length === 1 &&
        Q6WhatActivities[0] === "aucun de la liste" &&
        Q3WhatOtherSectorsOfActivity.length >= 1 &&
        Q2WhatSectorsOfActivity.includes("aucun de la liste")
      ) {
        setOrganisationNIS2Status(NIS2Status[2]);
        setDisplayThanksPopUp(true);
      }
    }
  };

  const isFormValid = () => {
    if (Q6WhatActivities.length === 0) {
      setDisplayGiveAtLeastOneAnswer(true);
      setAnswerType("poursuivre");
      return false;
    } else return true;
  };

  return (
    <>
      <div id="Q6WhatActivities" className="nis2form-question-container">
        <div className="nis2form-question-content">
          {JSONNIS2Questions[5].content}
        </div>
        <div id="q2-sectors-of-activity-container">
          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="la sécurité nationale"
                checked={Q6WhatActivities.includes("la sécurité nationale")}
                onChange={(e) =>
                  answersManagementForQ6WhatActivities(
                    e,
                    Q6WhatActivities,
                    setQ6WhatActivities
                  )
                }
                onClick={() => {}}
              />
              La sécurité nationale
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="la sécurité publique"
                checked={Q6WhatActivities.includes("la sécurité publique")}
                onChange={(e) =>
                  answersManagementForQ6WhatActivities(
                    e,
                    Q6WhatActivities,
                    setQ6WhatActivities
                  )
                }
                onClick={() => {}}
              />
              La sécurité publique
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="la défense"
                checked={Q6WhatActivities.includes("la défense")}
                onChange={(e) =>
                  answersManagementForQ6WhatActivities(
                    e,
                    Q6WhatActivities,
                    setQ6WhatActivities
                  )
                }
                onClick={() => {}}
              />
              La défense
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="l'application de la loi"
                checked={Q6WhatActivities.includes("l'application de la loi")}
                onChange={(e) =>
                  answersManagementForQ6WhatActivities(
                    e,
                    Q6WhatActivities,
                    setQ6WhatActivities
                  )
                }
                onClick={() => {}}
              />
              L'application de la loi, y compris la prévention et la détection
              des infractions pénales, ainsi que les enquêtes et les poursuites
              en la matière
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="aucun de la liste"
                checked={Q6WhatActivities.includes("aucun de la liste")}
                onChange={(e) => {
                  answersManagementForQ6WhatActivities(
                    e,
                    Q6WhatActivities,
                    setQ6WhatActivities
                  );
                }}
                onClick={() => {
                  setDisplayNoneFromOtherSectorsSubSectors(
                    !displayNoneFromOtherSectorsSubSectors
                  );
                }}
              />
              Aucun de la liste
            </label>
          </div>
        </div>
      </div>

      <div
        className="nis2-validation-button-container"
        onClick={() => answersAnlaysis()}
      >
        <div className="nis2-validation-button-text">SUIVANT</div>
        <FaLongArrowAltRight className="nis2-validation-button-arrow" />
      </div>

      {displayThanksPopUp && <ThanksForUsingOurFormAndSubmit />}
      {displayGiveAtLeastOneAnswer && (
        <GiveAtLeastOneAnswer
          setDisplayGiveAtLeastOneAnswer={setDisplayGiveAtLeastOneAnswer}
          answerType={answerType}
        />
      )}
    </>
  );
};

export default Q6WhatActivities;
