import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

// All these lines are related to conditionnal rendering purpose in the menus.

// Tell what tool is selected.
export const selectedToolAtom = atomWithStorage("selectedTool", "no-selection");

// Tell for each tool if it's selected or not.
export const isPreauditSelectedAtom = atomWithStorage(
  "isPreauditSelected",
  false
);

export const isSupplierAssesmentSelectedAtom = atomWithStorage(
  "isSupplierAssesmentSelected",
  false
);

export const isAuditRGPDSelectedAtom = atomWithStorage(
  "isAuditRGPDSelected",
  false
);

export const isBackstageSelectedAtom = atomWithStorage(
  "isBackstageSelected",
  false
);

// Tell what menu is selected from the sidebar menu.
export const isUserInformationSelectedAtom = atomWithStorage(
  "isUserInformationSelected",
  false
);

export const isDashboardSelectedAtom = atom(false);

export const isPreauditDashboardSelectedAtom = atomWithStorage(
  "isPreauditDashboardSelected",
  false
);
export const isPreauditQuestionsSelectedAtom = atomWithStorage(
  "isPreauditQuestionsSelected",
  false
);

export const isSupplierAssesmentDashboardSelectedAtom = atomWithStorage(
  "isSupplierAssesmentDashboardSelected",
  false
);

export const isSupplierAssesmentQuestionsSelectedAtom = atomWithStorage(
  "isSupplierAssesmentQuestionsSelected",
  false
);

export const displaySupplierAssesmentQuestionsThemesAtom = atomWithStorage(
  "displaySupplierAssesmentQuestionsThemes",
  false
);

export const isAuditRGPDDashboardSelectedAtom = atomWithStorage(
  "isAuditRGPDDashboardSelected",
  false
);

export const isAuditRGPDQuestionsSelectedAtom = atomWithStorage(
  "isAuditRGPDQuestionsSelected",
  false
);
