import React, { useState } from "react";
import { JSONNIS2Questions } from "../../../../utils/JSON/NIS2FormQuestions";
import {
  Q3WhatOtherSectorsOfActivityAtom,
  organisationNIS2StatusAtom,
  Q4WhatSizeAndRevenueAtom,
  Q2WhatSectorsOfActivityAtom,
  Q5WhichOnesAreCorrectAtom,
  displayQ5WhichOnesAreCorrectAtom,
  Q6WhatActivitiesAtom,
  displayQ6WhatActivitiesAtom,
  NIS2StatusAtom,
} from "../../../../atoms/NIS2Atom";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import Q5WhichOnesAreCorrect from "./Q5WhichOnesAreCorrect";
import {
  resetQ5WhichOnesAreCorrect,
  resetQ6WhatActivties,
} from "../../../../utils/functions/Version3/functionsForNIS2Management";
import ThanksForUsingOurFormAndSubmit from "./PopUps/ThanksForUsingOurFormAndSubmit";
import GiveAtLeastOneAnswer from "./PopUps/GiveAtLeastOneAnswer";
import { FaLongArrowAltRight } from "react-icons/fa";

const Q4WhatSizeAndRevenue = () => {
  const NIS2Status = useAtomValue(NIS2StatusAtom);

  const setOrganisationNIS2Status = useSetAtom(organisationNIS2StatusAtom);
  const [Q4WhatSizeAndRevenue, setQ4WhatSizeAndRevenue] = useAtom(
    Q4WhatSizeAndRevenueAtom
  );

  const Q2WhatSectorsOfActivity = useAtomValue(Q2WhatSectorsOfActivityAtom);

  const Q3WhatOtherSectorsOfActivity = useAtomValue(
    Q3WhatOtherSectorsOfActivityAtom
  );

  const setQ5WhichOnesAreCorrect = useSetAtom(Q5WhichOnesAreCorrectAtom);

  const [displayQ5WhichOnesAreCorrect, setDisplayQ5WhichOnesAreCorrect] =
    useAtom(displayQ5WhichOnesAreCorrectAtom);

  const setQ6WhatActivities = useSetAtom(Q6WhatActivitiesAtom);

  const setDisplayQ6WhatActivities = useSetAtom(displayQ6WhatActivitiesAtom);

  const [displayThanksPopUp, setDisplayThanksPopUp] = useState(false);
  const [displayGiveAtLeastOneAnswer, setDisplayGiveAtLeastOneAnswer] =
    useState(false);
  const [answerType, setAnswerType] = useState(null);

  const answersAnlaysis = () => {
    if (Q4WhatSizeAndRevenue.length === 0) {
      setDisplayGiveAtLeastOneAnswer(true);
      setAnswerType("poursuivre");
    }

    if (
      Q4WhatSizeAndRevenue === "oui" &&
      Q2WhatSectorsOfActivity.length >= 1 &&
      !Q2WhatSectorsOfActivity.includes("aucun de la liste")
    ) {
      setOrganisationNIS2Status(NIS2Status[1]);
      setDisplayThanksPopUp(true);
    }

    if (
      Q4WhatSizeAndRevenue === "oui" &&
      Q3WhatOtherSectorsOfActivity.length >= 1 &&
      Q2WhatSectorsOfActivity.includes("aucun de la liste")
    ) {
      setOrganisationNIS2Status(NIS2Status[2]);
      setDisplayThanksPopUp(true);
    }

    if (Q4WhatSizeAndRevenue === "non") {
      setDisplayQ5WhichOnesAreCorrect(true);
      window.scrollBy({
        top: 200,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div id="Q4WhatSizeAndRevenue" className="nis2form-question-container">
        <div className="nis2form-question-content">
          {JSONNIS2Questions[3].content}
        </div>
        <div id="q2-sectors-of-activity-container">
          <div className="nis2form-answers-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="radio"
                value="oui"
                checked={Q4WhatSizeAndRevenue === "oui"}
                onChange={(e) => setQ4WhatSizeAndRevenue(e.target.value)}
                onClick={() => {
                  setDisplayQ5WhichOnesAreCorrect(false);
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Oui
            </label>
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="radio"
                value="non"
                checked={Q4WhatSizeAndRevenue === "non"}
                onChange={(e) => setQ4WhatSizeAndRevenue(e.target.value)}
                onClick={() => {
                  setDisplayQ5WhichOnesAreCorrect(false);
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Non
            </label>
          </div>
        </div>
      </div>

      {displayQ5WhichOnesAreCorrect ? (
        <Q5WhichOnesAreCorrect />
      ) : (
        <div
          className="nis2-validation-button-container"
          onClick={() => answersAnlaysis()}
        >
          <div className="nis2-validation-button-text">SUIVANT</div>
          <FaLongArrowAltRight className="nis2-validation-button-arrow" />
        </div>
      )}

      {displayThanksPopUp && <ThanksForUsingOurFormAndSubmit />}
      {displayGiveAtLeastOneAnswer && (
        <GiveAtLeastOneAnswer
          setDisplayGiveAtLeastOneAnswer={setDisplayGiveAtLeastOneAnswer}
          answerType={answerType}
        />
      )}
    </>
  );
};

export default Q4WhatSizeAndRevenue;
