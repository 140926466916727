// Functions to SEND an assesment link to supplier
import Cookies from "js-cookie";

export const addNewSupplierForCurrentUser = (
  supplierName,
  supplierEmail,
  getAuthUserSuppliersList,
  setSuppliersListing,
  setDisplayAddSupplierForm
) => {
  fetch(process.env.REACT_APP_BACKEND_URL + "suppliers", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: Cookies.get(process.env.REACT_APP_COOKIES_TOKEN_SESSION),
    },
    body: JSON.stringify({
      supplier: { supplier_name: supplierName, supplier_email: supplierEmail },
    }),
  })
    .then((response) => {
      // console.log("RESPONSE from addNewSupplierForCurrentUser :\n", response);
      response.status !== 200 &&
        // ? alert(
        //     `${supplierName} a bien été ajouté à la liste de vos fournisseurs.`
        //   )
        alert(
          `Opération non réalisée : écrivez à ${process.env.REACT_APP_ACT_MAIL_CONTACT} si le problème persiste.`
        );
      return response.json();
    })
    .then(() => {
      setDisplayAddSupplierForm(false);
      getAuthUserSuppliersList(setSuppliersListing);
    });
  // )
  // .catch(error => console.error("askResetInstructions -> ERROR", error.message))
};

// function to GET a list of all authenticated user's suppliers
export const getAuthUserSuppliersList = (setSuppliersListing) => {
  fetch(process.env.REACT_APP_BACKEND_URL + "get_current_user_suppliers", {
    method: "GET",
    headers: {
      Authorization: Cookies.get(process.env.REACT_APP_COOKIES_TOKEN_SESSION),
    },
  })
    .then((response) => {
      // console.log("RESPONSE from getAuthUserSuppliersList :\n", response);
      return response.json();
    })
    .then((data) => {
      // console.log("DATA from getAuthUserSuppliersList :\n", data);
      setSuppliersListing(data);
    });
};

// Function to UPDATE supplier informations backend
export const saveSupplierInformationsInBackend = (
  data,
  supplierId,
  setSelectedTool,
  setDisplayAssesmentQuestions
) => {
  fetch(
    process.env.REACT_APP_BACKEND_URL +
      `suppliers/update_from_supplier_assesment_token/${supplierId}`,
    {
      method: "PUT",
      headers: {
        Authorization: Cookies.get(process.env.REACT_APP_COOKIES_TOKEN_SESSION),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        supplier: data,
      }),
    }
  )
    .then((response) => {
      // console.log(
      //   "RESPONSE from saveSupplierInformationsInBackend :\n",
      //   response
      // );
      response.status === 200
        ? alert("Les informations ont bien été mises à jour.")
        : alert(
            `Opération non réalisée : écrivez à ${process.env.REACT_APP_ACT_MAIL_CONTACT} si le problème persiste.`
          );

      // return response.json();
    })
    .then((data) => {
      // console.log("DATA from saveSupplierInformationsInBackend :\n", data);
      setSelectedTool("supplier-assesment-for-suppliers");
      setDisplayAssesmentQuestions(true);
    });
  // .catch(error => console.error("backendUpdate #members -> ERROR\n", error.message))
};
