export const JSONPreaudit = [
    {
        id: "A", 
        theme : "politique de sécurité de l'info",
        content : "Avez-vous mis en œuvre une politique de sécurité de l’information dans votre entreprise ?"
        
    },

    {
        id: "B", 
        theme : "rôles et responsabilités",
        content : "Avez-vous identifié les rôles et les responsabilités en matière de sécurité dans votre entreprises ? (Il faut définir les rôles et les responsabilités en matière de sécurité de l'information et les attribuer en fonction des besoins de la société)."
        
    },
    

    {
        id: "C", 
        theme : "contact autorités",
        content : "Avez-vous un processus interne vous permettant de communiquer avec les autorités dans le cas d’une cyber attaque ?"
        
    },

    {
        id: "D", 
        theme : "classification",
        content : "Avez-vous mis en œuvre des moyens permettant la classification des données ? (Les informations doivent être classifiées en fonction des besoins de votre société en terme de confidentialité, d'intégrité et de disponibilité)."
        
    },
    
    {
        id: "E", 
        theme : "inventaires",
        content : "Avez-vous réalisé un inventaire des informations et des équipements ?"
        
    },
    

    {
        id: "F", 
        theme : "prêt des équipements",
        content : "Avez-vous rédigé un processus de prêt et de retour des équipements ?"
        
    },
    

    {
        id: "G", 
        theme : "destruction des données",
        content : "Avez-vous formalisé un processus de destruction des données pour les matériels obsolètes ?"
        
    },
    

    {
        id: "H", 
        theme : "droits d'accès",
        content : "Avez-vous  mis en place un système de contrôle des accès et de gestion des droits des employés de votre entreprise ? (L'attribution et l'utilisation de droits d'accès privilégiés doivent être limitées et gérées)."
        
    },

    {
        id: "I",
        theme : "codage",
        content : "Avez-vous mis en œuvre des principes de codage sécurisé lors du développement de vos logiciels et sites web ?"
    },

    {
        id: "J",
        theme : "protection",
        content : "Avez-vous mis en œuvre des moyens de protection de vos systèmes d'information ?"
    },

    {

        id: "K",
        theme : "séparation des usages",
        content : "Avez-vous mis en œuvre des moyens de séparation de vos environnements de développement, de test et de production ?"
    },

    {
        id: "L",
        theme : "redondance",
        content : "Avez-vous mis en œuvre des moyens permettant la redondance des installations de traitement de l'information (PRA et PCA) ?"
    },

    {
        id: "M",
        theme : "audits et pentests",
        content : "Avez-vous fait des audits de vos vulnérabilités et des tests d’intrusion ?"
    },

    {
        id: "N",
        theme : "sensibilisation du personnel",
        content : "Avez-vous mis en œuvre un programme de sensibilisation des employés à la sécurité et des campagnes de faux phishing ?"
    },
    {
        id: "O",
        theme : "matériel hors-site",
        content : "Avez-vous mis en œuvre des moyens permettant de gérer la sécurité du matériel situé en dehors des locaux ? (Le matériel hors-site doit être protégé.)"
    },

    {
        id: "P",
        theme : "chiffrement",
        content : "Avez-vous mis en œuvre des moyens de chiffrement et contre la fuite des données personnelles et sensibles ?"
    },
    {
        id: "Q",
        theme : "surveillance des systèmes",
        content : "Avez-vous mis en œuvre des moyens de surveillance de vos systèmes et de vos applications contre tout comportement anormal ? (il faut pouvoir gérer les évènements et incidents de sécurité)"
    },

    {
        id: "R",
        theme : "journalisation",
        content : "Avez-vous mis en œuvre des moyens permettant de journaliser les activités, les fautes ou tous les autres évènements liés à des malversations ?"
    },

    {
        id: "S",
        theme : "gestion des risques",
        content : "Avez-vous mis en œuvre une gestion des risques dans votre entreprise ?"
    },

    {
        id: "T",
        theme : "accompagnement",
        content : "Avez-vous mis en œuvre un accompagnement sécurité dans les projets ?"
    }
    
    
]