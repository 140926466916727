import React from "react";
import {
  auditRGPDAnswersAtom,
  auditRGPDCommentsAtom,
  auditRGPDProgressAtom,
} from "../../../../../atoms/AuditRGPDAtom ";
import { useAtom, useSetAtom } from "jotai";

const AuditRGPDQuestionTemplate = ({ question, setIsAuditRGPDmodified }) => {
  const [auditRGPDAnswers, setAuditRGPDAnswers] = useAtom(auditRGPDAnswersAtom);
  const [auditRGPDComments, setAuditRGPDComments] = useAtom(
    auditRGPDCommentsAtom
  );

  const setProgress = useSetAtom(auditRGPDProgressAtom);

  const updateAnswer = (e) => {
    const selectedValue = e.target.value;

    setAuditRGPDAnswers((prevAnswers) => ({
      ...prevAnswers,
      [`Q${question.id}Answer`]: selectedValue,
    }));

    setIsAuditRGPDmodified(true);

    setProgress((prevProgress) => ({ ...prevProgress, [question.id]: true }));
  };

  const updateComment = (e) => {
    e.preventDefault();

    setAuditRGPDComments({
      ...auditRGPDComments,
      [`Q${question.id}Comment`]: e.target.value,
    });

    setIsAuditRGPDmodified(true);
    setProgress((prevProgress) => ({ ...prevProgress, [question.id]: true }));
  };

  return (
    <>
      <div className="audit-rgpd-single-question-container">
        <div className="audit-rgpd-single-question-header">
          <div className="audit-rgpd-question-id-container">
            Question {question.id}
          </div>
        </div>
        <div className="audit-rgpd-question-content-container">
          {question.content}
        </div>

        <div className="audit-rgpd-answer-container">
          <div className="audit-rgpd-input-container">
            <input
              type="radio"
              id={`Q${question.id}-oui`}
              name={`Q${question.id}Answer`}
              value="oui"
              className="cursor-pointer"
              checked={
                auditRGPDAnswers &&
                auditRGPDAnswers[`Q${question.id}Answer`] === "oui"
                  ? true
                  : false
              }
              onChange={(e) => updateAnswer(e)}
            />
            <label
              htmlFor={`Q${question.id}-oui`}
              className="audit-rgpd-input-label"
            >
              oui
            </label>
          </div>

          <div className="audit-rgpd-input-container">
            <input
              type="radio"
              id={`Q${question.id}-non`}
              name={`Q${question.id}Answer`}
              value="non"
              className="cursor-pointer"
              checked={
                auditRGPDAnswers &&
                auditRGPDAnswers[`Q${question.id}Answer`] === "non"
                  ? true
                  : false
              }
              onChange={(e) => updateAnswer(e)}
            />
            <label
              htmlFor={`Q${question.id}-non`}
              className="audit-rgpd-input-label"
            >
              non
            </label>
          </div>

          <div className="audit-rgpd-input-container">
            <input
              type="radio"
              id={`Q${question.id}-NA`}
              name={`Q${question.id}Answer`}
              value="NA"
              className=" cursor-pointer"
              checked={
                auditRGPDAnswers &&
                auditRGPDAnswers[`Q${question.id}Answer`] === "NA"
                  ? true
                  : false
              }
              onChange={(e) => updateAnswer(e)}
            />
            <label
              htmlFor={`Q${question.id}-NA`}
              className="audit-rgpd-input-label"
            >
              non applicable / je ne sais pas
            </label>
          </div>
        </div>

        <div className="audit-rgpd-comment-container">
          <div>
            <span className="audit-rgpd-comment-label">Commentaire :</span>
          </div>
          <div>
            <textarea
              name={`Q${question.id}Comment`}
              onChange={(e) => updateComment(e)}
              className="audit-rgpd-comment-input"
              placeholder={
                auditRGPDComments && auditRGPDComments[`Q${question.id}Comment`]
              }
            />
          </div>
        </div>
      </div>

      {/* <div className="audit-rgpd-question-container">
        <div className="audit-rgpd-question-id-container">
          <span className="font-weight-bold font-size-1-25rem">
            Question {question.id}
          </span>
        </div>

        <div className="audit-rgpd-question-content-container">
          <span className="font-weight-normal">{question.content}</span>
        </div>

        <div className="audit-rgpd-inputs-container">
          <div className="audit-rgpd-answer-container">
            <div className="">
              <input
                type="radio"
                id={`Q${question.id}-oui`}
                name={`Q${question.id}Answer`}
                value="oui"
                className="cursor-pointer"
                checked={
                  auditRGPDAnswers &&
                  auditRGPDAnswers[`Q${question.id}Answer`] === "oui"
                    ? true
                    : false
                }
                onChange={(e) => updateAnswer(e)}
              />
              <label
                htmlFor={`Q${question.id}-oui`}
                className="margin-left-0-5vw cursor-pointer"
              >
                oui
              </label>
            </div>

            <div className="">
              <input
                type="radio"
                id={`Q${question.id}-non`}
                name={`Q${question.id}Answer`}
                value="non"
                className=" cursor-pointer"
                checked={
                  auditRGPDAnswers &&
                  auditRGPDAnswers[`Q${question.id}Answer`] === "non"
                    ? true
                    : false
                }
                onChange={(e) => updateAnswer(e)}
              />
              <label
                htmlFor={`Q${question.id}-non`}
                className="margin-left-0-5vw cursor-pointer"
              >
                non
              </label>
            </div>

            <div className="">
              <input
                type="radio"
                id={`Q${question.id}-NA`}
                name={`Q${question.id}Answer`}
                value="NA"
                className=" cursor-pointer"
                checked={
                  auditRGPDAnswers &&
                  auditRGPDAnswers[`Q${question.id}Answer`] === "NA"
                    ? true
                    : false
                }
                onChange={(e) => updateAnswer(e)}
              />
              <label
                htmlFor={`Q${question.id}-NA`}
                className="margin-left-0-5vw cursor-pointer"
              >
                non applicable / je ne sais pas
              </label>
            </div>
          </div>

          <div className="audit-rgpd-comment-container">
            <div>
              <span className="font-weight-normal font-size-1-25rem font-family-designosaur">
                Commentaire
              </span>
            </div>
            <div>
              <textarea
                name={`Q${question.id}Comment`}
                onInput={(e) => updateComment(e)}
                className="audit-rgpd-input"
                placeholder={
                  auditRGPDComments &&
                  auditRGPDComments[`Q${question.id}Comment`]
                }
              />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AuditRGPDQuestionTemplate;
