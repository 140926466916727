import React from "react";
import { Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import PleaseConnect from "./components/PleaseConnect";

const ProtectedRoutesV3 = () => {
  const isSessionOpen = Cookies.get(
    process.env.REACT_APP_COOKIES_TOKEN_SESSION
  );

  return <>{isSessionOpen ? <Outlet /> : <PleaseConnect />}</>;
};

export default ProtectedRoutesV3;
