import React from "react";
import { parsePhoneNumber } from "../../../../../utils/functions/Version3/functionsForFormsInputsManagement";
import { MdOutlineAlternateEmail, MdPhone } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { IoLocation } from "react-icons/io5";

const SupplierDetails = ({ supplier }) => {
  return (
    <>
      <div
        id="supplier-details-container"
        className="supplier-details-animation"
      >
        <ul>
          <li>SIRET {supplier.siret ? supplier.siret : "-"}</li>

          <li>
            <MdOutlineAlternateEmail /> {supplier.email}
          </li>
          <li>
            <MdPhone />{" "}
            {supplier.phone ? parsePhoneNumber(supplier.phone) : "-"}
          </li>
          <li>
            <IoLocation /> {supplier.address ? supplier.address : "-"}
          </li>
          <li>
            <FaUserTie /> {supplier.contact ? supplier.contact : "-"}
          </li>
        </ul>
      </div>
    </>
  );
};

export default SupplierDetails;
