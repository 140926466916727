import React, { useEffect, useState } from "react";
import { getSupplierAssesmentsListing } from "../../../../../utils/functions/Version3/functionsForSupplierAssesmentManagement";
import SupplierAssesmentSummaryTemplate from "./SupplierAssesmentSummaryTemplate";
import { nanoid } from "nanoid";

const SupplierAssesmentListing = ({
  supplier,
  setDisplaySupplierAssesmentsListing,
}) => {
  const [supplierAssesmentsListing, setSupplierAssesmentsListing] = useState();

  useEffect(() => {
    getSupplierAssesmentsListing(supplier.id, setSupplierAssesmentsListing);
  }, [supplier.id]);

  return (
    <>
      {/* <div id="supplier-assesments-listing-modal"> */}
      <div
        id="supplier-assesments-listing-container"
        className="supplier-listing-animation"
      >
        {/* <div className="">
            <span className="">
              ÉVALUATIONS DE {supplier.organization}
            </span>
          </div> */}

        {supplierAssesmentsListing &&
          supplierAssesmentsListing.length === 0 && (
            <>Aucune évaluation réalisée</>
          )}

        <ul>
          {supplierAssesmentsListing &&
            supplierAssesmentsListing.length >= 0 &&
            supplierAssesmentsListing.map((supplierAssesment) => (
              <SupplierAssesmentSummaryTemplate
                key={nanoid()}
                supplierAssesment={supplierAssesment}
              />
            ))}
        </ul>
      </div>
      {/* </div> */}
    </>
  );
};

export default SupplierAssesmentListing;
