import React, { useEffect, useState } from "react";
import { supplierAudit } from "../../../../utils/JSON/supplierAssesmentQuestions";
import { useParams } from "react-router-dom";
import { useAtomValue } from "jotai";
import {
  supplierAssesmentToUpdateIdAtom,
  supplierAssesmentToUpdateTokenAtom,
} from "../../../../atoms/SupplierAssesmentAtom";
import { FaChartPie } from "react-icons/fa";
import SupplierAssesmentQuestionTemplate from "./SupplierAssesmentQuestionTemplate";
import { BsArrowUpCircleFill } from "react-icons/bs";

const SupplierAssesmentQuestions = () => {
  const supplierAssesmentIdToUpdate = useAtomValue(
    supplierAssesmentToUpdateIdAtom
  );
  const supplierAssesmentTokenToUpdate = useAtomValue(
    supplierAssesmentToUpdateTokenAtom
  );

  let { theme } = useParams();
  const [parsedTheme, setParsedTheme] = useState("");

  useEffect(() => {
    const parseParams = () => {
      const indexFirstSpace = theme.indexOf(" ");
      const parsedString = theme.substring(indexFirstSpace + 1);
      setParsedTheme(parsedString);
    };

    parseParams();
  }, [theme, supplierAssesmentIdToUpdate, supplierAssesmentTokenToUpdate]);

  return (
    <>
      <div id="supplier-assesment-answering-container">
        <div className="page-title-container">
          <div className="page-title-icon-and-text">
            <div className="page-title-icon">
              <FaChartPie />
            </div>
            <div className="page-title-text">Évaluation de fournisseur</div>
          </div>
        </div>

        <div className="supplier-assesment-theme-container">
          <h2>{theme}</h2>
        </div>

        <div id="supplier-assesment-questions-container">
          {supplierAudit.map(
            (question) =>
              question.theme === parsedTheme && (
                <>
                  <SupplierAssesmentQuestionTemplate question={question} />
                </>
              )
          )}
        </div>
      </div>

      <div
        id="back-to-top-container"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <abbr title="Vers le haut de page">
          <BsArrowUpCircleFill size="2rem" color="white" />
        </abbr>
      </div>
    </>
  );
};

export default SupplierAssesmentQuestions;
