import React, { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import Tooltip from "./components/Tooltip";
import { authUserDetailsAtom } from "../../../atoms/AuthenticationAtom";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { parsePhoneNumber } from "../../../utils/functions/Version3/functionsForFormsInputsManagement";
import { IoMdInformationCircle } from "react-icons/io";
import { getAuthUserDetails } from "../../../utils/functions/Version3/functionsForUserInformationsManagement";
import twoMenClimbing from "../../../images/two-men-climbing.png";

const UserInformationsV3 = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [userDetails, setUserDetails] = useAtom(authUserDetailsAtom);
  // const areUserInformationsCompleted = useAtomValue(
  //   areUserInformationsCompletedAtom
  // );
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      // left: 0,
      behavior: "smooth",
    });

    // Need a short delay in order to be sure data are fetched before displaying them (otherwise data are not dsplay on PROD environement)
    setTimeout(() => {
      getAuthUserDetails(setUserDetails);
    }, 500);
  }, [setUserDetails]);

  return (
    <>
      <div className="user-informations-container">
        <div className="page-title-container">
          <div className="page-title-icon-and-text">
            <div className="page-title-icon">
              {" "}
              <FaUserCircle />
            </div>
            <div className="page-title-text">Vos informations</div>
          </div>
        </div>

        <div className="user-informations-request-container">
          <h1>Accédez au tableau de bord pour démarrer.</h1>
        </div>

        <div id="user-informations-buttons-container">
          <div
            className="user-informations-button"
            onClick={() => navigate("/user-informations-edition")}
          >
            ÉDITER
          </div>
          <div
            className="user-informations-button"
            onClick={() => navigate("/dashboard")}
          >
            TABLEAU DE BORD
          </div>
        </div>

        <div className="all-informations-container">
          <div className="single-information-container">
            <div className="information-label">Société </div>
            <div className="information-text">
              {userDetails && userDetails.organization}
            </div>
          </div>
          <div className="single-information-container">
            <div className="information-label">Utilisateur du compte </div>
            <div className="information-text">
              {userDetails && userDetails.contact}
            </div>
          </div>
          <div className="single-information-container">
            <div className="information-label">Téléphone </div>
            <div className="information-text">
              {userDetails && parsePhoneNumber(userDetails.phone)}
            </div>
          </div>
          <div className="single-information-container">
            <div className="information-label">Adresse </div>
            <div className="information-text">
              {userDetails && userDetails.address}
            </div>
          </div>
          <div className="single-information-container">
            <div className="information-label">SIRET </div>
            <div className="information-text">
              {userDetails && userDetails.siret}
            </div>
          </div>
          <div className="single-information-container">
            <div className="information-label">Email </div>
            <div className="information-text">
              {userDetails && userDetails.email}
            </div>
          </div>
          <div className="single-information-container">
            <div className="information-label">
              Être rappelé par un expert
              <IoMdInformationCircle
                color="#539bff"
                size="1.1rem"
                onClick={() => setShowTooltip(true)}
                className={"cursor-pointer"}
              />
            </div>
            <div className="information-text">
              {userDetails && userDetails.availability}
            </div>
          </div>
        </div>

        {showTooltip && <Tooltip setShowTooltip={setShowTooltip} />}
      </div>

      <div id="user-informations-illustration-container">
        <img
          src={twoMenClimbing}
          alt="illustration de cybersécurité"
          className="user-informations-illustration"
        />
      </div>
    </>
  );
};

export default UserInformationsV3;
