import React, { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import {
  cleanInput,
  generateCaptcha,
  validateCaptcha,
} from "../../../../utils/functions/Version3/functionsForFormsInputsManagement";
import { passwordStrength } from "check-password-strength";
import { signUp } from "../../../../utils/functions/Version3/functionsForUserSessionManagement";
// import StrongPasswordInstructionsV3 from "./StrongPasswordInstructionsV3";
import CGUV3 from "../../../../components/Version3/CGUV3/CGUV3";
import CaptachError from "./SignInUpErrorMessages/CaptachError";
import LoginAlreadyExists from "./SignInUpErrorMessages/LoginAlreadyExists";
// import SignUpFailed from "./SignInUpErrorMessages/SignUpFailed";
import PasswordsMismatch from "./SignInUpErrorMessages/PasswordsMismatch";
import PasswordIsWeak from "./SignInUpErrorMessages/PasswordIsWeak";

const SignUpFormV3 = ({
  whichFormToDisplay,
  currentURL,
  setSelectedTool,
  setIsPreauditSelected,
  setIsSupplierAssesmentSelected,
  setIsAuditRGPDSelected,
}) => {
  const navigate = useNavigate();

  const passwordStrengthOptions = [
    {
      id: 0,
      value: "Too weak",
      minDiversity: 0,
      minLength: 0,
    },
    {
      id: 1,
      value: "Weak",
      minDiversity: 2,
      minLength: 6,
    },
    {
      id: 2,
      value: "Medium",
      minDiversity: 4,
      minLength: 8,
    },
    {
      id: 3,
      value: "Strong",
      minDiversity: 4,
      minLength: 12,
    },
  ];

  // const [displayPasswordInstructions, setDisplayPasswordInstructions] =
  //   useState(false);
  const [displayCGU, setDisplayCGU] = useState(false);

  const [captcha, setCaptcha] = useState();
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);
  const [isPasswordStrong, setIsPasswordStrong] = useState(true);
  const [ArePasswordsSame, setArePasswordsSame] = useState(true);
  const [doesLoginAlreadyExist, setDoesLoginAlreadyExist] = useState(false);
  // const [didSignUpFail, setDidSignUpFail] = useState(false);

  const [isSignupLoading, setIsSignupLoading] = useState(false);

  useEffect(() => {
    setCaptcha(generateCaptcha());
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const login = cleanInput(e.target.login.value);
    const password = cleanInput(e.target.password.value);
    const passwordConfirmation = cleanInput(
      e.target.passwordconfirmation.value
    );

    const checkCaptchaStatus = checkCaptcha(e);
    const checkPasswordStrengthStatus = checkPasswordStrength(password);
    const checkPasswordsMatchStatus = checkPasswordsMatch(
      password,
      passwordConfirmation
    );

    if (
      checkCaptchaStatus &&
      checkPasswordStrengthStatus &&
      checkPasswordsMatchStatus
    ) {
      signUp(
        login,
        password,
        navigate,
        // setDidSignUpFail,
        setDoesLoginAlreadyExist,
        setIsSignupLoading,
        currentURL,
        setSelectedTool,
        setIsPreauditSelected,
        setIsSupplierAssesmentSelected,
        setIsAuditRGPDSelected
      );
    }
  };

  const checkCaptcha = (e) => {
    if (validateCaptcha(captcha, e.target.captcha.value)) {
      setIsCaptchaValid(true);
      return true;
    } else {
      setIsCaptchaValid(false);
      setCaptcha(generateCaptcha());
      return false;
    }
  };

  const checkPasswordStrength = (password) => {
    // console.log(passwordStrength(password, passwordStrengthOptions), password)

    if (passwordStrength(password, passwordStrengthOptions).value === "Strong") {
      setIsPasswordStrong(true);
      return true;
    } else {
      setIsPasswordStrong(false);
      return false;
    }
  };

  const checkPasswordsMatch = (password, passwordConfirmation) => {
    if (password === passwordConfirmation) {
      setArePasswordsSame(true);
      return true;
    } else {
      setArePasswordsSame(false);
      return false;
    }
  };

  return (
    <div id="signup-form-container">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div>
          <h1 className="font-dark-blue1 text-center">Inscription</h1>
        </div>
        <div className="user-name-container">
          <input
            required
            maxLength="50"
            type="email"
            name="login"
            placeholder="Identifiant (email)"
            className="signinup-text-input"
          />
        </div>

        <div className="password-container">
          <input
            required
            maxLength="50"
            type="password"
            name="password"
            placeholder="Mot de passe"
            className="signinup-text-input"
          />
        </div>

        <div className="password-container">
          <input
            required
            maxLength="50"
            type="password"
            name="passwordconfirmation"
            placeholder="Confirmation du mot de passe"
            className="signinup-text-input"
          />
        </div>

        <div id="captcha-container">
          <div className="">
            <span className="font-dark-blue1 font-size-085rem ">
              CAPTCHA : recopiez{" "}
              <span className="font-dark-blue1 font-weight-bold">
                {captcha}
              </span>
            </span>
          </div>
          <div className="">
            <input
              required
              type="text"
              maxLength={6}
              name="captcha"
              placeholder="CAPTCHA"
              className="margin-left-1px signinup-text-input"
            />
          </div>
        </div>

        <div id="cgu-validation-container">
          <div className="">
            <span
              className="font-dark-blue1 font-size-085rem text-decoration-underline cursor-pointer"
              onClick={() => setDisplayCGU(true)}
            >
              Conditions d'utilisation
            </span>
          </div>
          <div className="">
            <input required type="checkbox" className="" />{" "}
          </div>
        </div>

        {isSignupLoading ? (
          <>
            <div className="text-center">
              <ColorRing
                visible={true}
                height="50"
                width="50"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#232233", "#2b2c40", "#232747", "#5D87FF", "#539BFF"]}
              />
            </div>
          </>
        ) : (
          <div className="submit-button-container">
            <button
              type="submit"
              className="font-family-designosaur submit-button"
            >
              OK
            </button>
          </div>
        )}

        {/* <div id="weak-password-container">
          <span
            className="font-dark-blue1 font-family-designosaur cursor-pointer"
            onClick={() =>
              setDisplayPasswordInstructions(!displayPasswordInstructions)
            }
          >
            Mot de passe fort ?
          </span>

          {displayPasswordInstructions && (
            <StrongPasswordInstructionsV3
              displayPasswordInstructions={displayPasswordInstructions}
              setDisplayPasswordInstructions={setDisplayPasswordInstructions}
            />
          )}
        </div> */}

        <div id="signin-link-container">
          <span
            className="font-dark-blue1 font-family-designosaur cursor-pointer"
            onClick={() => whichFormToDisplay("signin")}
          >
            Déjà inscrit ?
          </span>
        </div>
      </form>

      {displayCGU && (
        <>
          <CGUV3 setDisplayCGU={setDisplayCGU} />
        </>
      )}

      {!isCaptchaValid && (
        <>
          <CaptachError setIsCaptchaValid={setIsCaptchaValid} />
        </>
      )}

      <div className="">
        {doesLoginAlreadyExist && (
          <LoginAlreadyExists
            setDoesLoginAlreadyExist={setDoesLoginAlreadyExist}
          />
        )}

        {/* {didSignUpFail && (
          <>
            <SignUpFailed setDidSignUpFail={setDidSignUpFail}/>
          </>
        )} */}
      </div>

      <div className="margin-bottom-20px">
        {!ArePasswordsSame && (
          <>
            <PasswordsMismatch setArePasswordsSame={setArePasswordsSame} />
          </>
        )}

        {!isPasswordStrong && (
          <>
            <PasswordIsWeak setIsPasswordStrong={setIsPasswordStrong} />
          </>
        )}
      </div>
    </div>
  );
};

export default SignUpFormV3;
