import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PreauditChart from "./components/PreauditChart";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  isPreauditModifiedAtom,
  preauditAnswersAtom,
  preauditProgressAtom,
} from "../../../../atoms/PreauditAtom";
import { getCurrentUserPreaudit } from "../../../../utils/functions/Version3/functionsForPreauditManagement";
import Cookies from "js-cookie";
import { getAuthUserDetails } from "../../../../utils/functions/Version3/functionsForUserInformationsManagement";
import { authUserDetailsAtom } from "../../../../atoms/AuthenticationAtom";
import PreauditValidationPrompt from "./components/PreauditValidationPrompt";
// import { checkInformationsCompletion } from "../../../../utils/functions/Version3/functionsForUserInformationsManagement";
// import { JSONPreaudit } from "../../../../utils/JSON/preauditQuestions";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "chartjs-plugin-datalabels";
// import { resetPreauditInBackend } from "../../../../utils/functions/Version3/functionsForPreauditManagement";
// import PreauditQuestions from "./components/PreauditQuestions";

const PreauditDashboardV3 = () => {
  const navigate = useNavigate();
  // const [displayPreauditChart, setDisplayPreauditChart] = useState(false);
  const isPreauditModified = useAtomValue(isPreauditModifiedAtom);
  const [displayValidationPrompt, setDisplayValidationPrompt] = useState(false);
  const setPreauditAnswers = useSetAtom(preauditAnswersAtom);
  const [preauditProgress, setPreauditProgress] = useAtom(preauditProgressAtom);
  const [userDetails, setAuthUserDetails] = useAtom(authUserDetailsAtom);
  const printRef = useRef();
  // const [displayPreauditQuestions, setDisplayPreauditQuestions] =
  //   useState(false);

  useEffect(() => {
    isPreauditModified
      ? setDisplayValidationPrompt(true)
      : getCurrentUserPreaudit(
          Cookies.get(process.env.REACT_APP_COOKIES_AUTHENTICATED_USER_ID),
          setPreauditAnswers
        );

    setPreauditProgress(null);

    // Here we get the userDetails if the page has been refreshed and then the userDetailsAtom reinitialized. These are used by the function checkInformationsCompletion in handlePreaudit.
    if (!userDetails) {
      getAuthUserDetails(setAuthUserDetails);
    }
  }, [
    isPreauditModified,
    preauditProgress,
    setPreauditProgress,
    userDetails,
    setAuthUserDetails,
    setPreauditAnswers,
  ]);

  // const handleEditPreaudit = () => {
  //   if (checkInformationsCompletion(userDetails)) {
  //     navigate("/preaudit-questions");
  //   } else {
  //     alert(
  //       "Avant d'utiliser l'audit préliminaire, merci de compléter les informations qui vont suivre."
  //     );
  //     navigate("/user-informations-edition");
  //   }
  // };

  // const resetPreaudit = () => {
  //   if (window.confirm("Les données seront remises à zéro. Confirmer ?")) {
  //     let initialAudit = {};

  //     JSONPreaudit.map((question) => {
  //       initialAudit = {
  //         ...initialAudit,
  //         [`${question.id}. ${question.theme}`]: 0,
  //       };
  //       return question;
  //     });
  //     setPreauditAnswers(initialAudit);
  //     resetPreauditInBackend(userDetails.id, initialAudit);
  //     // localStorage.removeItem("preauditProgress");
  //   }
  // };

  const handleDownloadPdf = async () => {
    if (window.confirm("Télécharger les résultats au format PDF ?")) {
      const element = printRef.current;
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/png");

      const pdf = new jsPDF();
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight, "SLOW");
      pdf.save("alliance-cyber-technologies.pdf");
    }
  };

  return (
    <>
      <div id="preaudit-dashboard-container">
        <div id="preaudit-description-container">
          Afin d'évaluer la maturité de votre entreprise vis-à-vis des normes
          ISO 27001 et ISO 27002 et pour vous aider à définir votre plan
          d'actions, nous vous proposons de remplir un formulaire simplifié
          d'auto-évaluation.
          <br />
          <br /> Pour obtenir une analyse par l'un de nos experts en
          cybersécurité, <u>à titre grâcieux</u>, demandez à être contacté
          depuis l'onglet{" "}
          <span
            className="cursor-pointer font-orange"
            onClick={() => navigate("/user-informations-edition")}
          >
            vos informations
          </span>
          .
        </div>

        <div id="preaudit-dashboard-chart-and-buttons-container">
          <div id="preaudit-dashboard-buttons-container">
            <div
              className="preaudit-dashboard-button-container"
              onClick={() => navigate("/preaudit-questions")}
            >
              <button
                id="preaudit-questions-button"
                className="preaudit-dashboard-button"
              >
                QUESTIONNAIRE
                <br />
                ISO 27001
              </button>
            </div>
            {/* <div
            className="preaudit-dashboard-button-container"
            onClick={() => resetPreaudit()}
          >
            <button
              id="preaudit-raz-button"
              className="preaudit-dashboard-button"
            >
              R A Z
            </button>
          </div> */}
            <div
              className="preaudit-dashboard-button-container"
              onClick={handleDownloadPdf}
            >
              <button
                id="preaudit-pdf-button"
                className="preaudit-dashboard-button"
              >
                PDF
              </button>
            </div>
          </div>

          <div id="preauditchart-container" ref={printRef}>
            <PreauditChart />
          </div>
        </div>
      </div>

      {/* {displayPreauditQuestions && (
        <PreauditQuestions
          setDisplayPreauditQuestions={setDisplayPreauditQuestions}
        />
      )} */}

      {displayValidationPrompt && (
        <PreauditValidationPrompt
          setDisplayValidationPrompt={setDisplayValidationPrompt}
        />
      )}
    </>
  );
};

export default PreauditDashboardV3;
