import { nanoid } from "nanoid";
import React from "react";
import { BsArrowUpCircleFill } from "react-icons/bs";
import AuditRGPDQuestionStatus from "./AuditRGPDQuestionStatus";
import { auditRGPDQuestions } from "../../../../../utils/JSON/auditRGPDQuestions";
import { FiSave } from "react-icons/fi";

const AuditRGPDProgress = ({ handleClick }) => {
  return (
    <>
      {/* <div
        id="audit-rgpd-progress-container"
        className="audit-rgpd-progress-animation"
      >
        <div id="audit-rgpd-progress-title">Progression</div>

        <div id="audit-rgpd-all-status-container">
          {auditRGPDQuestions.map((question) => (
            <AuditRGPDQuestionStatus questionId={question.id} key={nanoid()} />
          ))}
        </div>

        <div
          id="back-to-top-container"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <BsArrowUpCircleFill size="1.5rem" color="black" />
        </div>
      </div> */}

      <div
        id="audit-rgpd-progress-container"
        className="audit-rgpd-progress-animation"
      >
        <div id="audit-rgpd-progress-header-container">
          <div id="audit-rgpd-progress-title" onClick={() => handleClick()}>
            <FiSave size="2rem" color="white" />
          </div>
          <div id="audit-rgpd-back-to-top-container">
            <div>
              <abbr title="Vers le haut de page">
                <BsArrowUpCircleFill
                  size="2rem"
                  color="white"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                />
              </abbr>
            </div>
          </div>
        </div>

        <div id="audit-rgpd-all-status-container">
          {auditRGPDQuestions.map((question) => (
            <AuditRGPDQuestionStatus questionId={question.id} key={nanoid()} />
          ))}
        </div>
      </div>
    </>
  );
};

export default AuditRGPDProgress;
