import React from "react";

import MobileChart from "./MobileChart";
import DesktopChart from "./DesktopChart";
import { useUA } from "use-ua-parser-js";

const PreauditChart = () => {
  const uADetails = useUA();

  return (
    <>
      {/* <div id="preauditchart-container" ref={printRef}> */}

      <>
        {uADetails &&
        (uADetails.os.name === "Android" || uADetails.os.name === "iOS") ? (
          <MobileChart />
        ) : (
          <DesktopChart />
        )}
      </>
    </>
  );
};

export default PreauditChart;
