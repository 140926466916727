import React, { useState } from "react";
import { JSONNIS2Questions } from "../../../../utils/JSON/NIS2FormQuestions";
import {
  NIS2StatusAtom,
  Q2WhatSectorsOfActivityAtom,
  Q3WhatEnergySubSectorsAtom,
  Q3WhatNumericInfrastructureSubSectorsAtom,
  Q3WhatOtherSectorsOfActivityAtom,
  Q3WhatPublicAdministrationSubSectorsAtom,
  Q3WhatTICServicesSubSectorsAtom,
  Q3WhatTransportSubSectorsAtom,
  Q4NoneFromOtherSectorsSubSectorsAtom,
  Q4WhatManufacturingSubSectorsAtom,
  Q4WhatSizeAndRevenueAtom,
  Q5WhichOnesAreCorrectAtom,
  Q6WhatActivitiesAtom,
  displayQ5WhichOnesAreCorrectAtom,
  displayQ6WhatActivitiesAtom,
  organisationNIS2StatusAtom,
} from "../../../../atoms/NIS2Atom";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import EnergySubSectors from "./SubSectors/EnergySubSectors";
import TransportSubSectors from "./SubSectors/TransportSubSectors";
import NumericInfrastructureSubSectors from "./SubSectors/NumericInfrastructureSubSectors";
import TICServicesSubSectors from "./SubSectors/TICServicesSubSectors";
import PublicAdministrationSubSectors from "./SubSectors/PublicAdministrationSubSectors";
import {
  answersManagementForQ2SectorsOfActivity,
  resetOtherSectors,
  resetQ5WhichOnesAreCorrect,
  resetQ6WhatActivties,
  resetSizeAndRevenue,
  resetSubSectors,
} from "../../../../utils/functions/Version3/functionsForNIS2Management";
import Q3WhatOthersSectorsOfActivity from "./Q3WhatOthersSectorsOfActivity";
import Q4WhatSizeAndRevenue from "./Q4WhatSizeAndRevenue";
import { FaLongArrowAltRight } from "react-icons/fa";
import ThanksForUsingOurFormAndSubmit from "./PopUps/ThanksForUsingOurFormAndSubmit";
import GiveAtLeastOneAnswer from "./PopUps/GiveAtLeastOneAnswer";

const Q2WhatSectorsOfActivity = () => {
  const NIS2Status = useAtomValue(NIS2StatusAtom);
  const setOrganisationStatus = useSetAtom(organisationNIS2StatusAtom);

  const [Q2WhatSectorsOfActivity, setQ2WhatSectorsOfActivity] = useAtom(
    Q2WhatSectorsOfActivityAtom
  );

  const [Q3WhatEnergySubSectors, setQ3WhatEnergySubSectors] = useAtom(
    Q3WhatEnergySubSectorsAtom
  );
  const [Q3WhatTransportSubSectors, setQ3WhatTransportSubSectors] = useAtom(
    Q3WhatTransportSubSectorsAtom
  );
  const [
    Q3WhatNumericInfrastructureSubSectors,
    setQ3WhatNumericInfrastructureSubSectors,
  ] = useAtom(Q3WhatNumericInfrastructureSubSectorsAtom);
  const [Q3WhatTICServicesSubSectors, setQ3WhatTICServicesSubSectors] = useAtom(
    Q3WhatTICServicesSubSectorsAtom
  );
  const [
    Q3WhatPublicAdministrationSubSectors,
    setQ3WhatPublicAdministrationSubSectors,
  ] = useAtom(Q3WhatPublicAdministrationSubSectorsAtom);

  const [displayEnergySubSectors, setDisplayEnergySubSectors] = useState(false);
  const [displayTransportSubSectors, setDisplayTransportSubSectors] =
    useState(false);
  const [
    displayNumericInfrastructureSubSectors,
    setDisplayNumericInfrastructureSubSectors,
  ] = useState(false);
  const [displayTICServicesSubSectors, setDisplayTICServicesSubSectors] =
    useState(false);
  const [
    displayPublicAdministrationSubSectors,
    setDisplayPublicAdministrationSubSectors,
  ] = useState(false);

  const [displayOtherSectorsOfActivity, setDisplayOtherSectorsOfActivity] =
    useState(false);

  const [displayQ4WhatSizeAndRevenu, setDisplayQ4WhatSizeAndRevenue] =
    useState(false);

  const setQ3WhatOtherSectorsOfActivity = useSetAtom(
    Q3WhatOtherSectorsOfActivityAtom
  );

  const setQ4WhatManufacturingSubSectors = useSetAtom(
    Q4WhatManufacturingSubSectorsAtom
  );

  const setQ4NoneFromOtherSectors = useSetAtom(
    Q4NoneFromOtherSectorsSubSectorsAtom
  );

  const setQ4WhatSizeAndRevenue = useSetAtom(Q4WhatSizeAndRevenueAtom);

  const setQ5WhichOnesAreCorrect = useSetAtom(Q5WhichOnesAreCorrectAtom);
  const setDisplayQ5WhichOnesAreCorrect = useSetAtom(
    displayQ5WhichOnesAreCorrectAtom
  );

  const setDisplayQ6WhatActivities = useSetAtom(displayQ6WhatActivitiesAtom);
  const setQ6WhatActivities = useSetAtom(Q6WhatActivitiesAtom);

  // const navigate = useNavigate();

  const [displayThanksPopUp, setDisplayThanksPopUp] = useState(false);
  const [displayGiveAtLeastOneAnswer, setDisplayGiveAtLeastOneAnswer] =
    useState(false);
  const [answerType, setAnswerType] = useState(null);

  const answersAnlaysis = () => {
    // Analyse user's answers before running next step :
    if (isFormValid()) {
      // 1 - Case : "No the organisation belongs to listed sectors"
      if (
        Q2WhatSectorsOfActivity.length === 1 &&
        Q2WhatSectorsOfActivity[0] === "aucun de la liste"
      ) {
        setDisplayOtherSectorsOfActivity(true);
        window.scrollBy({
          top: 200,
          left: 0,
          behavior: "smooth",
        });
      }

      // 2 - Case : the organisation is an "Entité essentielle"
      else if (
        Q2WhatSectorsOfActivity.includes("espace") ||
        Q2WhatSectorsOfActivity.includes("secteur bancaire") ||
        Q2WhatSectorsOfActivity.includes(
          "infrastructure des marchés financiers"
        ) ||
        Q2WhatSectorsOfActivity.includes("santé") ||
        Q2WhatSectorsOfActivity.includes("eau potable") ||
        Q2WhatSectorsOfActivity.includes("eaux usées")
      ) {
        setOrganisationStatus(NIS2Status[1]);
        setDisplayThanksPopUp(true);
      }

      // 3 - Case : "Go question about organisation size for essential entity"
      else if (
        (Q3WhatEnergySubSectors.length >= 1 &&
          !Q3WhatEnergySubSectors.includes("aucun de la liste")) ||
        (Q3WhatTransportSubSectors.length >= 1 &&
          !Q3WhatTransportSubSectors.includes("aucun de la liste")) ||
        (Q3WhatNumericInfrastructureSubSectors.length >= 1 &&
          !Q3WhatNumericInfrastructureSubSectors.includes(
            "aucun de la liste"
          )) ||
        (Q3WhatTICServicesSubSectors.length >= 1 &&
          !Q3WhatTICServicesSubSectors.includes("aucun de la liste")) ||
        (Q3WhatPublicAdministrationSubSectors.length >= 1 &&
          !Q3WhatPublicAdministrationSubSectors.includes("aucun de la liste"))
      ) {
        setDisplayQ4WhatSizeAndRevenue(true);
        window.scrollBy({
          top: 200,
          left: 0,
          behavior: "smooth",
        });
      }
      // 4 - Case : the organisation is an "Entité exclue"
      else {
        setOrganisationStatus(NIS2Status[0]);
        setDisplayThanksPopUp(true);
      }
    }
  };

  const isFormValid = () => {
    return isSectorsFormValid() &&
      isEnergyFormValid() &&
      isTransportFormValid() &&
      isNumericInfrastructureFormValid() &&
      isTICServicesFormValid() &&
      isPublicAdministrationFormValid()
      ? true
      : false;
  };

  const isSectorsFormValid = () => {
    if (Q2WhatSectorsOfActivity.length === 0) {
      // alert("Vous devez choisir au moins une réponse pour poursuivre.");
      setDisplayGiveAtLeastOneAnswer(true);
      setAnswerType("poursuivre");
      return false;
    } else return true;
  };

  const isEnergyFormValid = () => {
    if (displayEnergySubSectors && Q3WhatEnergySubSectors.length === 0) {
      setDisplayGiveAtLeastOneAnswer(true);
      setAnswerType("Énergie");
      return false;
    } else return true;
  };

  const isTransportFormValid = () => {
    if (displayTransportSubSectors && Q3WhatTransportSubSectors.length === 0) {
      setDisplayGiveAtLeastOneAnswer(true);
      setAnswerType("Transport");
      return false;
    } else return true;
  };

  const isNumericInfrastructureFormValid = () => {
    if (
      displayNumericInfrastructureSubSectors &&
      Q3WhatNumericInfrastructureSubSectors.length === 0
    ) {
      setDisplayGiveAtLeastOneAnswer(true);
      setAnswerType("Infrastructure numérique");
      return false;
    } else return true;
  };

  const isTICServicesFormValid = () => {
    if (
      displayTICServicesSubSectors &&
      Q3WhatTICServicesSubSectors.length === 0
    ) {
      setDisplayGiveAtLeastOneAnswer(true);
      setAnswerType("Gestion des services TIC");
      return false;
    } else return true;
  };

  const isPublicAdministrationFormValid = () => {
    if (
      displayPublicAdministrationSubSectors &&
      Q3WhatPublicAdministrationSubSectors.length === 0
    ) {
      setDisplayGiveAtLeastOneAnswer(true);
      setAnswerType("Administration publique");
      return false;
    } else return true;
  };

  return (
    <>
      <div id="Q2WhatSectorsOfActivity" className="nis2form-question-container">
        <div className="nis2form-question-content">
          {JSONNIS2Questions[1].content}
        </div>
        <div id="q2-sectors-of-activity-container">
          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="énergie"
                checked={Q2WhatSectorsOfActivity.includes("énergie")}
                onChange={(e) =>
                  answersManagementForQ2SectorsOfActivity(
                    e,
                    Q2WhatSectorsOfActivity,
                    setQ2WhatSectorsOfActivity,
                    setQ3WhatEnergySubSectors,
                    setQ3WhatTransportSubSectors,
                    setQ3WhatNumericInfrastructureSubSectors,
                    setQ3WhatTICServicesSubSectors,
                    setQ3WhatPublicAdministrationSubSectors
                  )
                }
                onClick={() => {
                  setDisplayEnergySubSectors(!displayEnergySubSectors);
                  resetOtherSectors(
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors,
                    setDisplayOtherSectorsOfActivity
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Énergie
            </label>
            {displayEnergySubSectors && (
              <EnergySubSectors
                setDisplayQ4WhatSizeAndRevenue={setDisplayQ4WhatSizeAndRevenue}
              />
            )}
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="transport"
                checked={Q2WhatSectorsOfActivity.includes("transport")}
                onChange={(e) =>
                  answersManagementForQ2SectorsOfActivity(
                    e,
                    Q2WhatSectorsOfActivity,
                    setQ2WhatSectorsOfActivity,
                    setQ3WhatEnergySubSectors,
                    setQ3WhatTransportSubSectors,
                    setQ3WhatNumericInfrastructureSubSectors,
                    setQ3WhatTICServicesSubSectors,
                    setQ3WhatPublicAdministrationSubSectors
                  )
                }
                onClick={() => {
                  setDisplayTransportSubSectors(!displayTransportSubSectors);
                  resetOtherSectors(
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors,
                    setDisplayOtherSectorsOfActivity
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Transport
            </label>
            {displayTransportSubSectors && (
              <TransportSubSectors
                setDisplayQ4WhatSizeAndRevenue={setDisplayQ4WhatSizeAndRevenue}
              />
            )}
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="infrastructure numérique"
                checked={Q2WhatSectorsOfActivity.includes(
                  "infrastructure numérique"
                )}
                onChange={(e) =>
                  answersManagementForQ2SectorsOfActivity(
                    e,
                    Q2WhatSectorsOfActivity,
                    setQ2WhatSectorsOfActivity,
                    setQ3WhatEnergySubSectors,
                    setQ3WhatTransportSubSectors,
                    setQ3WhatNumericInfrastructureSubSectors,
                    setQ3WhatTICServicesSubSectors,
                    setQ3WhatPublicAdministrationSubSectors
                  )
                }
                onClick={() => {
                  setDisplayNumericInfrastructureSubSectors(
                    !displayNumericInfrastructureSubSectors
                  );
                  resetOtherSectors(
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors,
                    setDisplayOtherSectorsOfActivity
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Infrastructure numérique
            </label>
            {displayNumericInfrastructureSubSectors && (
              <NumericInfrastructureSubSectors
                setDisplayQ4WhatSizeAndRevenue={setDisplayQ4WhatSizeAndRevenue}
              />
            )}
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="gestion des services TIC"
                checked={Q2WhatSectorsOfActivity.includes(
                  "gestion des services TIC"
                )}
                onChange={(e) =>
                  answersManagementForQ2SectorsOfActivity(
                    e,
                    Q2WhatSectorsOfActivity,
                    setQ2WhatSectorsOfActivity,
                    setQ3WhatEnergySubSectors,
                    setQ3WhatTransportSubSectors,
                    setQ3WhatNumericInfrastructureSubSectors,
                    setQ3WhatTICServicesSubSectors,
                    setQ3WhatPublicAdministrationSubSectors
                  )
                }
                onClick={() => {
                  setDisplayTICServicesSubSectors(
                    !displayTICServicesSubSectors
                  );
                  resetOtherSectors(
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors,
                    setDisplayOtherSectorsOfActivity
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Gestion des services TIC
            </label>
            {displayTICServicesSubSectors && (
              <TICServicesSubSectors
                setDisplayQ4WhatSizeAndRevenue={setDisplayQ4WhatSizeAndRevenue}
              />
            )}
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="administration publique"
                checked={Q2WhatSectorsOfActivity.includes(
                  "administration publique"
                )}
                onChange={(e) =>
                  answersManagementForQ2SectorsOfActivity(
                    e,
                    Q2WhatSectorsOfActivity,
                    setQ2WhatSectorsOfActivity,
                    setQ3WhatEnergySubSectors,
                    setQ3WhatTransportSubSectors,
                    setQ3WhatNumericInfrastructureSubSectors,
                    setQ3WhatTICServicesSubSectors,
                    setQ3WhatPublicAdministrationSubSectors
                  )
                }
                onClick={() => {
                  setDisplayPublicAdministrationSubSectors(
                    !displayPublicAdministrationSubSectors
                  );
                  resetOtherSectors(
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors,
                    setDisplayOtherSectorsOfActivity
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Administration publique
            </label>
            {displayPublicAdministrationSubSectors && (
              <PublicAdministrationSubSectors
                setDisplayQ4WhatSizeAndRevenue={setDisplayQ4WhatSizeAndRevenue}
              />
            )}
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="espace"
                checked={Q2WhatSectorsOfActivity.includes("espace")}
                onChange={(e) =>
                  answersManagementForQ2SectorsOfActivity(
                    e,
                    Q2WhatSectorsOfActivity,
                    setQ2WhatSectorsOfActivity,
                    setQ3WhatEnergySubSectors,
                    setQ3WhatTransportSubSectors,
                    setQ3WhatNumericInfrastructureSubSectors,
                    setQ3WhatTICServicesSubSectors,
                    setQ3WhatPublicAdministrationSubSectors
                  )
                }
                onClick={() => {
                  resetOtherSectors(
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors,
                    setDisplayOtherSectorsOfActivity
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Espace
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="secteur bancaire"
                checked={Q2WhatSectorsOfActivity.includes("secteur bancaire")}
                onChange={(e) =>
                  answersManagementForQ2SectorsOfActivity(
                    e,
                    Q2WhatSectorsOfActivity,
                    setQ2WhatSectorsOfActivity,
                    setQ3WhatEnergySubSectors,
                    setQ3WhatTransportSubSectors,
                    setQ3WhatNumericInfrastructureSubSectors,
                    setQ3WhatTICServicesSubSectors,
                    setQ3WhatPublicAdministrationSubSectors
                  )
                }
                onClick={() => {
                  resetOtherSectors(
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors,
                    setDisplayOtherSectorsOfActivity
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Secteur bancaire
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="infrastructure des marchés financiers"
                checked={Q2WhatSectorsOfActivity.includes(
                  "infrastructure des marchés financiers"
                )}
                onChange={(e) =>
                  answersManagementForQ2SectorsOfActivity(
                    e,
                    Q2WhatSectorsOfActivity,
                    setQ2WhatSectorsOfActivity,
                    setQ3WhatEnergySubSectors,
                    setQ3WhatTransportSubSectors,
                    setQ3WhatNumericInfrastructureSubSectors,
                    setQ3WhatTICServicesSubSectors,
                    setQ3WhatPublicAdministrationSubSectors
                  )
                }
                onClick={() => {
                  resetOtherSectors(
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors,
                    setDisplayOtherSectorsOfActivity
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Infrastructure des marchés financiers
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="santé"
                checked={Q2WhatSectorsOfActivity.includes("santé")}
                onChange={(e) =>
                  answersManagementForQ2SectorsOfActivity(
                    e,
                    Q2WhatSectorsOfActivity,
                    setQ2WhatSectorsOfActivity,
                    setQ3WhatEnergySubSectors,
                    setQ3WhatTransportSubSectors,
                    setQ3WhatNumericInfrastructureSubSectors,
                    setQ3WhatTICServicesSubSectors,
                    setQ3WhatPublicAdministrationSubSectors
                  )
                }
                onClick={() => {
                  resetOtherSectors(
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors,
                    setDisplayOtherSectorsOfActivity
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Santé
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="eau potable"
                checked={Q2WhatSectorsOfActivity.includes("eau potable")}
                onChange={(e) =>
                  answersManagementForQ2SectorsOfActivity(
                    e,
                    Q2WhatSectorsOfActivity,
                    setQ2WhatSectorsOfActivity,
                    setQ3WhatEnergySubSectors,
                    setQ3WhatTransportSubSectors,
                    setQ3WhatNumericInfrastructureSubSectors,
                    setQ3WhatTICServicesSubSectors,
                    setQ3WhatPublicAdministrationSubSectors
                  )
                }
                onClick={() => {
                  resetOtherSectors(
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors,
                    setDisplayOtherSectorsOfActivity
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Eau potable
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="eaux usées"
                checked={Q2WhatSectorsOfActivity.includes("eaux usées")}
                onChange={(e) =>
                  answersManagementForQ2SectorsOfActivity(
                    e,
                    Q2WhatSectorsOfActivity,
                    setQ2WhatSectorsOfActivity,
                    setQ3WhatEnergySubSectors,
                    setQ3WhatTransportSubSectors,
                    setQ3WhatNumericInfrastructureSubSectors,
                    setQ3WhatTICServicesSubSectors,
                    setQ3WhatPublicAdministrationSubSectors
                  )
                }
                onClick={() => {
                  resetOtherSectors(
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors,
                    setDisplayOtherSectorsOfActivity
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Eaux usées
            </label>
          </div>

          <div className="nis2-single-sector-container">
            <label className="nis2-form-answers">
              <input
                className="nis2-form-answers-input"
                type="checkbox"
                name="aucun de la liste"
                checked={Q2WhatSectorsOfActivity.includes("aucun de la liste")}
                onChange={(e) => {
                  answersManagementForQ2SectorsOfActivity(
                    e,
                    Q2WhatSectorsOfActivity,
                    setQ2WhatSectorsOfActivity,
                    setQ3WhatEnergySubSectors,
                    setQ3WhatTransportSubSectors,
                    setQ3WhatNumericInfrastructureSubSectors,
                    setQ3WhatTICServicesSubSectors,
                    setQ3WhatPublicAdministrationSubSectors
                  );
                }}
                onClick={() => {
                  resetSubSectors(
                    setQ3WhatEnergySubSectors,
                    setDisplayEnergySubSectors,
                    setQ3WhatTransportSubSectors,
                    setDisplayTransportSubSectors,
                    setQ3WhatNumericInfrastructureSubSectors,
                    setDisplayNumericInfrastructureSubSectors,
                    setQ3WhatTICServicesSubSectors,
                    setDisplayTICServicesSubSectors,
                    setQ3WhatPublicAdministrationSubSectors,
                    setDisplayPublicAdministrationSubSectors
                  );
                  resetOtherSectors(
                    setQ3WhatOtherSectorsOfActivity,
                    setQ4WhatManufacturingSubSectors,
                    setQ4NoneFromOtherSectors,
                    setDisplayOtherSectorsOfActivity
                  );
                  resetSizeAndRevenue(
                    setQ4WhatSizeAndRevenue,
                    setDisplayQ4WhatSizeAndRevenue
                  );
                  resetQ5WhichOnesAreCorrect(
                    setQ5WhichOnesAreCorrect,
                    setDisplayQ5WhichOnesAreCorrect
                  );
                  resetQ6WhatActivties(
                    setQ6WhatActivities,
                    setDisplayQ6WhatActivities
                  );
                }}
              />
              Aucun de la liste
            </label>
          </div>
        </div>
      </div>

      {displayOtherSectorsOfActivity && <Q3WhatOthersSectorsOfActivity />}

      {displayQ4WhatSizeAndRevenu && <Q4WhatSizeAndRevenue />}

      {!displayOtherSectorsOfActivity && !displayQ4WhatSizeAndRevenu && (
        <div
          className="nis2-validation-button-container"
          onClick={() => answersAnlaysis()}
        >
          <div className="nis2-validation-button-text">SUIVANT</div>
          <FaLongArrowAltRight className="nis2-validation-button-arrow" />
        </div>
      )}

      {displayThanksPopUp && <ThanksForUsingOurFormAndSubmit />}
      {displayGiveAtLeastOneAnswer && (
        <GiveAtLeastOneAnswer
          setDisplayGiveAtLeastOneAnswer={setDisplayGiveAtLeastOneAnswer}
          answerType={answerType}
        />
      )}
    </>
  );
};

export default Q2WhatSectorsOfActivity;
