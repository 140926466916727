import Cookies from "js-cookie";

// Function to get current user details from backend
export const getAuthUserDetails = (setAuthUserDetails) => {
  fetch(process.env.REACT_APP_BACKEND_URL + "member", {
    method: "GET",
    headers: {
      Authorization: Cookies.get(process.env.REACT_APP_COOKIES_TOKEN_SESSION),
    },
  })
    .then((response) => {
      // console.log("RESPONSE from getAuthUserDetails :\n", response);
      return response.json();
    })
    .then((data) => {
      // console.log("DATA from getAuthUserDetails :\n", data);
      setAuthUserDetails(data.user);
    });
};

// Function to UPDATE user informations backend
export const saveUserInformationsInBackend = (data) => {
  fetch(process.env.REACT_APP_BACKEND_URL + "member", {
    method: "PUT",
    headers: {
      Authorization: Cookies.get(process.env.REACT_APP_COOKIES_TOKEN_SESSION),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user: data }),
  })
    .then((response) => {
      // console.log("RESPONSE from saveUserInformationsInBackend :\n", response);
      return response.json();
    })
    .then((data) => {
      // console.log("DATA from saveUserInformationsInBackend :\n", data);
      data.availability_change &&
        alert("Vos nouvelles disponibilités ont bien été enregistrées.");
    });
  // .catch(error => console.error("backendUpdate #members -> ERROR\n", error.message))
};

// Function that checks if all User's informations are present.
export const checkInformationsCompletion = (userData) => {
  // console.log("HELLO checkInformationsCompletion :", userData);
  return userData.organization === "" ||
    userData.phone === "" ||
    userData.contact === "" ||
    userData.address === "" ||
    userData.siret === ""
    ? false
    : true;
};
