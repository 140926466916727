import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../../../components/Version3/Footer/Footer";

const GenericLayout = () => {
  return (
    <>
      <Outlet />

      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default GenericLayout;
