import { useAtom, useSetAtom } from "jotai";
import React from "react";
import {
  Q3WhatEnergySubSectorsAtom,
  Q4WhatSizeAndRevenueAtom,
  Q5WhichOnesAreCorrectAtom,
  Q6WhatActivitiesAtom,
  displayQ5WhichOnesAreCorrectAtom,
  displayQ6WhatActivitiesAtom,
} from "../../../../../atoms/NIS2Atom";
import {
  answersManagementForQ2SectorsOfActivity,
  resetQ5WhichOnesAreCorrect,
  resetQ6WhatActivties,
  resetSizeAndRevenue,
} from "../../../../../utils/functions/Version3/functionsForNIS2Management";

const EnergySubSectors = ({ setDisplayQ4WhatSizeAndRevenue }) => {
  const [Q3WhatEnergySubSectors, setQ3WhatEnergySubSectors] = useAtom(
    Q3WhatEnergySubSectorsAtom
  );

  const setQ4WhatSizeAndRevenue = useSetAtom(Q4WhatSizeAndRevenueAtom);

  const setQ5WhichOnesAreCorrect = useSetAtom(Q5WhichOnesAreCorrectAtom);
  const setDisplayQ5WhichOnesAreCorrect = useSetAtom(
    displayQ5WhichOnesAreCorrectAtom
  );

  const setDisplayQ6WhatActivities = useSetAtom(displayQ6WhatActivitiesAtom);

  const setQ6WhatActivities = useSetAtom(Q6WhatActivitiesAtom);

  return (
    <>
      <div className="nis2-sub-sectors-container">
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="électricité"
              checked={Q3WhatEnergySubSectors.includes("électricité")}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatEnergySubSectors,
                  setQ3WhatEnergySubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Électricité
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="réseaux de chaleur et de froid"
              checked={Q3WhatEnergySubSectors.includes(
                "réseaux de chaleur et de froid"
              )}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatEnergySubSectors,
                  setQ3WhatEnergySubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Réseaux de chaleur et de froid
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="pétrole"
              checked={Q3WhatEnergySubSectors.includes("pétrole")}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatEnergySubSectors,
                  setQ3WhatEnergySubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Pétrole
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="gaz"
              checked={Q3WhatEnergySubSectors.includes("gaz")}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatEnergySubSectors,
                  setQ3WhatEnergySubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Gaz
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="hydrogène"
              checked={Q3WhatEnergySubSectors.includes("hydrogène")}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatEnergySubSectors,
                  setQ3WhatEnergySubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Hydrogène
          </label>
        </div>
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="aucun de la liste"
              checked={Q3WhatEnergySubSectors.includes("aucun de la liste")}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatEnergySubSectors,
                  setQ3WhatEnergySubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Aucun de la liste
          </label>
        </div>
      </div>
    </>
  );
};

export default EnergySubSectors;
