import React from "react";

const CGUV3 = ({ setDisplayCGU }) => {
  return (
    <>
      <div id="cgu-modal">
        <div id="cgu-container">

          <h2 className="font-dark-blue1">
            Conditions d'utilisation
          </h2>

          <h5 className="font-dark-blue1 margin-bottom-30px">
            Dernière mise à jour : 16/10/2023
          </h5>

          <p className="font-dark-blue1 margin-bottom-30px">
            Les informations recueillies dans ce formulaire sont enregistrées
            dans un fichier informatisé par Alliance Cyber Technologies, société
            basée à Lille, et stockés sur des serveurs situés en Europe. La base
            légale du traitement est basée sur une relation pré-contractuelle.
            Les informations demandées dans le formulaire doivent
            obligatoirement être fournies. Dans le cas contraire, il vous sera
            impossible de poursuivre votre demande (inscription, campagne
            faux-phishing, etc.) . Les données collectées seront communiquées
            aux seuls destinataires suivants : Alliance Cyber Technologies. Vous
            pouvez demander à y accéder, les rectifier, demander leur effacement
            ou exercer votre droit à la limitation du traitement de vos données.
            Consultez le site cnil.fr pour plus d’informations sur vos droits.
            Pour exercer ces droits ou pour toute question sur le traitement de
            vos données dans ce dispositif, vous pouvez contacter (le cas
            échéant, notre délégué à la protection des données ou le service
            chargé de l’exercice de ces droits) : Alliance Cyber Technologies,
            Email: contact[@]alliancecybertech.com.
          </p>

        
          <div id="cgu-close-button-container">
            <button
              onClick={() => setDisplayCGU(false)}
              id="cgu-close-button"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CGUV3;
