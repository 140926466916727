import React from "react";
import { useLocation } from "react-router-dom";
import ToolsLayout from "./components/ToolsLayout";
import GenericLayout from "./components/GenericLayout";
import NIS2Layout from "./components/NIS2Layout";

const SharedLayoutV3 = () => {
  const location = useLocation();

  return (
    <>
      {/* Layout for generic pages (user is not connected) */}

      {(location.pathname === "/" ||
        location.pathname === "/preaudit-welcome" ||
        location.pathname === "/supplier-assesment-welcome" ||
        location.pathname === "/audit-rgpd-welcome" ||
        location.pathname.includes("/new_password")) && <GenericLayout />}

      {/* Layout for each tool usage (when user is connected) */}
      {(location.pathname === "/user-informations" ||
        location.pathname === "/user-informations-edition" ||
        location.pathname === "/dashboard" ||
        location.pathname === "/preaudit-questions" ||
        location.pathname === "/supplier-assesment-questions" ||
        location.pathname === "/audit-rgpd-questions" ||
        location.pathname.startsWith("/supplier-assesment/") ||
        location.pathname === "/supplier-informations-edition" ||
        location.pathname.includes("supplier-assesment-questions/") ||
        location.pathname === "/tests") && <ToolsLayout />}

      {/* Layout for the NIS2 form (user is not connected) */}
      {(location.pathname === "/nis2-welcome" ||
        location.pathname === "/nis2-form" ||
        location.pathname === "/nis2-organisation-informations-form") && (
        <NIS2Layout />
      )}
    </>
  );
};

export default SharedLayoutV3;
