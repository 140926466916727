import { useAtom, useSetAtom } from "jotai";
import React from "react";
import {
  Q3WhatTICServicesSubSectorsAtom,
  Q4WhatSizeAndRevenueAtom,
  Q5WhichOnesAreCorrectAtom,
  Q6WhatActivitiesAtom,
  displayQ5WhichOnesAreCorrectAtom,
  displayQ6WhatActivitiesAtom,
} from "../../../../../atoms/NIS2Atom";
import {
  answersManagementForQ2SectorsOfActivity,
  resetQ5WhichOnesAreCorrect,
  resetQ6WhatActivties,
  resetSizeAndRevenue,
} from "../../../../../utils/functions/Version3/functionsForNIS2Management";

const TICServicesSubSectors = ({ setDisplayQ4WhatSizeAndRevenue }) => {
  const [Q3WhatTICServicesSubSectors, setQ3WhatTICServicesSubSectors] = useAtom(
    Q3WhatTICServicesSubSectorsAtom
  );

  const setQ4WhatSizeAndRevenue = useSetAtom(Q4WhatSizeAndRevenueAtom);

  const setQ5WhichOnesAreCorrect = useSetAtom(Q5WhichOnesAreCorrectAtom);
  const setDisplayQ5WhichOnesAreCorrect = useSetAtom(
    displayQ5WhichOnesAreCorrectAtom
  );

  const setDisplayQ6WhatActivities = useSetAtom(displayQ6WhatActivitiesAtom);

  const setQ6WhatActivities = useSetAtom(Q6WhatActivitiesAtom);

  return (
    <>
      <div className="nis2-sub-sectors-container">
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="fournisseurs de services gérés"
              checked={Q3WhatTICServicesSubSectors.includes(
                "fournisseurs de services gérés"
              )}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatTICServicesSubSectors,
                  setQ3WhatTICServicesSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Fournisseurs de services gérés
          </label>
        </div>

        <div>
          <label className="nis2-form-sub-answers">
            {" "}
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="fournisseurs de services de sécurité gérés"
              checked={Q3WhatTICServicesSubSectors.includes(
                "fournisseurs de services de sécurité gérés"
              )}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatTICServicesSubSectors,
                  setQ3WhatTICServicesSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Fournisseurs de services de sécurité gérés
          </label>
        </div>

        <div>
          <label className="nis2-form-sub-answers">
            {" "}
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="aucun de la liste"
              checked={Q3WhatTICServicesSubSectors.includes(
                "aucun de la liste"
              )}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatTICServicesSubSectors,
                  setQ3WhatTICServicesSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Aucun de la liste
          </label>
        </div>
      </div>
    </>
  );
};

export default TICServicesSubSectors;
