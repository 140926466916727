import Cookies from "js-cookie";

// Function that redirect user on the right tool menu after signin or signup.
export const redirectUserOnTheRightTool = (
  currentURL,
  setSelectedTool,
  isUserAdmin,
  navigate
) => {
  if (currentURL === "/preaudit-welcome") {
    setSelectedTool("preaudit");
    // setIsPreauditSelected(true);
    // setIsSupplierAssesmentSelected(false);
    // setIsAuditRGPDSelected(false);
    // window.location.href =
    //   process.env.REACT_APP_FRONTEND_URL + "user-informations";
    navigate("/user-informations");
  }

  if (currentURL === "/supplier-assesment-welcome") {
    setSelectedTool("supplier-assesment");
    // setIsPreauditSelected(false);
    // setIsSupplierAssesmentSelected(true);
    // setIsAuditRGPDSelected(false);
    // window.location.href =
    //   process.env.REACT_APP_FRONTEND_URL + "supplier-assesment-questions";
    navigate("/user-informations");
  }

  if (currentURL === "/audit-rgpd-welcome") {
    setSelectedTool("audit-rgpd");
    // setIsPreauditSelected(false);
    // setIsSupplierAssesmentSelected(false);
    // setIsAuditRGPDSelected(true);
    // window.location.href =
    //   process.env.REACT_APP_FRONTEND_URL + "audit-rgpd-questions";
    navigate("/user-informations");
  }

  if (currentURL === "/act-access") {
    // if (isUserAdmin) {
    setSelectedTool("backstage");
    // setIsPreauditSelected(false);
    // setIsSupplierAssesmentSelected(false);
    // setIsAuditRGPDSelected(true);
    // window.location.href =
    //   process.env.REACT_APP_FRONTEND_URL + "act-access/users-listing";
    navigate("/user-informations");
    // } else {
    //   alert("Vous n'avez pas les droits suffisants pour aller plus loin.");
    // }
  }
};

// Function to login to backend
export const signIn = (
  login,
  password,
  setAuthenticationFailed,
  navigate,
  setIsSigninLoading,
  currentURL,
  setSelectedTool,
  setIsPreauditSelected,
  setIsSupplierAssesmentSelected,
  setIsAuditRGPDSelected,
  setAuthenticationPage
) => {
  setIsSigninLoading(true);

  fetch(process.env.REACT_APP_BACKEND_URL + "users/sign_in", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      user: {
        email: login,
        password: password,
      },
    }),
  })
    .then((response) => {
      // console.log("RESPONSE from SIGN IN :\n", response);

      if (response.headers.get("Authorization")) {
        const expirationDate = new Date();
        expirationDate.setFullYear(expirationDate.getFullYear() + 1);
        response.headers.get("Authorization") &&
          Cookies.set(
            process.env.REACT_APP_COOKIES_TOKEN_SESSION,
            response.headers.get("Authorization"),
            {
              expires: expirationDate,
              sameSite: "None",
              secure: true,
            }
          );
      } else {
        setAuthenticationFailed(true);
      }
      setIsSigninLoading(false);
      return response.json();
    })
    .then((data) => {
      // console.log("DATA from SIGN IN :\n", data);

      if (data.message === "You are logged in.") {
        Cookies.set(
          process.env.REACT_APP_COOKIES_AUTHENTICATED_USER_ID,
          data.user_id
        );
        redirectUserOnTheRightTool(
          currentURL,
          setSelectedTool,
          data.is_user_admin,
          navigate
        );
      }
    });
  // .catch((error) => console.error("SignIn -> ERROR", error.message));
};

// Function to register in backend
export const signUp = (
  login,
  password,
  // setAuthUserData,
  // setPreauditAnswers,
  navigate,
  // setDidSignUpFail,
  setDoesLoginAlreadyExist,
  setIsSignupLoading,
  currentURL,
  setSelectedTool
  // setIsPreauditSelected,
  // setIsSupplierAssesmentSelected,
  // setIsAuditRGPDSelected
) => {
  setIsSignupLoading(true);
  fetch(process.env.REACT_APP_BACKEND_URL + "users", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      user: {
        email: login,
        password: password,
      },
    }),
  })
    .then((response) => {
      // console.log("RESPONSE from SIGNUP :\n", response);

      if (response.headers.get("Authorization")) {
        const expirationDate = new Date();
        expirationDate.setFullYear(expirationDate.getFullYear() + 1);
        response.headers.get("Authorization") &&
          Cookies.set(
            process.env.REACT_APP_COOKIES_TOKEN_SESSION,
            response.headers.get("Authorization"),
            {
              expires: expirationDate,
              sameSite: "None",
              secure: true,
            }
          );
      } else {
        // setDidSignUpFail(true);
      }
      setIsSignupLoading(false);

      return response.json();
    })
    .then((data) => {
      // console.log("DATA from SIGNUP :\n", data);

      if (
        data.exception ===
        `#<ActiveRecord::RecordNotUnique: PG::UniqueViolation: ERROR:  duplicate key value violates unique constraint "index_users_on_email"\nDETAIL:  Key (email)=(${login}) already exists.\n>`
      ) {
        setDoesLoginAlreadyExist(true);
      } else {
        Cookies.set(
          process.env.REACT_APP_COOKIES_AUTHENTICATED_USER_ID,
          data.user_id
        );
        redirectUserOnTheRightTool(
          currentURL,
          setSelectedTool,
          data.is_user_admin,
          navigate
        );
      }
    });
  // .catch(error => console.error("SignUp -> ERROR", error.message))
};

// Function to signOut from backend
export const signOut = (
  navigate,
  // setAuthUserData,
  // setPreauditAnswers,
  selectedTool,
  setSelectedTool
  ) => {
  // console.log("SelectedTool : ", selectedTool);
  if (window.confirm("Vous allez être déconnecté. Poursuivre ?")) {
    fetch(process.env.REACT_APP_BACKEND_URL + "users/sign_out", {
      method: "DELETE",
      headers: {
        Authorization: Cookies.get(process.env.REACT_APP_COOKIES_TOKEN_SESSION),
      },
    })
      .then((response) => {
        // console.log("RESPONSE from SIGNOUT :\n", response);

        return response.json();
      })
      .then((data) => {
        // console.log("DATA from SIGNOUT :\n", data);

        Cookies.remove(process.env.REACT_APP_COOKIES_TOKEN_SESSION);
        Cookies.remove(process.env.REACT_APP_COOKIES_AUTHENTICATED_USER_ID);

        if (selectedTool === "preaudit") {
          navigate("/preaudit-welcome");
        }
        if (selectedTool === "supplier-assesment") {
          navigate("/supplier-assesment-welcome");
        }
        if (selectedTool === "audit-rgpd") {
          navigate("/audit-rgpd-welcome");
        }
        if (selectedTool === "backstage") {
          navigate("/act-access");
        }

        setSelectedTool("no-selection");
      });
    // .catch(error => console.error("SignOut -> ERROR", error.message))
  }
};

// Function test
export const test = (setAuthUserData) => {
  // console.log("Fonction test");
  setAuthUserData("DATA from TEST");
};
