import React from "react";
import { useAtomValue } from "jotai";
import { preauditProgressAtom } from "../../../../../atoms/PreauditAtom"

const QuestionStatus = ({ questionId }) => {
  const progress = useAtomValue(preauditProgressAtom);

  return (
    <>
      <div
        className={
          progress && progress[questionId]
            ? "one-status-container background-color-green "
            : "one-status-container background-color-white"
        }
      >
        <span
          className={
            progress && progress[questionId]
              ? "status-answered "
              : "status-not-answered"
          }
        >
          {questionId}
        </span>
      </div>
    </>
  );
};

export default QuestionStatus;
