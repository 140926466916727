import React from "react";
import { sendResetInstructions } from "../../../utils/functions/Version3/functionsForResetUserPassword";
import { cleanInput } from "../../../utils/functions/Version3/functionsForFormsInputsManagement";

const ResetInstructionsFormV3 = ({ setDisplayResetInstructionsForm }) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const email = cleanInput(e.target.emailForReset.value);
    sendResetInstructions(email, setDisplayResetInstructionsForm);
  };

  return (
    <>
      <div id="send-reset-instructions-modal">
        <form
          id="send-reset-instructions-form"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div>
            <div className="margin-bottom-30px">
              <h2>
                <span className="font-dark-blue1">Mot de passe perdu ?</span>
              </h2>
            </div>

            <div className="text-left">
              <label htmlFor="emailForReset" className="font-dark-blue1">
                Saisissez votre adresse email pour recevoir les instructions :
              </label>
            </div>

            <div>
              <input
                required
                maxLength="50"
                type="email"
                name="emailForReset"
                className="font-dark-blue1 margin-bottom-30px border-bottom-1px-solid-black width-100pc"
              />
            </div>
          </div>

          <div id="submit-buttons-container">
            <button type="submit" id="send-button">
              OK
            </button>
            <button
              id="close-button"
              onClick={() => setDisplayResetInstructionsForm(false)}
            >
              Annuler
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ResetInstructionsFormV3;
