import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSetAtom } from "jotai";
import { selectedToolAtom } from "../../../atoms/MenusSelectionAtom";
import { getSupplierAssesment } from "../../../utils/functions/Version3/functionsForSupplierAssesmentManagement";
import {
  supplierAssesmentAnswersAtom,
  supplierAssesmentCommentsAtom,
  supplierAssesmentToUpdateIdAtom,
  supplierAssesmentToUpdateSupplierAtom,
  supplierAssesmentToUpdateTokenAtom,
} from "../../../atoms/SupplierAssesmentAtom";
import Cookies from "js-cookie";

const SupplierAssesmentTokenLandingPage = () => {
  const { supplierAssesmentId, supplierAssesmentToken } = useParams();
  const setSupplierAssesmentIdToUpdate = useSetAtom(
    supplierAssesmentToUpdateIdAtom
  );
  const setSupplierAssesmentTokenToUpdate = useSetAtom(
    supplierAssesmentToUpdateTokenAtom
  );
  const setSupplierAssesmentAnswers = useSetAtom(supplierAssesmentAnswersAtom);
  const setSupplierAssesmentComments = useSetAtom(
    supplierAssesmentCommentsAtom
  );
  const setSupplierAssesmentSupplier = useSetAtom(
    supplierAssesmentToUpdateSupplierAtom
  );
  const setSelectedTool = useSetAtom(selectedToolAtom);
  const navigate = useNavigate();

  useEffect(() => {
    // Here we force disconnection in order to use the supplier assesment form as a supplier
    // The session and the related informations have to be reset
    if (Cookies.get(process.env.REACT_APP_COOKIES_TOKEN_SESSION)) {
      Cookies.remove(process.env.REACT_APP_COOKIES_TOKEN_SESSION);
      Cookies.remove(process.env.REACT_APP_COOKIES_AUTHENTICATED_USER_ID);
    }

    setSelectedTool("no-selection");
    setSupplierAssesmentIdToUpdate(supplierAssesmentId);
    setSupplierAssesmentTokenToUpdate(supplierAssesmentToken);
  }, [
    setSelectedTool,
    supplierAssesmentId,
    supplierAssesmentToken,
    setSupplierAssesmentIdToUpdate,
    setSupplierAssesmentTokenToUpdate,
  ]);

  const startAssesment = () => {
    getSupplierAssesment(
      supplierAssesmentId,
      supplierAssesmentToken,
      setSupplierAssesmentAnswers,
      setSupplierAssesmentComments,
      setSupplierAssesmentSupplier
    );

    navigate("/supplier-informations-edition");
  };

  return (
    <>
      <h2 className="text-center margin-top-10vh ">
        {" "}
        <Link to="https://www.alliancecybertech.com" target="_blank">
          <span className="font-family-designosaur">
            Alliance Cyber Technologies
          </span>
        </Link>{" "}
          vous souhaite la
          <br />
          bienvenue dans votre évaluation de fournisseur.
      </h2>

      <div className="margin-top-10vh text-center">
        <button className="light-blue1-button" onClick={() => startAssesment()}>
          Commencer
        </button>
      </div>
    </>
  );
};

export default SupplierAssesmentTokenLandingPage;
