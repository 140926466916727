import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../../../components/Version3/Footer/Footer';

const NIS2Layout = () => {
    return (
        <>
            <div id="nis2-page-container">
                <Outlet />
            </div>

            <footer>
                <Footer />
            </footer>
        </>
    );
};

export default NIS2Layout;