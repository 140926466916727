import React, { useEffect } from "react";
import { JSONPreaudit } from "../../../../../utils/JSON/preauditQuestions";
import PreauditQuestionTemplate from "./PreauditQuestionTemplate";
import { useNavigate } from "react-router-dom";
import {
  areUserInformationsCompletedAtom,
  authUserDetailsAtom,
} from "../../../../../atoms//AuthenticationAtom";
import { useAtom } from "jotai";
import {
  firstTimeAccessAtom,
  isPreauditModifiedAtom,
  preauditAnswersAtom,
} from "../../../../../atoms/PreauditAtom";
import {
  getCurrentUserPreaudit,
  savePreauditInBackend,
} from "../../../../../utils/functions/Version3/functionsForPreauditManagement";
import PreauditProgress from "./PreauditProgress";
import Cookies from "js-cookie";
// import { IoListCircle } from "react-icons/io5";
import { FaClipboardList } from "react-icons/fa";
import { FiSave } from "react-icons/fi";
import womanCreditCard from "../../../../../images/woman-credit-card.png";

const PreauditQuestions = ({ setDisplayPreauditQuestions }) => {
  const navigate = useNavigate();
  const [userDetails, setAuthUserDetails] = useAtom(authUserDetailsAtom);
  const [preauditAnswers, setPreauditAnswers] = useAtom(preauditAnswersAtom);
  useAtom(areUserInformationsCompletedAtom);
  const [isPreauditModified, setIsPreauditmodified] = useAtom(
    isPreauditModifiedAtom
  );
  const [firstTimeAccess, setFirstTimeAccess] = useAtom(firstTimeAccessAtom);

  useEffect(() => {
    window.scrollTo(0, 0);
    getCurrentUserPreaudit(
      Cookies.get(process.env.REACT_APP_COOKIES_AUTHENTICATED_USER_ID),
      setPreauditAnswers
    );
  }, [userDetails, setAuthUserDetails, setPreauditAnswers, navigate]);

  const handleClick = () => {
    savePreauditInBackend(
      Cookies.get(process.env.REACT_APP_COOKIES_AUTHENTICATED_USER_ID),
      preauditAnswers,
      setIsPreauditmodified,
      navigate
    );
  };

  return (
    <>
      <div id="preaudit-questions-container">
        <div className="page-title-container">
          <div className="page-title-icon-and-text">
            <div className="page-title-icon">
              <FaClipboardList />
            </div>
            <div className="page-title-text">Questionnaire ISO 27001</div>
          </div>
        </div>

        <div id="preaudit-questions-list-container">
          <h1>
            Veuillez évaluer chaque question de 0 à 10 (0 = plutôt non, 10 =
            plutôt oui).
          </h1>
          <p className="text-center">
            Vous pourrez sauvegarder avec{" "}
            <span className="padding-top-2px background-color-red border-radius-5px">
              <FiSave size="1rem" color="white" />
            </span>
            .
          </p>

          {firstTimeAccess ? (
            <div id="first-time-access-container">
              <button
                id="first-time-access-button"
                className="preaudit-dashboard-button"
                onClick={() => setFirstTimeAccess(false)}
              >
                COMMENCER ?
              </button>
            </div>
          ) : (
            <>
              <div id="preaudit-questions-form">
                {JSONPreaudit.map((question) => (
                  <PreauditQuestionTemplate
                    question={question}
                    // key={nanoid()}
                    setIsPreauditmodified={setIsPreauditmodified}
                  />
                ))}
              </div>

              <div id="preaudit-questions-illustration-container">
                <img
                  src={womanCreditCard}
                  alt="illustration de cybersécurité"
                  className="preaudit-questions-illustration"
                />
              </div>

              <PreauditProgress handleClick={handleClick} />
            </>
          )}
        </div>

        {/* {isPreauditModified && (
          <>
            
          </>
        )} */}
      </div>
    </>
  );
};

export default PreauditQuestions;
