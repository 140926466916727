import React, { useState } from "react";
import { JSONNIS2Questions } from "../../../../utils/JSON/NIS2FormQuestions";
import {
  NIS2StatusAtom,
  Q1IsInEuropeanUnionAnswerAtom,
  organisationNIS2StatusAtom,
} from "../../../../atoms/NIS2Atom";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import Q2WhatSectorsOfActivity from "./Q2WhatSectorsOfActivity";
import ThanksForUsingOurFormAndSubmit from "./PopUps/ThanksForUsingOurFormAndSubmit";

const Q1IsInEuropeanUnionQuestion = () => {
  const NIS2Status = useAtomValue(NIS2StatusAtom);
  const setOrganisationNIS2Status = useSetAtom(organisationNIS2StatusAtom);
  const [Q1IsInEuropeanUnionAnswer, setQ1IsInEuropeanUnionAnswer] = useAtom(
    Q1IsInEuropeanUnionAnswerAtom
  );

  const [displaySectorsOfActivity, setDisplayOtherSectorsOfActivity] =
    useState(false);

  const [displayThanksPopUp, setDisplayThanksPopUp] = useState(null);

  const handleChangeQ1 = (e) => {
    setQ1IsInEuropeanUnionAnswer(e.target.value);

    if (e.target.value === "non") {
      setOrganisationNIS2Status(NIS2Status[0]);
      setDisplayThanksPopUp(true);
    } else if (e.target.value === "oui") {
      setDisplayOtherSectorsOfActivity(true);
      window.scrollBy({
        top: 250,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div id="Q1IsInEuropeanUnion" className="nis2form-question-container">
        <div className="nis2form-question-content">
          {JSONNIS2Questions[0].content}
        </div>
        <div className="nis2form-answers-container">
          <label className="nis2-form-answers">
            <input
              className="nis2-form-answers-input"
              type="radio"
              value="oui"
              checked={Q1IsInEuropeanUnionAnswer === "oui"}
              onChange={handleChangeQ1}
            />
            Oui
          </label>
          <label className="nis2-form-answers">
            <input
              className="nis2-form-answers-input"
              type="radio"
              value="non"
              checked={Q1IsInEuropeanUnionAnswer === "non"}
              onChange={handleChangeQ1}
            />
            Non
          </label>
        </div>
      </div>

      {displaySectorsOfActivity && <Q2WhatSectorsOfActivity />}

      {displayThanksPopUp && <ThanksForUsingOurFormAndSubmit />}
    </>
  );
};

export default Q1IsInEuropeanUnionQuestion;
