import React from "react";
import { useAtomValue } from "jotai";
import { auditRGPDProgressAtom } from "../../../../../atoms/AuditRGPDAtom ";

const AuditRGPDQuestionStatus = ({ questionId }) => {
  const progress = useAtomValue(auditRGPDProgressAtom);

  return (
    <>
      <div
        className={
          progress && progress[questionId]
            ? "one-status-container background-color-green "
            : "one-status-container background-color-white"
        }
      >
        <span
          className={
            progress && progress[questionId]
              ? "status-answered "
              : "status-not-answered"
          }
        >
          {questionId}
        </span>
      </div>
    </>
  );
};

export default AuditRGPDQuestionStatus;
