import React from "react";

const ToolName = ({ selectedTool }) => {
  return (
    <>
      <div id="navbar-tool-name-container">
        <span id="navbar-tool-name">
          {selectedTool === "preaudit" && (
            <>
              AUDIT PRÉLIMINAIRE
              <br />
              ISO 27001
            </>
          )}
          {selectedTool === "supplier-assesment" && <>ÉVALUATION<br/>FOURNISSEUR</>}
          {selectedTool === "audit-rgpd" && <>AUDIT<br/>RGPD</>}
        </span>
      </div>
    </>
  );
};

export default ToolName;
