import React, { useEffect, useState } from "react";

const SupplierInformationsEditionFormErrorMessages = ({
  phoneInputValidity,
  siretInputValidity,
  dateInputValidity,
  isCaptchaValid,
  setDisplayErrorMessages,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const defineErrorMessage = () => {
      !phoneInputValidity &&
        setErrorMessage(
          "Le numéro de téléphone doit être au format 0102030405."
        );

      !siretInputValidity &&
        setErrorMessage("Le numéro SIRET doit être composé de 14 chiffres.");

      !dateInputValidity &&
        setErrorMessage(
          "Pour être rappelé, veuillez choisir un jour ouvrable (du lundi au vendredi, entre 9h et 17h59)."
        );

      !isCaptchaValid &&
        setErrorMessage("Erreur dans le captcha, merci de recomencer.");
    };

    defineErrorMessage();
  }, [
    phoneInputValidity,
    siretInputValidity,
    dateInputValidity,
    isCaptchaValid,
  ]);

  return (
    <>
      <div id="supplier-informations-edition-form-error-modal">
        <div id="supplier-informations-edition-form-error-container">
          <div id="supplier-informations-edition-form-error">{errorMessage}</div>
          <div
            id="supplier-informations-edition-form-error-close-button-container"
            onClick={() => setDisplayErrorMessages(false)}
          >
            <button id="supplier-informations-edition-form-error-close-button">Fermer</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierInformationsEditionFormErrorMessages;
