import React, { useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import ResetInstructionsFormV3 from "../../ResetPasswordV3/ResetInstructionsFormV3";
import { cleanInput } from "../../../../utils/functions/Version3/functionsForFormsInputsManagement";
import { signIn } from "../../../../utils/functions/Version3/functionsForUserSessionManagement";
import AuthenticationFailed from "./SignInUpErrorMessages/AuthenticationFailed";

const SignInFormV3 = ({
  whichFormToDisplay,
  currentURL,
  setSelectedTool,
  setIsPreauditSelected,
  setIsSupplierAssesmentSelected,
  setIsAuditRGPDSelected,
}) => {
  const navigate = useNavigate();
  const [authenticationStatus, setAuthenticationStatus] = useState(false);
  const [displayResetInstructionsForm, setDisplayResetInstructionsForm] =
    useState(false);
  const [isSigninLoading, setIsSigninLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const login = cleanInput(e.target.email.value);
    const password = cleanInput(e.target.password.value);

    signIn(
      login,
      password,
      setAuthenticationStatus,
      navigate,
      setIsSigninLoading,
      currentURL,
      setSelectedTool,
      setIsPreauditSelected,
      setIsSupplierAssesmentSelected,
      setIsAuditRGPDSelected
    );
  };

  return (
    <div id="signin-form-container">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div>
          <h1 className="font-dark-blue1 text-center">Connexion</h1>
        </div>
        <div className="user-name-container">
          <input
            required
            maxLength="50"
            type="email"
            name="email"
            placeholder="Identifiant (email)"
            className="signinup-text-input"
          />
        </div>
        <div className="password-container">
          <input
            required
            maxLength="50"
            type="password"
            name="password"
            placeholder="Mot de passe"
            className="signinup-text-input"
          />
        </div>
        {isSigninLoading ? (
          <>
            <div className="text-center">
              <ColorRing
                visible={true}
                height="50"
                width="50"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#232233", "#2b2c40", "#232747", "#5D87FF", "#539BFF"]}
              />
            </div>
          </>
        ) : (
          <>
            <div className="submit-button-container">
              <button
                type="submit"
                className="font-family-designosaur submit-button"
              >
                OK
              </button>
            </div>
          </>
        )}
        <div className="forgotten-password-container">
          <span
            className="font-dark-blue1 font-family-designosaur cursor-pointer"
            onClick={() => setDisplayResetInstructionsForm(true)}
          >
            Mot de passe perdu ?
          </span>
        </div>
        <div id="signup-link-container">
          <span
            className="font-dark-blue1 font-family-designosaur cursor-pointer"
            onClick={() => whichFormToDisplay("signup")}
          >
            Pas de compte ?
          </span>
        </div>
      </form>

      <div>
        {authenticationStatus && (
          <AuthenticationFailed
            setAuthenticationStatus={setAuthenticationStatus}
          />
        )}
      </div>

      {displayResetInstructionsForm && (
        <>
          <ResetInstructionsFormV3
            setDisplayResetInstructionsForm={setDisplayResetInstructionsForm}
          />
        </>
      )}
    </div>
  );
};

export default SignInFormV3;
