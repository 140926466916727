// Functions to SEND an assesment link to supplier
import Cookies from "js-cookie";

// Function that send a link to suppliers towards their assesment form
export const sendAssesmentLink = (
  // supplierEmail,
  // supplierName,
  // setDisplayAssesmentNotification
  supplierId
) => {
  fetch(
    process.env.REACT_APP_BACKEND_URL +
      "supplier_assesments/assesment_link_request",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: Cookies.get(process.env.REACT_APP_COOKIES_TOKEN_SESSION),
      },
      body: JSON.stringify({
        supplier_assesment: { supplier_id: supplierId },
      }),
    }
  ).then((response) => {
    // console.log("RESPONSE from sendResetInstructions :\n", response);
    response.status === 200
      ? alert(`L'évalutation de fournisseur a bien été envoyée.`)
      : alert(
          `Opération non réalisée : écrivez à ${process.env.REACT_APP_ACT_MAIL_CONTACT} si le problème persiste.`
        );
  });
  // .then(() => setDisplayAssesmentNotification(false));
  // .catch(error => console.error("askResetInstructions -> ERROR", error.message))
};

// Function that GETS all supplier assesments for a single supplier, but only those required by the current user.
export const getSupplierAssesmentsListing = (
  supplierId,
  setSupplierAssesmentsListing
) => {
  fetch(
    process.env.REACT_APP_BACKEND_URL +
      `get_supplier_all_assesments/${supplierId}`,
    {
      method: "GET",
      headers: {
        Authorization: Cookies.get(process.env.REACT_APP_COOKIES_TOKEN_SESSION),
      },
    }
  )
    .then((response) => {
      // console.log("RESPONSE from getSupplierAssesmentsListing :\n", response);
      return response.json();
    })
    .then((data) => {
      // console.log("DATA from getSupplierAssesmentsListing :\n", data);
      setSupplierAssesmentsListing(data);
    });
};

// Function that GET a supplier assesment.
export const getSupplierAssesment = (
  supplierAssesmentId,
  supplierAssesmentToken,
  setSupplierAssesmentAnswers,
  setSupplierAssesmentComments,
  setSupplierAssesmentSupplier
) => {
  fetch(
    process.env.REACT_APP_BACKEND_URL + "supplier_assesments/get_by_token",
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Cookies.get(
          process.env.REACT_APP_COOKIES_TOKEN_SESSION
        )}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        supplier_assesment: {
          id: supplierAssesmentId,
          token: supplierAssesmentToken,
        },
      }),
    }
  )
    .then((response) => {
      // console.log("RESPONSE from getSupplierAssesment :\n", response);
      return response.json();
    })
    .then((data) => {
      // console.log("DATA from getSupplierAssesment :\n", data);
      setSupplierAssesmentAnswers(data.supplier_assesment.answers);
      setSupplierAssesmentComments(data.supplier_assesment.comments);
      setSupplierAssesmentSupplier(data.supplier);
    });
};

// Function that UPDATE a supplier assesment.
export const updateSupplierAssesmentByToken = (
  supplierAssesmentId,
  supplierAssesmentToken,
  supplierAssesmentAnswers,
  supplierAssesmentComments,
  sendFinishNotification
) => {
  // console.log("TOKEN : ", supplierAssesmentToken);
  fetch(
    process.env.REACT_APP_BACKEND_URL + "supplier_assesments/update_by_token",
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${Cookies.get(
          process.env.REACT_APP_COOKIES_TOKEN_SESSION
        )}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        supplier_assesment: {
          id: supplierAssesmentId,
          token: supplierAssesmentToken,
          answers: supplierAssesmentAnswers,
          comments: supplierAssesmentComments,
        },
      }),
    }
  )
    .then((response) => {
      // console.log("RESPONSE from updateSupplierAssesmentByToken :\n", response);
      return response.json();
    })
    .then((data) => {
      // console.log("DATA from updateSupplierAssesmentByToken :\n", data);
      if (data.message === "Token valid.") {
        alert("Enregistrement terminé.");
        if (
          window.confirm(
            "Souhaitez-vous informer votre partenaire que l'évaluation est terminée ?"
          )
        ) {
          sendFinishNotification(supplierAssesmentId);
        }
      } else
        alert(
          `Enregistrement échoué. \n\nSi le problème persiste, envoyez un mail à :\n${process.env.REACT_APP_ACT_MAIL_CONTACT}`
        );
    });
};

//Function that send an email notification to user requesting the supplier assesment, in order to inform that the supplier assesment is done.
export const sendFinishNotification = (supplierAssesmentToUpdateId) => {
  fetch(
    process.env.REACT_APP_BACKEND_URL +
      "supplier_assesment_is_finished_notification",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        supplier_assesment: { id: supplierAssesmentToUpdateId },
      }),
    }
  ).then((response) => {
    // console.log("RESPONSE from sendFinishNotification :\n", response);
    response.status === 200
      ? alert(
          "La notification a bien été envoyée. Merci d'avoir rempli notre évaluation fournisseur."
        )
      : alert(
          `Opération non réalisée : écrivez à ${process.env.REACT_APP_ACT_MAIL_CONTACT} si le problème persiste.`
        );
  });
  // .catch(error => console.error("sendFinishNotification -> ERROR", error.message))
};
