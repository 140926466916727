export const JSONNIS2Questions = [
  {
    id: "1",
    // theme : "politique de sécurité de l'info",
    title: "Q1_IsInEuropeanUnion",
    content:
      "Votre organisation, qu’elle soit privée ou publique, propose-t-elle des services ou exerce-t-elle ses activités au sein de l'Union Européenne ?",
  },

  {
    id: "2",
    // theme : "rôles et responsabilités",
    title: "Q2_WhatSectorsOfActivity",
    content:
      "Votre organisation opère-t-elle dans un ou plusieurs des secteurs suivants ?",
  },

  {
    id: "3",
    // theme : "contact autorités",
    title: "Q3_WhatOtherSectorsOfActivity",
    content:
      "Votre organisation opère-t-elle dans un ou plusieurs des secteurs suivants ?",
  },

  {
    id: "4",
    // theme : "classification",
    title: "Q4_WhatSizeAndRevenue",
    content:
      "Votre entreprise est-elle classifiée comme une organisation de taille moyenne à grande (effectif dépassant les 50 employés et chiffre d’affaires annuel ou bilan annuel supérieur à 10 M€) ?",
  },

  {
    id: "5",
    // theme : "inventaires",
    title: "Q5_WhichOnesAreCorrect",
    content:
      "Une ou plusieurs des affirmations suivantes s'appliquent-elles à votre organisation ?",
  },

  {
    id: "6",
    // theme : "prêt des équipements",
    title: "Q6_WhichOnesAreCorrect",
    content:
      "Votre entité exerce-t-elle ses activités sans l'un des domaines suivants ?",
  },
];
