import React from "react";
import { useNavigate } from "react-router-dom";
import { useAtomValue, useSetAtom } from "jotai";
import { authUserDetailsAtom } from "../../../../../atoms/AuthenticationAtom";
import {
  auditRGPDAnswersAtom,
  auditRGPDCommentsAtom,
  isAuditRGPDModifiedAtom,
} from "../../../../../atoms/AuditRGPDAtom ";
import { saveAuditRGPDInBackend } from "../../../../../utils/functions/Version3/functionsForAuditRGPDManagement";

const AuditRGPDValidationPrompt = ({ setDisplayAuditRGPDValidationPrompt }) => {
  const navigate = useNavigate();
  const authUserDetails = useAtomValue(authUserDetailsAtom);
  const auditRGPDAnswers = useAtomValue(auditRGPDAnswersAtom);
  const auditRGPDDComments = useAtomValue(auditRGPDCommentsAtom);
  const setIsAuditRGPDmodified = useSetAtom(isAuditRGPDModifiedAtom);

  const handleValidate = () => {
    saveAuditRGPDInBackend(
      authUserDetails.id,
      auditRGPDAnswers,
      auditRGPDDComments,
      setIsAuditRGPDmodified,
      navigate
    );

    setDisplayAuditRGPDValidationPrompt(false);
  };

  const handleCancel = () => {
    setDisplayAuditRGPDValidationPrompt(false);
    navigate("/audit-rgpd-questions");
  };

  return (
    <>
      <div id="audit-rgpd-validation-prompt-modal">
        <div id="audit-rgpd-validation-prompt">
          <div className="font-dark-blue1 margin-bottom-30px">
            Vous devez valider vos réponses avant de poursuivre vers le tableau
            de bord.
          </div>

          <div id="audit-rgpd-validation-prompt-buttons-container">
            <button
              type="submit"
              id="audit-rgpd-validation-prompt-submit-button"
              onClick={() => handleValidate()}
            >
              VALIDER
            </button>
            <button
              id="audit-rgpd-validation-prompt-cancel-button"
              onClick={() => handleCancel()}
            >
              ANNULER
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuditRGPDValidationPrompt;
