import Cookies from "js-cookie";

// Function to GET current users' audit rgpd
export const getCurrentUserAuditRGPD = (
  userId,
  setAuditRGPDAnswers,
  setAuditRGPDComments
) => {
  fetch(process.env.REACT_APP_BACKEND_URL + `audit_rgpds/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: Cookies.get(process.env.REACT_APP_COOKIES_TOKEN_SESSION),
    },
  })
    .then((response) => {
      // console.log("RESPONSE from getCurrentUserAuditRGPD :\n", response);
      return response.json();
    })
    .then((data) => {
      // console.log("DATA from getCurrentUserAuditRGPD :\n", data);
      setAuditRGPDAnswers(data.audit_rgpd.answers);
      setAuditRGPDComments(data.audit_rgpd.comments);
    });
  // .catch((error) => console.log(error.message));
};

// Function that UPDATE the audit rgpd answers in backend
export const saveAuditRGPDInBackend = (
  userId,
  auditRGPDAnswers,
  auditRGPDComments,
  setIsAuditRGPDmodified,
  navigate
) => {
  // console.log("userId = ", userId);
  // console.log("auditRGPDAnswers = ", auditRGPDAnswers);

  fetch(process.env.REACT_APP_BACKEND_URL + `audit_rgpds/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: Cookies.get(process.env.REACT_APP_COOKIES_TOKEN_SESSION),
    },

    body: JSON.stringify({
      audit_rgpd: {
        user_id: userId,
        answers: { ...auditRGPDAnswers }, // Use this to create a hash in Rails
        comments: { ...auditRGPDComments },
        // ...preauditStorage, [`${question.id}. ${question.theme}`]: parseInt(value), // Use this to update only one value of the hash in Rails
      },
    }),
  })
    .then((response) => {
      // console.log("RESPONSE from saveAuditRGPDInBackend :\n", response);
      if (!response.ok) {
        throw new Error(`Erreur de sauvegarde: ${response.statusText}`);
      }
      return response.json();
    })
    .then((data) => {
      // console.log("DATA from saveAuditRGPDInBackend :\n", data);
      setIsAuditRGPDmodified(false);
      alert("Vos réponses sont enregistrées.");
      navigate("/dashboard");
    })
    .catch((error) => {
      alert(`Erreur de sauvegarde : ${error.message}`);
      // console.log(error.message)
    });
};

// Function that RESET the audit rgpd answers in backend
// export const resetPreauditInBackend = (
//   userId,
//   preauditAnswers,
//   // setIsPreauditmodified,
//   // navigate
// ) => {
//   // console.log("userId = ", userId);
//   // console.log("prepreauditAnswers = ", preauditAnswers);

//   fetch(process.env.REACT_APP_BACKEND_URL + `pre_audits/${userId}`, {
//     method: "PUT",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: Cookies.get(process.env.REACT_APP_COOKIES_TOKEN_SESSION),
//     },

//     body: JSON.stringify({
//       pre_audit: {
//         user_id: userId,
//         answers: { ...preauditAnswers }, // Use this to create a hash in Rails
//         // ...preauditStorage, [`${question.id}. ${question.theme}`]: parseInt(value), // Use this to update only one value of the hash in Rails
//       },
//     }),
//   })
//     .then((response) => {
//       // console.log("RESPONSE from savePrauditInBackend :\n", response);
//       return response.json();
//     })
//     .then((data) => {
//       // console.log("DATA from savePrauditInBackend :\n", data);
//       // setIsPreauditmodified(false);
//       // navigate("/preaudit-dashboard");
//     });
//   // .catch((error) => console.log(error.message));
// };
