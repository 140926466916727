export const supplierAuditCategories = [
        {
            id:"1",
            name:"Identifier",
        },

        {
            id:"2",
            name:"Protéger",
        },

        {
            id:"3",
            name:"Détecter",
        },

        {
            id:"4",
            name:"Réagir",
        },
]

export const supplierAuditThemes = [
    {
        id:"1",
        name: "Conformité",
    },

    {
        id:"2",
        name: "Protection des données à caractère personnel",
    },

    {
        id:"3",
        name: "Gouvernance",
    },

    {
        id:"4",
        name: "Gestion des actifs",
    },

    {
        id:"5",
        name: "Évaluation des risques",
    },

    {
        id:"6",
        name: "Ressources humaines",
    },

    {
        id:"7",
        name: "Gestion des accès",
    },

    {
        id:"8",
        name: "Protection de l’information",
    },

    {
        id:"9",
        name: "Gestion du réseau & sécurité mobile",
    },

    {
        id:"10",
        name: "Sécurité dans les projets & les applications",
    },

    {
        id:"11",
        name: "Sécurité de l’infrastructure",
    },

    {
        id:"12",
        name: "Protection physique",
    },

    {
        id:"13",
        name: "Gestion des tiers",
    },

    {
        id:"14",
        name: "Sensibilisation & information",
    },


    {
        id:"15",
        name: "Enregistrement et surveillance",
    },

    {
        id:"16",
        name: "Processus de détection",
    },

    {
        id:"17",
        name: "Anomalies & évènements",
    },


    {
        id:"18",
        name: "Gestion des incidents",
    },

    {
        id:"19",
        name: "Collecte de preuves",
    },
]


export const supplierAudit = [
    {
        id: "1",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[0].name,
        content : "Vous assurez-vous de la conformité aux lois et réglementations sur la sécurité de l'information ?"

    },

    {
        id: "2",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[1].name,
        content : "Votre société s'engage-t-elle dans la protection des données à caractère personnel ?"

    },

    {
        id: "3",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[1].name,
        content : "Les rôles et responsabilités relatifs à la protection des données à caractère personnel sont-ils définis ?"

    },

    {
        id: "4",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[1].name,
        content : "Les collaborateurs sont-ils formés aux bonnes pratiques liées à la protection des données à caractère personnel ?"

    },

    {
        id: "5",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[1].name,
        content : "Les traitements de données à caractère personnel sont-ils identifiés ?"

    },

    {
        id: "6",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[1].name,
        content : "Les personnes sont-elles informées lorsque vous traitez de données à caractère personnel les concernant ?"

    },

    {
        id: "7",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[1].name,
        content : "La conformité des contrats et des achats est-elle assurée en ce qui concerne la protection des données à caractère personnel ?"

    },

    {
        id: "8",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[1].name,
        content : "Vous assurez vous d'une durée adéquate de conservation des données à caractère personnel ?"

    },

    {
        id: "9",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[1].name,
        content : "Les transferts de données à caractère personnel aux tiers se conforment-ils aux exigences de respect de la vie privée ?"

    },

    {
        id: "10",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[1].name,
        content : "Des processus de notification de violation des données à caractère personnel sont-ils mis en œuvre ?"

    },

    {
        id: "11",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[2].name,
        content : "Avez-vous une politique générale de sécurité de l'information ?"

    },

    {
        id: "12",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[2].name,
        content : "Les rôles et responsabilités relatifs à la sécurité de l'information sont-ils gérés ?"

    },

    {
        id: "13",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[2].name,
        content : "Votre organisation a-t-elle obtenu ou est-elle en cours d'obtention d'une certification concernant la sécurité de l'information ?"

    },

    {
        id: "14",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[3].name,
        content : "Les données sont-elles inventoriées et classifiées ?"

    },

    {
        id: "15",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[3].name,
        content : "Les appareils physiques sont-ils inventoriés et classifiés ?"

    },

    {
        id: "16",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[3].name,
        content : "Les applications métier (incluant les applications traitant des données de clients) sont-elles inventoriées et classifiées ?"

    },

    {
        id: "17",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[4].name,
        content : "Des analyses de risques sont-elles réalisées ?"

    },

    {
        id: "18",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[5].name,
        content : "Les nouvelles recrues, les sous-traitants et les employés temporaires signent-ils un code d’éthique ou un accord de confidentialité ?"

    },

    {
        id: "19",
        category:supplierAuditCategories[0].name,
        theme : supplierAuditThemes[5].name,
        content : "Les antécédents des nouvelles recrues, des sous-traitants et des employés temporaires sont-ils vérifiés ?"

    },

    {
        id: "20",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[6].name,
        content : "Les identités et les accès des utilisateurs sont-ils gérés ?"

    },

    {
        id: "21",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[6].name,
        content : "Les mots de passe sont-ils forts (selon les recommandations de l'ANSSI) ou existent-ils des authentifications multiples ?"

    },

    {
        id: "22",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[6].name,
        content : "L’accès aux systèmes d’information critiques est-il protégé par des mécanismes d’authentification avancés ?"

    },

    {
        id: "23",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[7].name,
        content : "Les données sensibles (incluant les données des clients) sont-elles protégées ?"

    },

    {
        id: "24",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[7].name,
        content : "Les postes de travail et supports de stockage (autorisés par l’entreprise) sont-ils protégés ?"

    },

    {
        id: "25",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[7].name,
        content : "Les sauvegardes sont-elles réalisées ?"

    },

    {
        id: "26",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[7].name,
        content : "Les plans de continuité des activités sont-ils gérés ?"

    },

    {
        id: "27",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[7].name,
        content : "Assurez-vous une implémentation efficace des mesures de chiffrement pour protéger les données ?"

    },

    {
        id: "28",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[7].name,
        content : "Avez-vous implémenté un processus de gestion des changements ?"

    },

    {
        id: "29",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[8].name,
        content : "Avez-vous élaboré un plan de gestion de la capacité ?"

    },

    {
        id: "30",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[8].name,
        content : "Le réseau est-il sécurisé ?"

    },

    {
        id: "31",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[8].name,
        content : "Les flux sur le réseau sont-ils gérés ?"

    },

    {
        id: "32",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[8].name,
        content : "Le réseau sans-fil est-il sécurisé ?"

    },

    {
        id: "33",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[8].name,
        content : "L'utilisation des appareils personnels est-elle contrôlée ?"

    },

    {
        id: "34",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[8].name,
        content : "L'accès à distance est-il sécurisé ?"

    },

    {
        id: "35",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[8].name,
        content : "L'utilisation des appareils mobiles est-elle administrée selon de bonnes pratiques de sécurité ?"

    },

    {
        id: "36",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[9].name,
        content : "La sécurité de l'information est-elle intégrée dans les projets ?"

    },

    {
        id: "37",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[9].name,
        content : "Faites-vous des scans de vulnérabilités du code des applications développées (OWASP, etc.) ?"

    }
    ,

    {
        id: "38",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[9].name,
        content : "Le patching des applications est-il réalisé ?"

    }
    ,

    {
        id: "39",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[10].name,
        content : "Les serveurs sont-ils sécurisés et audités ?"

    }
    ,

    {
        id: "40",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[10].name,
        content : "Les correctifs de sécurité sont-ils appliqués sur vos infrastructures ?"

    }
    ,

    {
        id: "41",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[10].name,
        content : "Des mesures sont-elles en place pour éviter le téléchargement de logiciels ou de fichiers malveillants ?"

    }
    ,

    {
        id: "42",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[10].name,
        content : "La messagerie électronique est-elle protégée contre les spams et le phishing ?"

    }
    ,

    {
        id: "43",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[11].name,
        content : "Assurez-vous la gestion des accès physiques aux locaux et zones sensibles ?"

    }
    ,

    {
        id: "44",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[12].name,
        content : "L'analyse des risques liés aux tiers est-elle réalisée ?"

    }
    ,

    {
        id: "45",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[12].name,
        content : "L'accès des tiers à votre système d'information est-il encadré par les équipes sécurité ?"

    }
    ,

    {
        id: "46",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[12].name,
        content : "La prestation avec un tiers est-elle contractualisée ?"

    }
    ,

    {
        id: "47",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[12].name,
        content : "Réalisez-vous une analyse des risques spécifique afin d'évaluer les tiers qui fournissent des solutions cloud ?"

    }
    ,

    {
        id: "48",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[12].name,
        content : "Gérez-vous la mise en œuvre des pratiques de développement sécurisé auprès des sous-traitants concernés ?"

    }
    ,

    {
        id: "49",
        category:supplierAuditCategories[1].name,
        theme : supplierAuditThemes[13].name,
        content : "Les employés sont-ils sensibilisés et formés aux risques liés à la sécurité informatique ?"

    }
    ,

    {
        id: "50",
        category:supplierAuditCategories[2].name,
        theme : supplierAuditThemes[14].name,
        content : "Assurez-vous la journalisation des activités ?"

    }
    ,

    {
        id: "51",
        category:supplierAuditCategories[2].name,
        theme : supplierAuditThemes[15].name,
        content : "Les événements de sécurité sont-ils détectés ?"

    }
    ,

    {
        id: "52",
        category:supplierAuditCategories[2].name,
        theme : supplierAuditThemes[15].name,
        content : "Les systèmes d'information sensibles sont-ils audités ou des tests d'intrusions sont-ils faits ?"

    }
    ,

    {
        id: "53",
        category:supplierAuditCategories[2].name,
        theme : supplierAuditThemes[16].name,
        content : "Des processus de détection dans le cas d'une cyberattaque sont-ils mis en oeuvre ?"

    }
    ,

    {
        id: "54",
        category:supplierAuditCategories[3].name,
        theme : supplierAuditThemes[17].name,
        content : "Existe-t-il une cellule de gestion de crise dans votre entreprise ?"

    }
    ,

    {
        id: "55",
        category:supplierAuditCategories[3].name,
        theme : supplierAuditThemes[17].name,
        content : "Réalisez-vous des simulations de crises périodiquement ?"

    }
    ,

    {
        id: "56",
        category:supplierAuditCategories[3].name,
        theme : supplierAuditThemes[18].name,
        content : "Existe-t-il un processus de collecte de preuves en cas de cyberattaque ?"

    }

]