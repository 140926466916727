import React from "react";
import StrongPasswordInstructionsV3 from "../StrongPasswordInstructionsV3";

const PasswordIsWeak = ({ setIsPasswordStrong }) => {
  return (
    <>
      <div className="signinup-error-message-modal">
        <div className="signinup-error-message-container">
          <div className="margin-bottom-30px">
            <span className="font-dark-blue1">
              Votre mot de passe est trop faible.
            </span>
          </div>

          <StrongPasswordInstructionsV3 />

          <div
            className="signinup-error-message-close-button-container"
            onClick={() => setIsPasswordStrong(true)}
          >
            <button>Fermer</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordIsWeak;
