import React, { useEffect, useState } from "react";
import {
  cleanInput,
  validatePhoneFormat,
} from "../../../../utils/functions/Version3/functionsForFormsInputsManagement";
import { sendNIS2StatusThroughBackend } from "../../../../utils/functions/Version3/functionsForNIS2Management";
import { useAtomValue } from "jotai";
import {
  Q1IsInEuropeanUnionAnswerAtom,
  Q2WhatSectorsOfActivityAtom,
  Q3WhatEnergySubSectorsAtom,
  Q3WhatNumericInfrastructureSubSectorsAtom,
  Q3WhatOtherSectorsOfActivityAtom,
  Q3WhatPublicAdministrationSubSectorsAtom,
  Q3WhatTICServicesSubSectorsAtom,
  Q3WhatTransportSubSectorsAtom,
  Q4NoneFromOtherSectorsSubSectorsAtom,
  Q4WhatManufacturingSubSectorsAtom,
  Q4WhatSizeAndRevenueAtom,
  Q5WhichOnesAreCorrectAtom,
  Q6WhatActivitiesAtom,
  organisationNIS2StatusAtom,
} from "../../../../atoms/NIS2Atom";
import { RiMailSendFill } from "react-icons/ri";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { CgOrganisation } from "react-icons/cg";
import { FaPhoneVolume, FaUser } from "react-icons/fa";
import { ColorRing } from "react-loader-spinner";
import EmailSentConfirmation from "./PopUps/EmailSentConfirmation";
import EmailSentError from "./PopUps/EmailSentError";

const SubmitAnswersForm = () => {
  const organisationNIS2Status = useAtomValue(organisationNIS2StatusAtom);
  const Q1IsInEuropeanUnionAnswer = useAtomValue(Q1IsInEuropeanUnionAnswerAtom);
  const Q2WhatSectorsOfActivity = useAtomValue(Q2WhatSectorsOfActivityAtom);
  const Q3WhatOtherSectorsOfActivity = useAtomValue(
    Q3WhatOtherSectorsOfActivityAtom
  );
  const Q3WhatEnergySubSectors = useAtomValue(Q3WhatEnergySubSectorsAtom);
  const Q3WhatTransportSubSectors = useAtomValue(Q3WhatTransportSubSectorsAtom);
  const Q3WhatNumericInfrastructureSubSectors = useAtomValue(
    Q3WhatNumericInfrastructureSubSectorsAtom
  );
  const Q3WhatTICServicesSubSectors = useAtomValue(
    Q3WhatTICServicesSubSectorsAtom
  );
  const Q3WhatPublicAdministrationSubSectors = useAtomValue(
    Q3WhatPublicAdministrationSubSectorsAtom
  );
  const Q4WhatManufacturingSubSectors = useAtomValue(
    Q4WhatManufacturingSubSectorsAtom
  );
  const Q4NoneFromOtherSectorsSubSectors = useAtomValue(
    Q4NoneFromOtherSectorsSubSectorsAtom
  );
  const Q4WhatSizeAndRevenue = useAtomValue(Q4WhatSizeAndRevenueAtom);
  const Q5WhichOnesAreCorrect = useAtomValue(Q5WhichOnesAreCorrectAtom);
  const Q6WhatActivities = useAtomValue(Q6WhatActivitiesAtom);

  const [organisationName, setOrganisationName] = useState(null);
  const [organisationEmail, setOrganisationEmail] = useState(null);
  const [organisationFirstName, setOrganisationFirstName] = useState(null);
  const [organisationLastName, setOrganisationLastName] = useState(null);
  const [organisationPhone, setOrganisationPhone] = useState(null);
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const [displayEmailSentConfirmation, setDisplayEmailSentConfirmation] =
    useState(false);
  const [displayEmailSentError, setDisplayEmailSentError] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check phone format
    if (isPhoneFormatValid(organisationPhone)) {
      sendNIS2StatusThroughBackend(
        organisationNIS2Status,
        Q1IsInEuropeanUnionAnswer,
        Q2WhatSectorsOfActivity,
        Q3WhatOtherSectorsOfActivity,
        Q3WhatEnergySubSectors,
        Q3WhatTransportSubSectors,
        Q3WhatNumericInfrastructureSubSectors,
        Q3WhatTICServicesSubSectors,
        Q3WhatPublicAdministrationSubSectors,
        Q4WhatManufacturingSubSectors,
        Q4NoneFromOtherSectorsSubSectors,
        Q4WhatSizeAndRevenue,
        Q5WhichOnesAreCorrect,
        Q6WhatActivities,
        organisationName,
        organisationEmail,
        organisationFirstName,
        organisationLastName,
        organisationPhone,
        setIsRequestLoading,
        setDisplayEmailSentConfirmation,
        setDisplayEmailSentError
      );
    } else {
      alert(
        "Le format du numéro de téléphone semble incorrect.\nEntrez un numéro de type 0611223344"
      );
    }
  };

  const isPhoneFormatValid = (phoneNumber) => {
    const validationResult = validatePhoneFormat(phoneNumber);
    return validationResult ? true : false;
  };

  return (
    <>
      <div id="nis2-submit-form-modal">
        <form onSubmit={handleSubmit} id="nis2-submit-form">
          <h2 className="text-center font-dark-blue1 font-family-nunito-variablefont">
            Où devons nous envoyer vos résultats ?
            <div>
              <RiMailSendFill size="100px" color="#539bff" />
            </div>
          </h2>

          <div id="nis2-submit-form-fields-container">
            <div className="nis2-submit-form-label-container">
              <MdOutlineAlternateEmail color="#539bff" />
              <div>
                <label htmlFor="email" className="nis2-submit-form-label">
                  email*
                </label>
              </div>
            </div>
            <div className="nis2-submit-form-input-container">
              <input
                className="nis2-submit-form-input"
                type="email"
                name="email"
                maxLength="100"
                required
                onInput={(e) =>
                  setOrganisationEmail(cleanInput(e.target.value))
                }
              />
            </div>

            <div className="nis2-submit-form-label-container">
              <CgOrganisation color="#539bff" />
              <div>
                <label
                  htmlFor="organisation"
                  className="nis2-submit-form-label"
                >
                  organisation*
                </label>
              </div>
            </div>
            <div className="nis2-submit-form-input-container">
              {" "}
              <input
                className="nis2-submit-form-input"
                type="text"
                name="organisation"
                maxLength="100"
                required
                onInput={(e) => setOrganisationName(cleanInput(e.target.value))}
              />
            </div>

            <div className="nis2-submit-form-label-container">
              {" "}
              <FaUser color="#539bff" />
              <div>
                <label htmlFor="firstname" className="nis2-submit-form-label">
                  prénom
                </label>
              </div>
            </div>
            <div className="nis2-submit-form-input-container">
              {" "}
              <input
                className="nis2-submit-form-input"
                type="text"
                name="firstname"
                maxLength="100"
                // required
                onInput={(e) =>
                  setOrganisationFirstName(cleanInput(e.target.value))
                }
              />
            </div>

            <div className="nis2-submit-form-label-container">
              <FaUser color="#539bff" />
              <div>
                <label htmlFor="lastname" className="nis2-submit-form-label">
                  nom
                </label>
              </div>
            </div>
            <div className="nis2-submit-form-input-container">
              {" "}
              <input
                className="nis2-submit-form-input"
                type="text"
                name="lastname"
                maxLength="100"
                // required
                onInput={(e) =>
                  setOrganisationLastName(cleanInput(e.target.value))
                }
              />
            </div>

            <div className="nis2-submit-form-label-container">
              <FaPhoneVolume color="#539bff" />
              <div>
                <label htmlFor="phone" className="nis2-submit-form-label">
                  téléphone*
                </label>
              </div>
            </div>
            <div className="nis2-submit-form-input-container">
              {" "}
              <input
                className="nis2-submit-form-input"
                type="phone"
                name="phone"
                maxLength="10"
                required
                onInput={(e) =>
                  setOrganisationPhone(cleanInput(e.target.value))
                }
              />
            </div>

            <div className="nis2-submit-form-label-container">
              <label
                htmlFor="cgu"
                className="nis2-submit-form-label cgu-validation"
              >
                Accepter les conditions d'utilisation*
              </label>
            </div>

            <div className="nis2-submit-form-input-container">
              <input
                required
                type="checkbox"
                className="nis2-submit-form-input"
              />{" "}
            </div>
          </div>

          <div className="nis2-submit-form-button-container">
            {isRequestLoading ? (
              <>
                <ColorRing
                  visible={true}
                  height="50"
                  width="50"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#232233",
                    "#2b2c40",
                    "#232747",
                    "#5D87FF",
                    "#539BFF",
                  ]}
                />
              </>
            ) : (
              <button className="nis2-submit-form-button" type="submit">
                ENVOYER
              </button>
            )}
          </div>

          <div className="font-dark-blue1 font-size-070rem">
            * champs obligatoires
          </div>

          <div id="nis2-cgu-container">
            <p id="nis2-cgu-title">Conditions d'utilisation</p>

            <p className="nis2-cgu-content ">
              Les informations recueillies dans ce formulaire sont enregistrées
              dans un fichier informatisé par Alliance Cyber Technologies,
              société basée à Lille, et stockés sur des serveurs situés en
              Europe. La base légale du traitement est basée sur une relation
              pré-contractuelle. Les informations demandées dans le formulaire
              doivent obligatoirement être fournies. Dans le cas contraire, il
              vous sera impossible de poursuivre votre demande (inscription,
              campagne faux-phishing, etc.) . Les données collectées seront
              communiquées aux seuls destinataires suivants : Alliance Cyber
              Technologies. Vous pouvez demander à y accéder, les rectifier,
              demander leur effacement ou exercer votre droit à la limitation du
              traitement de vos données. Consultez le site cnil.fr pour plus
              d’informations sur vos droits. Pour exercer ces droits ou pour
              toute question sur le traitement de vos données dans ce
              dispositif, vous pouvez contacter (le cas échéant, notre délégué à
              la protection des données ou le service chargé de l’exercice de
              ces droits) : Alliance Cyber Technologies, Email:
              contact[@]alliancecybertech.com.
            </p>
          </div>
        </form>
      </div>

      {displayEmailSentConfirmation && <EmailSentConfirmation />}
      {displayEmailSentError && <EmailSentError />}
    </>
  );
};

export default SubmitAnswersForm;
