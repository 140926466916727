import React from "react";
import QuestionsButtonSupplierAssesmentMenu from "./components/QuestionsButtonSupplierAssesmentMenu";
import SaveSupplierAssesmentButtons from "./components/SaveSupplierAssesmentButtons";
import { BsArrowUpCircleFill } from "react-icons/bs";

const MenuSupplierAssesmentAnswering = () => {
  return (
    <>
      <div id="supplier-assesment-answering-menu-container">
        <SaveSupplierAssesmentButtons />
        <QuestionsButtonSupplierAssesmentMenu />
      </div>

      <div
        id="back-to-top-container"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <abbr title="Vers le haut de page">
          <BsArrowUpCircleFill size="2rem" color="white" />
        </abbr>
      </div>
    </>
  );
};

export default MenuSupplierAssesmentAnswering;
