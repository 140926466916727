import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";

const EmailSentError = () => {
  return (
    <>
      <div id="nis-pop-up-modal">
        <div id="nis2-pop-up-container">
          <div className="nis2-pop-up-content">
            <p className="text-center font-dark-blue1 font-size-1-50rem margin-bottom-30px">
              Votre email n'a pas pu être envoyé.
            </p>
            Contactez{" "}
            <a
              href="https://www.alliancecybertech.com"
              className="font-dark-blue1 font-weight-bold"
              target="_blank"
              rel="noreferrer"
            >
              Alliance Cyber Technologies
            </a>{" "}
            si le problème persiste.
            {/* <br />
            <a
              href="https://www.alliancecybertech.com/contact"
              className="font-dark-blue1 font-weight-bold"
              target="_blank"
              rel="noreferrer"
            >
              Contact
            </a>{" "} */}
            <div
              className="nis2-pop-up-button-container"
              onClick={() =>
                window.open(
                  "https://www.alliancecybertech.com/contact",
                  "_blank"
                )
              }
            >
              <div className="nis2-pop-up-button-text">CONTACT</div>
              <FaLongArrowAltRight className="nis2-pop-up-button-arrow" />
            </div>
            <div
              className="nis2-pop-up-button-container"
              onClick={() => window.location.assign("../nis2-form")}
            >
              <div className="nis2-pop-up-button-text">ACCUEIL</div>
              <FaLongArrowAltRight className="nis2-pop-up-button-arrow" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailSentError;
