import React from "react";
import {
  sendFinishNotification,
  updateSupplierAssesmentByToken,
} from "../../../../../utils/functions/Version3/functionsForSupplierAssesmentManagement";
import { useAtomValue } from "jotai";
import {
  supplierAssesmentAnswersAtom,
  supplierAssesmentCommentsAtom,
  supplierAssesmentToUpdateIdAtom,
  supplierAssesmentToUpdateTokenAtom,
} from "../../../../../atoms/SupplierAssesmentAtom";
import { RiSaveFill } from "react-icons/ri";

const SaveSupplierAssesmentButtons = () => {
  const supplierAssesmentToUpdateId = useAtomValue(
    supplierAssesmentToUpdateIdAtom
  );
  const supplierAssesmentToUpdateToken = useAtomValue(
    supplierAssesmentToUpdateTokenAtom
  );
  const supplierAssesmentAnswers = useAtomValue(supplierAssesmentAnswersAtom);
  const supplierAssesmentComments = useAtomValue(supplierAssesmentCommentsAtom);

  const handleSave = () => {
    updateSupplierAssesmentByToken(
      supplierAssesmentToUpdateId,
      supplierAssesmentToUpdateToken,
      supplierAssesmentAnswers,
      supplierAssesmentComments,
      sendFinishNotification
    );
  };

  return (
    <>
      <div
        id="supplier-assesment-save-button-container"
        onClick={() => handleSave()}
      >
        <button id="supplier-assesment-save-button">
          <RiSaveFill color="#f76a66" />
        </button>
      </div>
    </>
  );
};

export default SaveSupplierAssesmentButtons;
