import React from "react";
import { supplierAudit } from "../../../../../utils/JSON/supplierAssesmentQuestions";
import { isItEven } from "../../../../../utils/functions/Version3/functionsForNumbers";
import { IoIosCloseCircle } from "react-icons/io";

const SupplierAssesmentQuestionsList = ({ setDisplayQuestionsList }) => {
  return (
    <>
      <div id="supplier-assesment-questions-list-modal">
        <div id="supplier-assesment-questions-list-container">
          <div id="supplier-assesment-questions-list-title-container">
            <h2>
              <div>
                Voici les {supplierAudit.length} questions auxquelles vont
                répondre vos fournisseurs.
              </div>
              <div
                className="cursor-pointer"
                onClick={() => setDisplayQuestionsList(false)}
              >
                <IoIosCloseCircle size="1.75rem" color="#f76a66" />
              </div>
            </h2>
          </div>

          <div id="supplier-assesment-questions-list">
            {supplierAudit.map((question) => (
              <div
                className={`supplier-assesment-question-container ${
                  isItEven(question.id) ? "even-line" : "odd-line"
                }`}
              >
                <div className="supplier-assesment-question-title">
                  {question.id}. {question.theme}
                </div>
                <div className="supplier-assesment-question-content">
                  {question.content}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierAssesmentQuestionsList;
