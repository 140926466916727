import { useAtom, useSetAtom } from "jotai";
import React from "react";
import {
  Q3WhatTransportSubSectorsAtom,
  Q4WhatSizeAndRevenueAtom,
  Q5WhichOnesAreCorrectAtom,
  Q6WhatActivitiesAtom,
  displayQ5WhichOnesAreCorrectAtom,
  displayQ6WhatActivitiesAtom,
} from "../../../../../atoms/NIS2Atom";
import {
  answersManagementForQ2SectorsOfActivity,
  resetQ5WhichOnesAreCorrect,
  resetQ6WhatActivties,
  resetSizeAndRevenue,
} from "../../../../../utils/functions/Version3/functionsForNIS2Management";

const TransportSubSectors = ({ setDisplayQ4WhatSizeAndRevenue }) => {
  const [Q3WhatTransportSubSectors, setQ3WhatTransportSubSectors] = useAtom(
    Q3WhatTransportSubSectorsAtom
  );

  const setQ4WhatSizeAndRevenue = useSetAtom(Q4WhatSizeAndRevenueAtom);

  const setQ5WhichOnesAreCorrect = useSetAtom(Q5WhichOnesAreCorrectAtom);
  const setDisplayQ5WhichOnesAreCorrect = useSetAtom(
    displayQ5WhichOnesAreCorrectAtom
  );

  const setDisplayQ6WhatActivities = useSetAtom(displayQ6WhatActivitiesAtom);

  const setQ6WhatActivities = useSetAtom(Q6WhatActivitiesAtom);

  return (
    <>
      <div className="nis2-sub-sectors-container">
        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="transports aériens"
              checked={Q3WhatTransportSubSectors.includes("transports aériens")}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatTransportSubSectors,
                  setQ3WhatTransportSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Transports aériens
          </label>
        </div>

        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="transports férroviaires"
              checked={Q3WhatTransportSubSectors.includes(
                "transports férroviaires"
              )}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatTransportSubSectors,
                  setQ3WhatTransportSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Transports férroviaires
          </label>
        </div>

        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="transports par eau"
              checked={Q3WhatTransportSubSectors.includes("transports par eau")}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatTransportSubSectors,
                  setQ3WhatTransportSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Transports par eau
          </label>
        </div>

        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="transports routiers"
              checked={Q3WhatTransportSubSectors.includes(
                "transports routiers"
              )}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatTransportSubSectors,
                  setQ3WhatTransportSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Transports routiers
          </label>
        </div>

        <div>
          <label className="nis2-form-sub-answers">
            <input
              className="nis2-form-answers-input"
              type="checkbox"
              name="aucun de la liste"
              checked={Q3WhatTransportSubSectors.includes("aucun de la liste")}
              onChange={(e) =>
                answersManagementForQ2SectorsOfActivity(
                  e,
                  Q3WhatTransportSubSectors,
                  setQ3WhatTransportSubSectors
                )
              }
              onClick={() => {
                resetSizeAndRevenue(
                  setQ4WhatSizeAndRevenue,
                  setDisplayQ4WhatSizeAndRevenue
                );
                resetQ5WhichOnesAreCorrect(
                  setQ5WhichOnesAreCorrect,
                  setDisplayQ5WhichOnesAreCorrect
                );
                resetQ6WhatActivties(
                  setQ6WhatActivities,
                  setDisplayQ6WhatActivities
                );
              }}
            />
            Aucun de la liste
          </label>
        </div>
      </div>
    </>
  );
};

export default TransportSubSectors;
