import sanitizeHtml from "sanitize-html";

// Function that clean inputs forms
export const cleanInput = (dirtyInput) => {
  const cleanCode = sanitizeHtml(dirtyInput, {
    allowedTags: [],
    allowedAttributes: {},
  });

  return cleanCode;
};

/////// Other maner to clean input forms /////////
// export const cleanInput = (input, inputType) => {
//   let forbidden = [];
//   // Define a list of forbidden characters according to the type of data to sanitize.
//   switch (inputType) {
//     case "regular":
//       forbidden = [
//         "@",
//         '"',
//         "#",
//         "$",
//         ")",
//         "*",
//         "+",
//         ":",
//         ";",
//         "{",
//         "|",
//         "~",
//         "?",
//       ];
//       break;
//     case "email":
//       forbidden = ['"', "#", "$", ")", "*", "+", ":", ";", "{", "|", "~", "?"];
//       break;
//     case "password":
//       break;
//     default:
//     // console.log("No case matched.");
//   }

//   // Remove all html tags.
//   let cleaned = sanitizeHtml(input, {
//     allowedTags: [],
//     allowedAttributes: [],
//   });

//   // Check weather 'cleaned' contains any dangerous characters like '$' which is typically used for variables.
//   return cleaned
//     .split("")
//     .some((character) => forbidden.indexOf(character) >= 0)
//     ? false
//     : cleaned;
// };

// Function that check if a phone input is written in an expected format
export const validatePhoneFormat = (phoneNumber) => {
  // const validPhoneFormat = new RegExp(/^0\d{1}\s\d{2}\s\d{2}\s\d{2}\s\d{2}$/);
  const validPhoneFormat = new RegExp(/^0\d{9}$/); // Phone format 0102030405
  return validPhoneFormat.test(phoneNumber);
};

// Function that parse à phone number from 0102030405 to 01 02 03 04 05 (in order to be displayed nicely)
export const parsePhoneNumber = (input) => {
  // Remove all non numerics characters
  const formatted = input.replace(/\D/g, "");

  // Parse into 2 digits couples
  let result = "";
  for (let i = 0; i < formatted.length; i += 2) {
    result += formatted.slice(i, i + 2) + " ";
  }

  // Remove the last space at the end of 'result'
  if (result.endsWith(" ")) {
    result = result.slice(0, -1);
  }

  return result;
};

// Functions that parse a phone input into a phone number like : +33.06.01.02.03.04
export const parsePhoneNumberWithCountryCode = (inputPhone) => {
  var parsedPhone = "";

  for (var i = 0; i < inputPhone.length; i++) {
    if (i > 0 && i % 2 !== 0) {
      parsedPhone += ".";
    }

    parsedPhone += inputPhone.charAt(i);
  }

  parsedPhone = "+" + parsedPhone;
  const countryCode = parsedPhone.slice(0, 5).replace(".", "");
  parsedPhone = countryCode + parsedPhone.slice(5);

  return parsedPhone;
};

// Function that check if a SIRET input is written in an expected format
export const validateSIRETFormat = (siret) => {
  const validSiretFormat = new RegExp(/^\d{14}$/);
  return validSiretFormat.test(siret);
};

// Function to parse a date to an expected format
export const parseDate = (stringDate) => {
  // console.log('timestamp', stringDate)

  const dateObject = new Date(stringDate);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false, // Used for 24h format
    timeZone: "Europe/Paris",
  };

  const parsedDate = dateObject.toLocaleDateString("fr-FR", options);

  // console.log("Date formatée", parsedDate);

  return parsedDate;
};

// Functions related to CAPTCHA
// Generate a 6 random characters string
export const generateCaptcha = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let captcha = "";

  for (let i = 0; i < 6; i++) {
    let randomIndex = Math.floor(Math.random() * characters.length);
    captcha += characters[randomIndex];
  }

  return captcha;
};

// Compare an input with a captcha, return true if there is a match.
export const validateCaptcha = (input, captcha) => {
  return input === captcha ? true : false;
};
