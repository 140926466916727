import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { areUserInformationsCompletedAtom } from "../../../../../atoms/AuthenticationAtom";
import { useAtom } from "jotai";
import Cookies from "js-cookie";
import {
  auditRGPDAnswersAtom,
  auditRGPDCommentsAtom,
  isAuditRGPDModifiedAtom,
} from "../../../../../atoms/AuditRGPDAtom ";
import { auditRGPDQuestions } from "../../../../../utils/JSON/auditRGPDQuestions";
import AuditRGPDQuestionTemplate from "./AuditRGPDQuestionTemplate";
import AuditRGPDProgress from "./AuditRGPDProgress";
import {
  getCurrentUserAuditRGPD,
  saveAuditRGPDInBackend,
} from "../../../../../utils/functions/Version3/functionsForAuditRGPDManagement";
import { FaClipboardList } from "react-icons/fa";
import { firstTimeAccessAtom } from "../../../../../atoms/PreauditAtom";
import { FiSave } from "react-icons/fi";
import manWithMask from "../../../../../images/man-with-mask.png";

const AuditRGPDQuestions = () => {
  const navigate = useNavigate();
  const [auditRGPDAnswers, setAuditRGPDAnswers] = useAtom(auditRGPDAnswersAtom);
  const [auditRGPDComments, setAuditRGPDComments] = useAtom(
    auditRGPDCommentsAtom
  );

  useAtom(areUserInformationsCompletedAtom);
  const [isAuditRGPDModified, setIsAuditRGPDmodified] = useAtom(
    isAuditRGPDModifiedAtom
  );
  const [firstTimeAccess, setFirstTimeAccess] = useAtom(firstTimeAccessAtom);

  useEffect(() => {
    getCurrentUserAuditRGPD(
      Cookies.get(process.env.REACT_APP_COOKIES_AUTHENTICATED_USER_ID),
      setAuditRGPDAnswers,
      setAuditRGPDComments
    );
  }, [setAuditRGPDAnswers, setAuditRGPDComments]);

  const handleClick = () => {
    saveAuditRGPDInBackend(
      Cookies.get(process.env.REACT_APP_COOKIES_AUTHENTICATED_USER_ID),
      auditRGPDAnswers,
      auditRGPDComments,
      setIsAuditRGPDmodified,
      navigate
    );
  };

  return (
    <>
      {/* <div id="audit-rgpd-global-container">
        <div id="audit-rgpd-questions-container">
          <h3 className="font-weight-normal margin-bottom-5vh">
            Voici les {auditRGPDQuestions.length} questions de votre audit RGPD
            :
          </h3>
          {auditRGPDQuestions.map((question) => (
            <AuditRGPDQuestionTemplate
              question={question}
              setIsAuditRGPDmodified={setIsAuditRGPDmodified}
            />
          ))}
        </div>
      </div>

     */}

      <div id="audit-rgpd-questions-container">
        <div className="page-title-container">
          <div className="page-title-icon-and-text">
            <div className="page-title-icon">
              <FaClipboardList />
            </div>
            <div className="page-title-text">AUDIT RGPD</div>
          </div>
        </div>

        <div id="audit-rgpd-questions-list-container">
          <h1>Veuillez répondre aux {auditRGPDQuestions.length} questions .</h1>
          <p className="text-center">
            Vous pourrez sauvegarder avec{" "}
            <span className="padding-top-2px background-color-red border-radius-5px">
              <FiSave size="1rem" color="white" />
            </span>{" "}
            .
          </p>

          {firstTimeAccess ? (
            <div id="first-time-access-container">
              <button
                id="first-time-access-button"
                className="preaudit-dashboard-button"
                onClick={() => setFirstTimeAccess(false)}
              >
                COMMENCER ?
              </button>
            </div>
          ) : (
            <>
              <div id="audit-rgpd-questions-form">
                {auditRGPDQuestions.map((question) => (
                  <AuditRGPDQuestionTemplate
                    question={question}
                    // key={nanoid()}
                    setIsAuditRGPDmodified={setIsAuditRGPDmodified}
                  />
                ))}
              </div>

              <div id="audit-rgpd-questions-illustration-container">
                <img
                  src={manWithMask}
                  alt="illustration de cybersécurité"
                  className="audit-rgpd-questions-illustration"
                />
              </div>

              <AuditRGPDProgress handleClick={handleClick} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AuditRGPDQuestions;
