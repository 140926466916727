import Cookies from "js-cookie";

// Function to GET current users' pre_audit
export const getCurrentUserPreaudit = (userId, setPreauditAnswers) => {
  fetch(process.env.REACT_APP_BACKEND_URL + `pre_audits/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: Cookies.get(process.env.REACT_APP_COOKIES_TOKEN_SESSION),
    },
  })
    .then((response) => {
      // console.log("RESPONSE from getCurrentUserPreaudit :\n", response);
      return response.json();
    })
    .then((data) => {
      // console.log("DATA from getCurrentUserPreaudit :\n", data);
      setPreauditAnswers(data.preaudit);
    });
  // .catch((error) => console.log(error.message));
};

// Function that UPDATE the preaudit answers in backend
export const savePreauditInBackend = (
  userId,
  preauditAnswers,
  setIsPreauditmodified,
  navigate
) => {
  // console.log("userId = ", userId);
  // console.log("prepreauditAnswers = ", preauditAnswers);

  fetch(process.env.REACT_APP_BACKEND_URL + `pre_audits/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: Cookies.get(process.env.REACT_APP_COOKIES_TOKEN_SESSION),
    },

    body: JSON.stringify({
      pre_audit: {
        user_id: userId,
        answers: { ...preauditAnswers }, // Use this to create a hash in Rails
        // ...preauditStorage, [`${question.id}. ${question.theme}`]: parseInt(value), // Use this to update only one value of the hash in Rails
      },
    }),
  })
    .then((response) => {
      // console.log("RESPONSE from savePrauditInBackend :\n", response);
      return response.json();
    })
    .then((data) => {
      // console.log("DATA from savePrauditInBackend :\n", data);
      setIsPreauditmodified(false);
      alert("Vos réponses sont enregistrées.")
      navigate("/dashboard");
    });
  // .catch((error) => console.log(error.message));
};
// Function that RESET the preaudit answers in backend
export const resetPreauditInBackend = (
  userId,
  preauditAnswers,
  // setIsPreauditmodified,
  // navigate
) => {
  // console.log("userId = ", userId);
  // console.log("prepreauditAnswers = ", preauditAnswers);

  fetch(process.env.REACT_APP_BACKEND_URL + `pre_audits/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: Cookies.get(process.env.REACT_APP_COOKIES_TOKEN_SESSION),
    },

    body: JSON.stringify({
      pre_audit: {
        user_id: userId,
        answers: { ...preauditAnswers }, // Use this to create a hash in Rails
        // ...preauditStorage, [`${question.id}. ${question.theme}`]: parseInt(value), // Use this to update only one value of the hash in Rails
      },
    }),
  })
    .then((response) => {
      // console.log("RESPONSE from savePrauditInBackend :\n", response);
      return response.json();
    })
    .then((data) => {
      // console.log("DATA from savePrauditInBackend :\n", data);
      // setIsPreauditmodified(false);
      // navigate("/preaudit-dashboard");
    });
  // .catch((error) => console.log(error.message));
};
