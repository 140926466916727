import React from "react";
import { auditRGPDQuestions } from "../../../../../utils/JSON/auditRGPDQuestions";
import { IoIosCloseCircle } from "react-icons/io";

const AuditRGPDAnswersAndCommentsModal = ({
  questionsCategory,
  answersAndCommentsForModal,
  setDisplayAnswersAndComments,
}) => {
  // useEffect(() => {
  //   console.log("answersAndCommentsForModal", answersAndCommentsForModal);
  // }, [answersAndCommentsForModal]);

  return (
    <>
      <div id="audit-rgpd-answers-and-comments-modal">
        <div id="audit-rgpd-answers-and-comments-container">
          <div id="audit-rgpd-answers-and-comments-title-container">
            <h2>
              <div>
                {questionsCategory.id}. {questionsCategory.name}{" "}
                <span className="font-weight-normal">(détails) </span>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => setDisplayAnswersAndComments(false)}
              >
                <IoIosCloseCircle size="1.75rem" color="#f76a66" />
              </div>
            </h2>
          </div>

          <div id="audit-rgpd-answers-and-comments">
            {auditRGPDQuestions.map(
              (question) =>
                question.category === questionsCategory.name && (
                  <>
                    <div className="audit-rgpd-single-answer-and-comment-container">
                      <div
                        className="audit-rgpd-question-container"
                        key={question.id}
                      >
                        Q{question.id} : {question.content}
                      </div>

                      <div className="audit-rgpd-answer-container">
                        {answersAndCommentsForModal[question.id - 1] ===
                        ". Commentaire : " ? (
                          <>
                            <span className="not-yet-answered">
                              Pas encore répondu.
                            </span>
                          </>
                        ) : (
                          <>
                            {/* Adapt font background color according to "oui" ou "non" ou "NA" */}

                            <span
                              className={`answered

                            ${
                              answersAndCommentsForModal[
                                question.id - 1
                              ].substring(0, 3) === "oui" && "answer-yes"
                            }
                            
                            ${
                              answersAndCommentsForModal[
                                question.id - 1
                              ].substring(0, 3) === "non" && "answer-no"
                            }
                            
                            ${
                              answersAndCommentsForModal[
                                question.id - 1
                              ].substring(0, 2) === "NA" && "answer-na"
                            }
                            
                            `}
                            >
                              {answersAndCommentsForModal[question.id - 1]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AuditRGPDAnswersAndCommentsModal;
