import React, { useEffect, useState } from "react";
import {
  auditRGPDCategories,
  auditRGPDQuestions,
} from "../../../../../utils/JSON/auditRGPDQuestions";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { useAtomValue } from "jotai";

import {  FaCircle } from "react-icons/fa";
import {
  auditRGPDAnswersAtom,
  auditRGPDCommentsAtom,
} from "../../../../../atoms/AuditRGPDAtom ";
import AuditRGPDAnswersAndCommentsModal from "./AuditRGPDAnswersAndCommentsModal";
// import SupplierAssesmentAnswersAndCommentsModal from "./SupplierAssesmentAnswersAndCommentsModal";
// import ResetButtonTemplate from "../../../../components/Buttons/ResetButtonTemplate";

////////////////////////////////////////////////////////////////////////////
// All comments in this page are kept as a memo of the PDF feature coding //
////////////////////////////////////////////////////////////////////////////

// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import { BsFiletypePdf } from "react-icons/bs";

ChartJS.register(ArcElement, Tooltip, Legend);

const AuditRGPDCharts = ({ setDisplayAuditRGPDCharts }) => {
  // const printRef = useRef();

  // const handleDownloadPdf = async () => {
  //   const element = printRef.current;
  //   const canvas = await html2canvas(element);
  //   const data = canvas.toDataURL("image/png");

  //   const pdf = new jsPDF();
  //   const imgProperties = pdf.getImageProperties(data);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
  //   pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight, "SLOW");
  //   pdf.save("alliance-cyber-technologies.pdf");
  // };

  const auditRGPDAnswers = useAtomValue(auditRGPDAnswersAtom);
  const auditRGPDComments = useAtomValue(auditRGPDCommentsAtom);
  const [displayAnswersAndComments, setDisplayAnswersAndComments] =
    useState(false);
  const [questionsCategoryForModal, setQuestionsCategoryForModal] = useState();
  const [answersAndCommentsForModal, setAnswersAndCommentsForModal] =
    useState();

  const [totalQuestionsByCategories, setTotalQuestionsByCategories] = useState(
    []
  );

  const [yesQtyForCategory1, setYesQtyForCategory1] = useState(0);
  const [noQtyForCategory1, setNoQtyForCategory1] = useState(0);
  const [naQtyForCategory1, setNAQtyForCategory1] = useState(0);

  const [yesQtyForCategory2, setYesQtyForCategory2] = useState(0);
  const [noQtyForCategory2, setNoQtyForCategory2] = useState(0);
  const [naQtyForCategory2, setNAQtyForCategory2] = useState(0);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
      // [ChartDataLabels],

      datalabels: {
        display: (context) => {
          return context.dataset.data[context.dataIndex] !== 0;
        },
        color: "black",
        formatter: (value, context) => {
          return value !== 0 ? value.toString() : "";
        },
      },
    },
  };

  const dataForCategory1 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForCategory1, noQtyForCategory1, naQtyForCategory1],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataForCategory2 = {
    labels: ["OUI", "NON", "NA"],
    datasets: [
      {
        label: "Vos réponses",
        data: [yesQtyForCategory2, noQtyForCategory2, naQtyForCategory2],
        backgroundColor: [
          "rgba(19, 222, 185, 1)",
          "rgb(247, 106, 102, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const countTotalQuestionsByCategories = () => {
      const categoryCounts = [];

      for (let i = 0; i < auditRGPDCategories.length; i++) {
        const categoryName = auditRGPDCategories[i].name;
        const occurrences = auditRGPDQuestions.reduce((count, item) => {
          if (item.category === categoryName) {
            return count + 1;
          }
          return count;
        }, 0);

        categoryCounts.push(occurrences);
      }

      setTotalQuestionsByCategories(categoryCounts);
    };

    const calculateStatsForCategory1 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in auditRGPDAnswers) {
        const index = parseAuditRGPDAnswersKey(key);
        if (index >= 1 && index <= 52) {
          auditRGPDAnswers[key] === "oui" && yesCounts++;
          auditRGPDAnswers[key] === "non" && noCounts++;
          auditRGPDAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForCategory1(yesCounts);
      setNoQtyForCategory1(noCounts);
      setNAQtyForCategory1(naCounts);
    };

    const calculateStatsForCategory2 = () => {
      let yesCounts = 0;
      let noCounts = 0;
      let naCounts = 0;

      for (const key in auditRGPDAnswers) {
        const index = parseAuditRGPDAnswersKey(key);
        if (index >= 53 && index <= 66) {
          auditRGPDAnswers[key] === "oui" && yesCounts++;
          auditRGPDAnswers[key] === "non" && noCounts++;
          auditRGPDAnswers[key] === "NA" && naCounts++;
        }
      }

      setYesQtyForCategory2(yesCounts);
      setNoQtyForCategory2(noCounts);
      setNAQtyForCategory2(naCounts);
    };

    const initializeAnswersAndComments = () => {
      let answersArray = Object.values(auditRGPDAnswers);
      let commentsArray = Object.values(auditRGPDComments);
      let answersAndComments = [];

      for (let i = 0; i < answersArray.length; i++) {
        answersAndComments.push(
          answersArray[i] + ". Commentaire : " + commentsArray[i]
        );
      }

      setAnswersAndCommentsForModal(answersAndComments);
      // console.log("AnswersAndCOmments :\n", answersAndComments);
    };

    calculateStatsForCategory1();
    calculateStatsForCategory2();

    countTotalQuestionsByCategories();
    auditRGPDAnswers && auditRGPDComments && initializeAnswersAndComments();
  }, [auditRGPDAnswers, auditRGPDComments]);

  const parseAuditRGPDAnswersKey = (key) => {
    const parsedKey = parseInt(key.match(/\d+/)[0]);
    return parsedKey;
  };

  // const resetSupplierAssesment = () => {
  //   if (window.confirm("Les données seront remises à zéro. Continuer ?")) {
  //     let initialSupplierAssesmentAnswers = {};
  //     let initialSupplierAssesmentComments = {};

  //     supplierAudit.map((question) => {
  //       initialSupplierAssesmentAnswers = {
  //         ...initialSupplierAssesmentAnswers,
  //         [`Q${question.id}Answer`]: "",
  //       };
  //       initialSupplierAssesmentComments = {
  //         ...initialSupplierAssesmentComments,
  //         [`Q${question.id}Comment`]: "",
  //       };
  //       return question;
  //     });
  //     setSupplierAssesmentAnswers(initialSupplierAssesmentAnswers);
  //     setSupplierAssesmentComments(initialSupplierAssesmentComments);
  //   }
  // };

  const getAnswersAndComments = (questionsTheme) => {
    setQuestionsCategoryForModal(questionsTheme);
    setDisplayAnswersAndComments(true);
  };

  return (
    <>
      {/* <div id="audit-rgpd-charts-container"> */}
      {/* ************************************************************************************************** */}
      {/* Note these charts can not be displayed dynamically with the react-chartjs-2 and chartjs librairies */}
      {/* Each chart has to be created with its own datas    *********************************************** */}
      {/* ************************************************************************************************** */}

      <div
        className="single-audit-rgpd-chart-container"
        onClick={() => getAnswersAndComments(auditRGPDCategories[0])}
      >
        <div className="audit-rgpd-chart-title-container">
          <div className="audit-rgpd-title-header">
            <div className="audit-rgpd-category-container">
              {auditRGPDCategories[0].id}. {auditRGPDCategories[0].name}
            </div>

            <div className="audit-rgpd-total-questions-container">
              <div className="audit-rgpd-total-questions">
                Réponses données :{" "}
                {yesQtyForCategory1 + noQtyForCategory1 + naQtyForCategory1} /{" "}
                {totalQuestionsByCategories[0]}
              </div>
              <div className="audit-rgpd-completion-icon-container">
                {yesQtyForCategory1 + noQtyForCategory1 + naQtyForCategory1 ===
                totalQuestionsByCategories[0] ? (
                  <>
                    <FaCircle size="0.90rem" className="font-green" />
                  </>
                ) : (
                  <>
                    <FaCircle size="0.90rem" className="font-red" />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="audit-rgpd-chart-container">
          <Pie data={dataForCategory1} options={options} />
        </div>

        <div className="answers-quantities-container">
          <div className="answers-quantity-yes">{yesQtyForCategory1} OUI</div>

          <div className="answers-quantity-no">{noQtyForCategory1} NON</div>

          <div className="answers-quantity-na">{naQtyForCategory1} NA</div>
        </div>
      </div>

      <div
        className="single-audit-rgpd-chart-container"
        onClick={() => getAnswersAndComments(auditRGPDCategories[1])}
      >
        <div className="audit-rgpd-chart-title-container">
          <div className="audit-rgpd-title-header">
            <div className="audit-rgpd-category-container">
              {auditRGPDCategories[1].id}. {auditRGPDCategories[1].name}
            </div>

            <div className="audit-rgpd-total-questions-container">
              <div className="audit-rgpd-total-questions">
                Réponses données :{" "}
                {yesQtyForCategory2 + noQtyForCategory2 + naQtyForCategory2} /{" "}
                {totalQuestionsByCategories[1]}
              </div>
              <div className="audit-rgpd-completion-icon-container">
                {yesQtyForCategory2 + noQtyForCategory2 + naQtyForCategory2 ===
                totalQuestionsByCategories[1] ? (
                  <>
                    <FaCircle size="0.90rem" className="font-green" />
                  </>
                ) : (
                  <>
                    <FaCircle size="0.90rem" className="font-red" />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="audit-rgpd-chart-container">
          <Pie data={dataForCategory2} options={options} />
        </div>

        <div className="answers-quantities-container">
          <div className="answers-quantity-yes">{yesQtyForCategory2} OUI</div>

          <div className="answers-quantity-no">{noQtyForCategory2} NON</div>

          <div className="answers-quantity-na">{naQtyForCategory2} NA</div>
        </div>
      </div>

      {/* <div className="single-audit-rgpd-chart-container">
        <div className="audit-rgpd-chart-title-container">
          <div className="audit-rgpd-category-container">
            {auditRGPDCategories[1].id}. {auditRGPDCategories[1].name}
          </div>
        </div>
        <div className="audit-rgpd-chart-container">
          <Pie
            data={dataForCategory2}
            options={options}
            onClick={() => getAnswersAndComments(auditRGPDCategories[1])}
          />
        </div>
        <div className="audit-rgpd-total-questions-container">
          <div className="audit-rgpd-total-questions">
            Réponses :{" "}
            {yesQtyForCategory2 + noQtyForCategory2 + naQtyForCategory2} /{" "}
            {totalQuestionsByCategories[1]}
          </div>

          <div className="completion-icon-container">
            {yesQtyForCategory2 + noQtyForCategory2 + naQtyForCategory2 ===
              totalQuestionsByCategories[1] && (
              <>
                <FaCheck size="1rem" className="font-green" />
              </>
            )}
          </div>
        </div>
      </div> */}
      {/* </div> */}

      {/* <div
        className="closing-icon"
        onClick={() => setDisplayAuditRGPDCharts(false)}
      >
        X
      </div> */}

      {displayAnswersAndComments && (
        <AuditRGPDAnswersAndCommentsModal
          setDisplayAnswersAndComments={setDisplayAnswersAndComments}
          questionsCategory={questionsCategoryForModal}
          answersAndCommentsForModal={answersAndCommentsForModal}
        />
      )}
    </>
  );
};

export default AuditRGPDCharts;
